import { Box, Heading, Link, Text } from "@primer/react";
import style from '../global.module.scss'
import googleIcon from '../assets/Google.svg'
import { useMediaQuery } from 'react-responsive'
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db, auth, googleProvider, analytics } from '../firebase';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useNavigate } from "react-router-dom";
import { logEvent, setUserId, setUserProperties } from "firebase/analytics";

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}
const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
}

const parentBox = {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
    background: style.pageBg
}



const childBox = {
    position: 'absolute',
    top: '135px',
    left: '0px',
    right: '0px',
    margin: 'auto auto auto auto',
    height: '326px',
    width: '388px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '39px'

}

const innerChildBox = { display: 'flex', flexDirection: 'column', gap: '29px', alignItems: 'center' }

const childBoxMobile = {
    position: 'absolute',
    top: '135px',
    left: '0px',
    right: '0px',
    margin: 'auto auto auto auto',
    height: '326px',
    width: '348px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '39px',
    //  background:'red'

}

const innerChild = {
    display: 'flex',
    flexDirection: 'row',
    gap: '18px',
    alignItems: 'center'
}

export default function SignUp() {

    const navigate = useNavigate()



    const handleFirebaseAuth = (props) => {


        signInWithPopup(auth, googleProvider)
            .then((result) => {


                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                const user = result.user;
                handleSignUpWithFirebase(user.uid, user.email)

            }).catch((error) => {

                const errorCode = error.code;
                const errorMessage = error.message;
                const email = error.customData.email;
                const credential = GoogleAuthProvider.credentialFromError(error);

            });

    }

    const handleSignUpWithFirebase = async (userId, userEmail) => {

        console.log(userId, userEmail)

        const userRef = doc(db, 'users', userId);

        const data = {
            userId: userId,
            userEmail: userEmail,
            timeStamp: new Date(),
            capital: true,
            merge: true,
            username: '',
            name: '',
            description: '',
            imageUrl: ''
        }

        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {

            const responseData = docSnap.data()
            const userName = responseData?.username
            const fullName = responseData?.name
            console.log("userName", userName)
            if (userName === "") {
                navigate('/accountsetup')
            } else {

                if (fullName === "") {

                    navigate('/profilesetup')

                } else {

                    navigate(`/${userName}`)
                }
            }


        } else {

            await setDoc(userRef, data)

            setUserId(analytics, userId)
            setUserProperties(analytics, { userEmail: userEmail })
            logEvent(analytics, 'signup', {
                userId: userId,
                userEmail: userEmail
            });

            navigate('/accountsetup')
            console.log("No such document!");
        }



    }


    return (

        <>
            <Default >
                <Box sx={parentBox}>

                    <Box sx={childBox}>
                        <Box sx={innerChildBox}>
                            <Heading className={style.H1}>Hello👋</Heading>
                            <Text className={style.p2} sx={{ textAlign: 'center' }}>Today is a new day. It's your day. You shape it.
                                Sign in to start managing your  bio page .</Text>
                        </Box>

                        <Box sx={innerChild}>
                            <Box sx={{ minWidth: '115px', height: '1px', background: style.borderColor }}></Box>
                            <Text className={style.p3} sx={{ textAlign: 'center' }}>Signup with.</Text>
                            <Box sx={{ minWidth: '115px', height: '1px', background: style.borderColor }}></Box>
                        </Box>

                        <Box sx={{
                            minHeight: '52px',
                            width: '100%',
                            background: style.btnbackgroundColor,
                            borderRadius: '12px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '16px',
                            cursor: 'pointer',
                            ":hover": {
                                background: 'rgb(246, 248, 250)',
                                border: `1px solid ${style.borderColor}`
                            }
                        }}
                            onClick={() => {
                                handleFirebaseAuth("")
                            }}
                        >
                            <img src={googleIcon}></img>
                            <Text className={style.p3} sx={{}}>Sign up with Google</Text>
                        </Box>

                        <Text className={style.p2} sx={{ textAlign: 'center' }}>Already have an account?  <Link sx={{
                            ":hover": {
                                textDecoration: 'none',
                                cursor: 'pointer'
                            }
                        }}
                            onClick={() => {
                                navigate('/')
                            }}
                        >Login.</Link></Text>

                    </Box>


                </Box>
            </Default >
            {/* Mobile responsive code */}
            <Mobile>
                <Box sx={parentBox}>

                    <Box sx={childBoxMobile}>
                        <Box sx={innerChildBox}>
                            <Heading className={style.H1}>Hello👋</Heading>
                            <Text className={style.p2} sx={{ textAlign: 'center' }}>Today is a new day. It's your day. You shape it.
                                Sign in to start managing your  bio page .</Text>
                        </Box>

                        <Box sx={innerChild}>
                            <Box sx={{ minWidth: '115px', height: '1px', background: style.borderColor }}></Box>
                            <Text className={style.p3} sx={{ textAlign: 'center' }}>Signup with.</Text>
                            <Box sx={{ minWidth: '115px', height: '1px', background: style.borderColor }}></Box>
                        </Box>

                        <Box sx={{
                            minHeight: '52px',
                            width: '100%',
                            background: style.btnbackgroundColor,
                            borderRadius: '12px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '16px',
                            cursor: 'pointer',
                            ":hover": {
                                // background:'rgb(246, 248, 250)'
                            }
                        }}
                            onClick={() => {
                                handleFirebaseAuth("")
                            }}
                        >
                            <img src={googleIcon}></img>
                            <Text className={style.p3} sx={{}}>Sign in with Google</Text>
                        </Box>

                        <Text className={style.p2} sx={{ textAlign: 'center' }}>Already have an account? <Link sx={{
                            ":hover": {
                                textDecoration: 'none',
                                cursor: 'pointer'
                            }
                        }} onClick={() => {
                            navigate('/')
                        }}>Login .</Link></Text>

                    </Box>


                </Box>
            </Mobile>
            <Text className={style.p4} sx={{

                textAlign: 'center',
                width: '100vw',
                position: 'absolute',
                bottom: '30px',
                left: '0px',
                right: '0px',
                margin: 'auto auto auto auto',
            }}>© 2024 ALL RIGHTS RESERVED</Text>
        </>

    )
}