import { AppsIcon, CopyIcon, DeviceDesktopIcon, DeviceMobileIcon, IdBadgeIcon, MoonIcon, PencilIcon, SunIcon, XIcon } from "@primer/octicons-react";
import { Box, IconButton } from "@primer/react";
import React from "react";
import { addProjectWidgetOpenClose, editPlatformOpenClose, mobileViewTrigger, swichThemes, switchMenu } from "./redux/features/dashboardSlice";
import { useDispatch } from "react-redux";
import { Toaster, Position, Intent } from '@blueprintjs/core';
import '@blueprintjs/core/lib/css/blueprint.css';
import { useSelector } from "react-redux";
import { db } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import mixpanel from 'mixpanel-browser';
import posthog from "posthog-js";
mixpanel.init('7a2c125cf3bf07b7fef81e91d0190b14');
posthog.init('phc_mGFMcRORH045UBsthDPi8bGDGMAPCvc7qtACPde72Hh', { api_host: 'https://app.posthog.com' })
const analytics = getAnalytics();
export default function ActionBar(props) {

    const username = props?.username
    const firebaseUser = useSelector((state) => state.firebaseStore.firebaseUser)
    const themeName = useSelector((state) => state.dasboardStore.themeName)
    const isEditableMenu = useSelector((state) => state.dasboardStore.isEditableMenu)

    const dispatch = useDispatch()

    const openEditDialog = (props) => {

        dispatch(editPlatformOpenClose({
            openClose: true
        }))

    }

    const openAddProjectWidgetDialog = (props) => {

        dispatch(addProjectWidgetOpenClose({
            isaddprojectWidgetOpenClose: true
        }))

    }

    const hadnleCopyBioLink = (props) => {


        navigator.clipboard.writeText(`${window.location.host}/${username}`)
            .then(() => {
                console.log('Text copied to clipboard');

                Toaster.create({ position: Position.TOP }).show({
                    message: "Link Copied",
                    intent: Intent.SUCCESS
                });

                mixpanel.track('LinkCopied', {
                    $distinct_id: firebaseUser?.uid,
                    time: new Date().getTime(),
                    user_id: firebaseUser?.uid,
                    user_email: firebaseUser?.email
                })

                posthog.capture(
                    'LinkCopied',
                    {
                        distinct_id: firebaseUser?.uid,
                        $set_once: {
                            user_id: firebaseUser?.uid,
                            user_email: firebaseUser?.email,
                            link: `${window.location.host}/${username}`
                        },
                        timestamp: new Date().toISOString(),
                        user_id: firebaseUser?.uid,
                        user_email: firebaseUser?.email,
                    }
                )


                logEvent(analytics, 'link_copy', {
                    userId: firebaseUser?.uid,
                    userEmail: firebaseUser?.email,
                    user_id: firebaseUser?.uid,
                    user_email: firebaseUser?.email,
                });


            })
            .catch(err => {
                console.error('Unable to copy text: ', err);
            });

    }

    const handleSwitchMobileView = (props) => {

        dispatch(mobileViewTrigger({
            isMobileView: props
        }))
    }

    const handleSwichTheme = async (props) => {
        dispatch(swichThemes({
            themeName: props
        }))
        const userRef = doc(db, 'users', firebaseUser?.uid);
        await updateDoc(userRef, { themeName: props })
        posthog.capture(
            'SwitchTheme',
            {
                distinct_id: firebaseUser?.uid,
                $set_once: {
                    user_id: firebaseUser?.uid,
                    user_email: firebaseUser?.email,
                    theme: props
                },
                timestamp: new Date().toISOString(),
                user_id: firebaseUser?.uid,
                user_email: firebaseUser?.email,
                theme: props
            }
        )

    }

    const handleSwichEditableMenu = (props) => {
        dispatch(switchMenu({
            isEditableMenu: props
        }))
    }


    return (<>

        {isEditableMenu ?
            <Box sx={{
                position: 'fixed',
                bottom: '20px',
                left: '0px',
                right: '0px',
                margin: 'auto',
                width: '288px',
                height: '64px',
                borderRadius: '6px',
                border: '1px solid #D8DEE4',
                background: '#ffffff',
                display: 'flex',
                flexDirection: 'row',
                padding: '16px 32px 16px 32px'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>

                    {/* <IconButton variant="invisible" icon={PersonIcon} onClick={()=>{ }} /> */}

                    <IconButton variant="invisible" icon={themeName === 'light' ? SunIcon : MoonIcon} onClick={() => {

                        if (themeName === 'light') {
                            handleSwichTheme('dark')
                        } else {
                            handleSwichTheme('light')
                        }


                    }} />

                    <IconButton variant="invisible" icon={AppsIcon} onClick={() => { openEditDialog("") }} />
                    <Box sx={{ width: '16px', height: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box sx={{ width: '1px', height: '16px', background: '#D0D7DE' }}></Box>
                    </Box>
                    <IconButton variant="invisible" icon={IdBadgeIcon} onClick={() => { openAddProjectWidgetDialog("") }} />
                    <IconButton variant="invisible" icon={DeviceMobileIcon} onClick={() => handleSwitchMobileView(true)} />
                    <IconButton variant="invisible" icon={DeviceDesktopIcon} onClick={() => handleSwitchMobileView(false)} />
                    <Box sx={{ width: '16px', height: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box sx={{ width: '1px', height: '16px', background: '#D0D7DE' }}></Box>
                    </Box>
                    <IconButton variant="invisible" icon={XIcon} onClick={() => {
                        handleSwichEditableMenu(false)
                    }} />
                </Box>
            </Box>

            :

            <Box sx={{
                position: 'fixed',
                bottom: '20px',
                left: '0px',
                right: '0px',
                margin: 'auto',
                width: '200px',
                height: '64px',
                borderRadius: '6px',
                border: '1px solid #D8DEE4',
                background: '#ffffff',
                display: 'flex',
                flexDirection: 'row',
                padding: '16px 20px 16px 20px'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                    <IconButton variant="invisible" icon={CopyIcon} onClick={() => { hadnleCopyBioLink("") }} />
                    <Box sx={{ width: '16px', height: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box sx={{ width: '1px', height: '16px', background: '#D0D7DE' }}></Box>
                    </Box>
                    <IconButton variant="invisible" icon={DeviceMobileIcon} onClick={() => handleSwitchMobileView(true)} />
                    <IconButton variant="invisible" icon={DeviceDesktopIcon} onClick={() => handleSwitchMobileView(false)} />
                    <Box sx={{ width: '16px', height: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box sx={{ width: '1px', height: '16px', background: '#D0D7DE' }}></Box>
                    </Box>
                    <IconButton variant="invisible" icon={PencilIcon} onClick={() => {

                        handleSwichEditableMenu(true)
                    }} />
                </Box>
            </Box>

        }


    </>)
}