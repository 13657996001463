import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";

 
export const openCloseMobileNav = createAsyncThunk("openCloseMobileNav",async (data)=>{

    return({isOpenSideNav:data.isOpenSideNav})
})

 
export const webSlice = createSlice({
    name: 'dashboardSlice',
    initialState: {
        isOpenSideNav:false
    },
    extraReducers: {
        
        //------------
        [openCloseMobileNav.pending]: (state, action) => {
            state.isOpenSideNav = false
        },
        [openCloseMobileNav.fulfilled]: (state, action) => {

            state.isOpenSideNav = action.payload.isOpenSideNav
        },
        [openCloseMobileNav.rejected]: (state, action) => {
            state.isOpenSideNav = false
        },



     }
})

export default webSlice.reducer
