import twitter from '../../assets/socialIcons/twitter.svg'
import instagram from '../../assets/socialIcons/Instagram.svg'
import linkedIn from '../../assets/socialIcons/linkedIn.svg'
import youtube from '../../assets/socialIcons/youtube.svg'
import spotify from '../../assets/socialIcons/spotify.svg'
import marxiix from '../../assets/socialIcons/marxiix.svg'
import thread from '../../assets/socialIcons/thread.svg'
import discord from '../../assets/socialIcons/discord.svg'
import github from '../../assets/socialIcons/github.svg'
import gumroad from '../../assets/socialIcons/gumroad.svg'
import medium from '../../assets/socialIcons/medium.svg'
import figma from '../../assets/socialIcons/figma.svg'
import drible from '../../assets/socialIcons/drible.svg'
import sms from '../../assets/socialIcons/sms.svg'
import behance from '../../assets/socialIcons/behance.svg'
import whatsapp from '../../assets/socialIcons/whatsapp.svg'
import clubhouse from '../../assets/socialIcons/clubhouse.svg'
import mastodon from '../../assets/socialIcons/mastodon.svg'
import tiktok from '../../assets/socialIcons/tiktok.svg'
import producthunt from '../../assets/socialIcons/producthunt.svg'
import coffe from '../../assets/socialIcons/coffe.svg'


const platformBg = [
    { icon: twitter, bgColor: 'rgba(0, 0, 0, 0.15)' }, //#00000026
    { icon: instagram, bgColor: '#C1319326' },
    { icon: linkedIn, bgColor: 'rgba(10, 102, 194, 0.15)' },  //10, 102, 194  #0A66C226
    { icon: whatsapp, bgColor: 'rgba(37, 211, 102, 0.15)' }, //37, 211, 102   #25D36626
    { icon: producthunt, bgColor: 'rgba(218, 85, 47, 0.15)' },//218, 85, 47  #D5502A26
    { icon: coffe, bgColor: '#FFDD0026' },
    { icon: youtube, bgColor: 'rgba(255, 3, 2, 0.15)' },
    { icon: spotify, bgColor: 'rgba(30, 215, 96, 0.15)' },
    { icon: marxiix, bgColor: 'rgba(145, 70, 255, 0.15)' },
    { icon: thread, bgColor: 'rgba(0, 0, 0, 0.15)' },
    { icon: discord, bgColor: 'rgba(88, 101, 242, 0.15)' },
    { icon: github, bgColor: 'rgba(36, 41, 47, 0.15)' },
    { icon: gumroad, bgColor: 'rgba(255, 144, 232, 0.15)' },
    { icon: medium, bgColor: 'rgba(0, 0, 0, 0.15)' },
    { icon: figma, bgColor: 'rgba(242, 78, 30, 0.15)' },
    { icon: drible, bgColor: 'rgba(195, 35, 97, 0.15)' },
    { icon: sms, bgColor: 'rgba(41, 45, 50, 0.15)' },
    { icon: behance, bgColor: 'rgba(23, 105, 255, 0.15)' },
    { icon: clubhouse, bgColor: 'rgba(161, 161, 170, 0.15)' },
    { icon: mastodon, bgColor: 'rgba(48, 136, 212, 0.15)' },
    { icon: tiktok, bgColor: 'rgba(0, 242, 234, 0.15)' },
]

export default platformBg