import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";

export const editPlatformOpenClose = createAsyncThunk("editPlatformOpenClose",async (data)=>{

          return({openClose:data.openClose})
})


export const editPlatformWidgetOpenClose = createAsyncThunk("editPlatformWidgetOpenClose",async (data)=>{

    return({platformWidgetopenClose:data.platformWidgetopenClose,platformId:data.platformId})
})

export const addProjectWidgetOpenClose = createAsyncThunk("addProjectWidgetOpenClose",async (data)=>{
     return({isaddprojectWidgetOpenClose:data.isaddprojectWidgetOpenClose})
})

export const editProfileOpenClose = createAsyncThunk("editProfileOpenClose",async (data)=>{

     return({iseditprofileOpenClose:data.iseditprofileOpenClose})
})

export const profilereloadTrigger = createAsyncThunk("profilereloadTrigger",async (data)=>{
     return({isprofRedload:data.isprofRedload}) 
})


export const mobileViewTrigger = createAsyncThunk("mobileViewTrigger",async (data)=>{
    return({isMobileView:data.isMobileView}) 
})

export const swichThemes = createAsyncThunk("swichThemes",async (data)=>{

    return({themeName:data.themeName})

})

export const switchMenu = createAsyncThunk("switchMenu",async (data)=>{
    return({isEditableMenu:data.isEditableMenu})
})

 






export const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState: {
        openClose: false,
        loading: false,
        platformWidgetopenClose: false,
        isaddprojectWidgetOpenClose: false,
        iseditprofileOpenClose: false,
        isprofRedload:false,
        platformId:null,
        isMobileView:false,
        themeName:'light',
        isEditableMenu:false,
        
    },
    extraReducers: {
        [editPlatformOpenClose.pending]: (state, action) => {
            state.loading = true;
            state.openClose = false

        },
        [editPlatformOpenClose.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.openClose = action.payload.openClose

        },
        [editPlatformOpenClose.rejected]: (state, action) => {
            state.loading = false
            state.openClose = false
        },
        //---------
        [editPlatformWidgetOpenClose.pending]: (state, action) => {
            state.platformWidgetopenClose = false
            state.platformId = null
        },
        [editPlatformWidgetOpenClose.fulfilled]: (state, action) => {

            state.platformWidgetopenClose = action.payload.platformWidgetopenClose
            state.platformId = action.payload.platformId
        },
        [editPlatformWidgetOpenClose.rejected]: (state, action) => {
            state.platformWidgetopenClose = false
            state.platformId = null
        },
        //-------------------------------
        [addProjectWidgetOpenClose.pending]: (state, action) => {
            state.isaddprojectWidgetOpenClose = false
        },
        [addProjectWidgetOpenClose.fulfilled]: (state, action) => {

            state.isaddprojectWidgetOpenClose = action.payload.isaddprojectWidgetOpenClose
        },
        [addProjectWidgetOpenClose.rejected]: (state, action) => {
            state.isaddprojectWidgetOpenClose = false
        },
        //----------- edit profile open close ------------
        [editProfileOpenClose.pending]: (state, action) => {
            state.iseditprofileOpenClose = false
        },
        [editProfileOpenClose.fulfilled]: (state, action) => {

            state.iseditprofileOpenClose = action.payload.iseditprofileOpenClose
        },
        [editProfileOpenClose.rejected]: (state, action) => {
            state.iseditprofileOpenClose = false
        },
       //------------------------------------------------
        [profilereloadTrigger.pending]: (state, action) => {
            state.isprofRedload = false
        },
        [profilereloadTrigger.fulfilled]: (state, action) => {

            state.isprofRedload = action.payload.isprofRedload
        },
        [profilereloadTrigger.rejected]: (state, action) => {
            state.isprofRedload = false
        },
        //-------------- Mobile view trigger ----------------
        [mobileViewTrigger.pending]: (state, action) => {
            state.isMobileView = false
        },
        [mobileViewTrigger.fulfilled]: (state, action) => {

            state.isMobileView = action.payload.isMobileView
        },
        [mobileViewTrigger.rejected]: (state, action) => {
            state.isMobileView = false
        },
        //--------------- Theme -------------------------
        [swichThemes.pending]: (state, action) => {
            state.themeName = 'light'
        },
        [swichThemes.fulfilled]: (state, action) => {

            state.themeName = action.payload.themeName
        },
        [swichThemes.rejected]: (state, action) => {
            state.themeName = 'light'
        },
        //----------------- Editable menu --------------
        [switchMenu.pending]: (state, action) => {
            state.isEditableMenu = false
        },
        [switchMenu.fulfilled]: (state, action) => {

            state.isEditableMenu = action.payload.isEditableMenu
        },
        [switchMenu.rejected]: (state, action) => {
            state.isEditableMenu = false
        },
        



     }
})

export default dashboardSlice.reducer
