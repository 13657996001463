import { Box, Dialog, Text, TextInput, Button, IconButton, Spinner, Avatar, FormControl, Textarea } from "@primer/react"
import React from "react"
import style from '../../global.module.scss'
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { editProfileOpenClose, profilereloadTrigger } from "../redux/features/dashboardSlice"
import uploadIcon from '../../assets/uploadicon.svg'
import { XIcon } from "@primer/octicons-react"
import { doc, getDoc, updateDoc } from "firebase/firestore"
import { db } from "../../firebase"
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage"
import { useMediaQuery } from "react-responsive"
import { Toaster, Position, Intent } from '@blueprintjs/core';
import '@blueprintjs/core/lib/css/blueprint.css';
const storage = getStorage()
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}
const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
}

const contentBox = {

    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    margin: 'auto',
    width: ['80%', '581px'],
    borderRadius: '14px',
    border: '1px solid #D1D5DB',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '30px',
    paddingTop: '40px',
    paddingBottom: '40px',
    background: '#FFFFFF',
    zIndex: '1'
}

const contentBoxChildBox = {
    display: 'flex',
    flexDirection: 'column',
    width: ['90%', '350px'],
    height: 'fit-content',
    gap: '18px',
}

const contentBoxChildBox2 = {

    width: '489px',
    height: 'fit-content',
    gap: '19px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
}

const contentBoxChildBox2InnerBox = {
    height: '120px',
    width: '120px',
    background: '#F4F4F5',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

const inputStyle = {
    minHeight: '48px',
    maxHeight: '48px',
    borderRadius: '8px',

}
const NextBtnStyle = {

    minWidth: '120px',
    minHeight: '48px',
    maxHeight: '48px',
    background: '#000000',
    color: '#FFFFFF',
    borderRadius: '12px',
    '&:hover': {
        background: '#000000',
        backgroundColor: '#000000'

    }
}

//---------- Mobile tag ---------------------

const contentBoxMobile = {

    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    margin: 'auto',
    width: ['100%', '100%'],
    borderRadius: '14px',
    border: '1px solid #D1D5DB',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '30px',
    paddingTop: '40px',
    paddingBottom: '40px',
    background: '#FFFFFF',
    zIndex: '1'

}

const contentBoxChildBoxMobile = {
    display: 'flex',
    flexDirection: 'column',
    width: ['90%', '350px'],
    height: 'fit-content',
    gap: '18px',

}

const contentBoxChildBox2Mobile = {

    width: '85vw',
    height: 'fit-content',
    gap: '19px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

}

 
export default function EditProfileDialog() {

    const dispatch = useDispatch()
    const firebaseUser = useSelector((state) => state.firebaseStore.firebaseUser)
    const [showLoading, setshowLoading] = React.useState(false)
    const [selectedFile, setselectedFile] = React.useState(null)
    const [profileData, setprofileData] = React.useState({
        username: '',
        name: '',
        nameValidation: '',
        description: '',
        imageUrl: '',
    })
    const iseditprofileOpenClose = useSelector((state) => state.dasboardStore.iseditprofileOpenClose)
    const imageRef = React.useRef()


    const handlePickComputerFile = (props) => {

        const file = props;
        const fileSizeInBytes = file?.size;
        const fileSizeInKb = fileSizeInBytes / 1024;
        const fileSizeInMb = fileSizeInKb / 1024;

        if (fileSizeInMb > 3) {

              
              Toaster.create({ position: Position.TOP }).show({
                message: "File Size should less than 3 Mb",
                intent: Intent.PRIMARY
              });
 
        } else {

            setselectedFile(file)
            setprofileData((prevState) => {
                return ({ ...prevState, imageUrl: URL.createObjectURL(props) })
            })
 
        }
 
    }

    const updateImageInfirestore = (props) => {

        const file = props;

        setshowLoading(true)

        const fileName = "" + firebaseUser.uid + "" //props.name
        const metadata = {
            contentType: 'image/jpeg',
        };

        const storageRef = ref(storage, 'userprofileimages/' + fileName)
        const uploadTask = uploadBytesResumable(storageRef, file, metadata);
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed',
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                }
            },
            () => {
                // Upload completed successfully, now we can get the download URL

                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                    console.log('File available at', downloadURL);
                    setshowLoading(false)
                    setprofileData((prevState) => {
                        return ({ ...prevState, imageUrl: downloadURL })
                    })
                    const userRef = doc(db, 'users', firebaseUser.uid);
                    const updateResponse = await updateDoc(userRef, { imageUrl: downloadURL, name: profileData.name, description: profileData.description })
                    dispatch(profilereloadTrigger({
                        isprofRedload: true
                    }))
                    setselectedFile(null)
                    closeDialog("")
                });
            }
        );




    }


    const closeDialog = (props) => {

        dispatch(editProfileOpenClose({
            iseditprofileOpenClose: false
        }))
    }

    const handleChangeInput = (props, value) => {

        if (props === "name") {

            setprofileData((prevState) => {
                return ({ ...prevState, name: value, nameValidation: '' })
            })


        } else if (props === "description") {
            setprofileData((prevState) => {
                return ({ ...prevState, description: value, })
            })
        }

    }

    const handleClickNext = async (props) => {



        if (profileData.name === "") {

            setprofileData((prevState) => {
                return ({ ...prevState, nameValidation: 'error' })
            })

        } else {

            setprofileData((prevState) => {
                return ({ ...prevState, nameValidation: '' })
            })

            if (selectedFile !== null) {

                updateImageInfirestore(selectedFile)

            } else {

                const userRef = doc(db, 'users', firebaseUser.uid);
                const updateResponse = await updateDoc(userRef, { name: profileData.name, description: profileData.description })
                closeDialog("")
                dispatch(profilereloadTrigger({
                    isprofRedload: true
                }))

            }



        }
    }

    const getUserProfile = async (props) => {


        const userRef = doc(db, 'users', props);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {

            const responseData = docSnap.data()
            const userName = responseData?.username
            const userImage = responseData?.imageUrl
            const name = responseData?.name
            const description = responseData?.description
            setprofileData((prevState) => {
                return ({ ...prevState, name: name, description: description, username: userName, imageUrl: userImage })
            })





        } else {


        }
    }


    React.useEffect(() => {

        if (iseditprofileOpenClose) {
            getUserProfile(firebaseUser?.uid)
        }

    }, [iseditprofileOpenClose])


    return (<>

        <Default>
            <Dialog
                isOpen={iseditprofileOpenClose}
                onDismiss={() => {
                    closeDialog("")
                }}
                sx={{
                    width: '581px',
                    height: 'fit-content',
                }}
            >

                <Box sx={contentBox}>


                    <IconButton
                        sx={{
                            position: 'absolute',
                            right: '20px',
                            top: '20px',
                            background: 'transparent',
                            border: 'none'
                        }} icon={XIcon}
                        onClick={() => {
                            closeDialog("")
                        }}
                    />
                    <Box sx={contentBoxChildBox}>
                        <Text className={style.H7} sx={{ textAlign: 'center' }}>Update your bio</Text>
                        <Text className={style.p4} sx={{ textAlign: 'center' }}>supperlink.com/{profileData.username} is yours!</Text>
                    </Box>

                    <Box sx={contentBoxChildBox2}>

                        <Box sx={contentBoxChildBox2InnerBox}
                            onClick={() => {
                                imageRef.current.click()

                            }}
                        >
                            {showLoading ? <Spinner sx={{ position: 'absolute' }}></Spinner> : ''}

                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                {profileData.imageUrl === "" ?
                                    <>
                                        <img src={uploadIcon} style={{ width: '24px', height: '24px' }} />
                                        <Text className={style.p6} sx={{ textAlign: 'center' }}>Add Avatar</Text>
                                        <Text className={style.p6} sx={{ textAlign: 'center' }}>(max. 3MB)</Text>
                                    </>
                                    :
                                    <>

                                        <Avatar size={120} src={profileData.imageUrl} sx={{objectFit:'cover'}}></Avatar>
                                    </>
                                }


                            </Box>
                        </Box>
                        <input ref={imageRef} type={"file"} accept=".png, .jpg, .jpeg" onChange={(e) => handlePickComputerFile(e.target.files[0])} hidden />

                        {/* input design */}
                        <Box sx={{
                            width: '350px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px'
                        }}>
                            <TextInput
                                contrast
                                className={style.p4}
                                sx={inputStyle}
                                placeholder="name"
                                value={profileData.name}
                                onChange={(e) => {
                                    handleChangeInput("name", e.target.value)
                                }}
                                validationStatus={profileData.nameValidation}
                            ></TextInput>
                            <Box className={style.p7}
                            >
                                <FormControl>
                                    <FormControl.Label sx={{ fontSize: '10px' }}>Description(optional)</FormControl.Label>
                                    <Textarea
                                        value={profileData.description}
                                        onChange={(e) => {
                                            handleChangeInput("description", e.target.value)
                                        }}
                                        resize="none" cols={56} rows={7} contrast className={style.p4} />
                                </FormControl>
                            </Box>
                        </Box>
                        {/* ------------ */}

                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px'
                    }}>
                        <Button
                            variant="invisible"
                            className={style.p5}
                            sx={NextBtnStyle}
                            onClick={() => {
                                handleClickNext("")
                            }}
                        >
                            Update
                        </Button>

                    </Box>

                </Box>


            </Dialog>
        </Default>

        {/* -------------- Mobile responsive */}

        <Mobile>


            <Dialog
                isOpen={iseditprofileOpenClose}
                onDismiss={() => {
                    closeDialog("")
                }}
                sx={{
                    width: '90vw',
                    minWidth: '90vw',
                    maxWidth: '90vw',
                    top: '20px',
                    height: 'fit-content',
                    background: 'transparent'
                }}
            >


                <Box sx={contentBoxMobile}>


                    <IconButton
                        sx={{
                            position: 'absolute',
                            right: '20px',
                            top: '20px',
                            background: 'transparent',
                            border: 'none'
                        }} icon={XIcon}
                        onClick={() => {
                            closeDialog("")
                        }}
                    />
                    <Box sx={contentBoxChildBoxMobile}>
                        <Text className={style.H7} sx={{ textAlign: 'center' }}>Update your bio</Text>
                        <Text className={style.p4} sx={{ textAlign: 'center' }}>supperlink.com/{profileData.username} is yours!</Text>
                    </Box>

                    <Box sx={contentBoxChildBox2Mobile}>

                        <Box sx={contentBoxChildBox2InnerBox}
                            onClick={() => {
                                imageRef.current.click()

                            }}
                        >
                            {showLoading ? <Spinner sx={{ position: 'absolute' }}></Spinner> : ''}

                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                {profileData.imageUrl === "" ?
                                    <>
                                        <img src={uploadIcon} style={{ width: '24px', height: '24px' }} />
                                        <Text className={style.p6} sx={{ textAlign: 'center' }}>Add Avatar</Text>
                                        <Text className={style.p6} sx={{ textAlign: 'center' }}>(max. 3MB)</Text>
                                    </>
                                    :
                                    <>

                                        <Avatar size={120} src={profileData.imageUrl} sx={{objectFit:'cover'}}></Avatar>
                                    </>
                                }


                            </Box>
                        </Box>
                        <input ref={imageRef} type={"file"} accept=".png, .jpg, .jpeg" onChange={(e) => handlePickComputerFile(e.target.files[0])} hidden />

                        {/* input design */}
                        <Box sx={{
                            width: '80vw',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px'
                        }}>
                            <TextInput
                                contrast
                                className={style.p4}
                                sx={inputStyle}
                                placeholder="name"
                                value={profileData.name}
                                onChange={(e) => {
                                    handleChangeInput("name", e.target.value)
                                }}
                                validationStatus={profileData.nameValidation}
                            ></TextInput>
                            <Box className={style.p7}
                            >
                                <FormControl>
                                    <FormControl.Label sx={{ fontSize: '10px' }}>Description(optional)</FormControl.Label>
                                    <Textarea
                                        value={profileData.description}
                                        onChange={(e) => {
                                            handleChangeInput("description", e.target.value)
                                        }}
                                        resize="none" cols={56} rows={7} contrast className={style.p4} />
                                </FormControl>
                            </Box>
                        </Box>
                        {/* ------------ */}

                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px'
                    }}>
                        <Button
                            variant="invisible"
                            className={style.p5}
                            sx={NextBtnStyle}
                            onClick={() => {
                                handleClickNext("")
                            }}
                        >
                            Update
                        </Button>

                    </Box>

                </Box>

            </Dialog>
        </Mobile>

    </>)

}


