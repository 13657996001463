import { Box, Button,Text, TextInput, Textarea,FormControl, Avatar, Spinner } from "@primer/react";
import style from '../global.module.scss'
import { useMediaQuery } from 'react-responsive'
import uploadIcon from '../assets/uploadicon.svg'
import { useNavigate } from "react-router-dom";
import React from "react";
import { auth, db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import {getDownloadURL, getStorage,ref, uploadBytesResumable} from "firebase/storage"
import { Toaster, Position, Intent } from '@blueprintjs/core';
import '@blueprintjs/core/lib/css/blueprint.css';
const storage = getStorage();

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}
const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
}

const parentBox = {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
    background: style.pageBg,
    
}

const contentBox = {

    position: 'absolute',
    top: '75px',
    left: '0px',
    right: '0px',
    margin: 'auto',
    width: ['80%', '581px'],
    borderRadius: '14px',
    border: '1px solid #D1D5DB',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '30px',
    paddingTop: '40px',
    paddingBottom: '40px',
    background: '#FFFFFF',
    zIndex: '1'
}

const contentBoxChildBox = {
    display: 'flex',
    flexDirection: 'column',
    width: ['90%', '350px'],
    height: 'fit-content',
    gap: '18px',
}

const contentBoxChildBox2 = {

    width:'489px',
    height:'fit-content',
    gap:'19px',
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
} 

const contentBoxChildBox2InnerBox = {
    height:'120px',
    width:'120px',
    background:'#F4F4F5',
    borderRadius:'50%',
    cursor:'pointer',
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
}

const inputStyle = {
    minHeight: '48px',
    maxHeight:'48px',
    borderRadius: '8px',
   // border: '2px solid #E4E4E7'
}

const NextBtnStyle = {

    minWidth: '120px',
    minHeight: '48px',
    maxHeight: '48px',
    background: '#000000',
    color: '#FFFFFF',
    borderRadius: '12px',
    '&:hover': {
        background: '#000000',
        backgroundColor: '#000000'

    }
}

const LaterBtStyle = {
    minWidth: '120px',
    minHeight: '48px',
    maxHeight: '48px',
    background: '#F4F4F5',
    borderRadius: '12px',
    border:'none' 
}

//----------- Mobile responsive code ------------

const contentBoxMobile = {

    position: 'absolute',
    top: '75px',
    left: '0px',
    right: '0px',
    margin: 'auto',
    width: ['80%', '350px'],
    borderRadius: '14px',
    border: '1px solid #D1D5DB',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '30px',
    paddingTop: '40px',
    paddingBottom: '40px',
    background: '#FFFFFF',
    zIndex: '1'

   


}

const contentBoxChildBox2Mobile = {

    width:'489px',
    height:'fit-content',
    gap:'19px',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
}

const contentBoxChildBox2InnerBoxMobile = {
    height:'120px',
    width:'120px',
    background:'#F4F4F5',
    borderRadius:'50%',
    cursor:'pointer',
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
}



//-----------------------------------------------

export default function AccountProfileSetup(){

    const navigate = useNavigate()
    const [user,setuser] = React.useState(null)
    const userId = auth?.currentUser?.uid
    const imageRef = React.useRef()
    const [showLoading,setshowLoading] = React.useState(false)
    const [profileData,setprofileData] = React.useState({
          username:'',
          name:'',
          nameValidation:'',
          description:'',
          imageUrl:'',
    })

    const getCurrentUser =async (props) => {
      const user = auth.currentUser;

      if (user) {
          // User is signed in
          console.log('Current user:', user);

           
          const userRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(userRef);
          if (docSnap.exists()) {
                
               const responseData = docSnap.data()
               const userName = responseData?.username
               setprofileData((prevState)=>{
                   return({...prevState,username:userName,name:userName})
               })
          }


          return user;
      } else {
          // No user is signed in
          console.log('No user signed in.');

          return null;
      }
    };


    const handlePickComputerFile = (props) => {


            const file = props;
            const fileSizeInBytes = file.size;
            const fileSizeInKb = fileSizeInBytes / 1024;
            const fileSizeInMb = fileSizeInKb / 1024;

            if(fileSizeInMb > 3){

               // alert('File Size should less than 3 Mb')
                  Toaster.create({ position: Position.TOP }).show({
                    message: "File Size should less than 3 Mb",
                    intent: Intent.PRIMARY
                  });

                 
            }else{
                
                setshowLoading(true)
               
                 const fileName = ""+userId+"" //props.name
                 const metadata = {
                    contentType: 'image/jpeg',
                 };

                const storageRef = ref(storage,'userprofileimages/'+fileName) 
                const uploadTask = uploadBytesResumable(storageRef, file, metadata);
                // Listen for state changes, errors, and completion of the upload.
                uploadTask.on('state_changed',
                    (snapshot) => {
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused');
                                break;
                            case 'running':
                                console.log('Upload is running');
                                break;
                        }
                    },
                    (error) => {
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        switch (error.code) {
                            case 'storage/unauthorized':
                                // User doesn't have permission to access the object
                                break;
                            case 'storage/canceled':
                                // User canceled the upload
                                break;

                            // ...

                            case 'storage/unknown':
                                // Unknown error occurred, inspect error.serverResponse
                                break;
                        }
                    },
                    () => {
                        // Upload completed successfully, now we can get the download URL
                          
                            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            console.log('File available at', downloadURL);
                            setshowLoading(false)
                            setprofileData((prevState)=> {
                                 return({...prevState,imageUrl:downloadURL})
                            })
                             
                            });
                    }
                );

            }

            
            

          
          

    }

    const handleChangeInput = (props,value) => {

            if(props==="name"){

                setprofileData((prevState)=>{
                    return({...prevState,name:value,nameValidation:''})
                })


            }else if(props==="description"){
                setprofileData((prevState)=>{
                    return({...prevState,description:value,})
                })
            }
            
            
            
    }

    
    const handleClickNext =async (props)=>{

             const user = auth.currentUser;
             const userRef = doc(db, 'users', user.uid);
        

             if(profileData.name===""){

                setprofileData((prevState)=>{
                    return({...prevState,nameValidation:'error'})
                })

             }else{

                setprofileData((prevState)=>{
                    return({...prevState,nameValidation:''})
                })
                await updateDoc(userRef,{name:profileData.name,description:profileData.description,imageUrl:profileData.imageUrl})
                navigate('/addplatforms')       


             }
    }

    const handleLaterClick = (props) => {

                 navigate('/addplatforms')     
    }

    
    React.useState(()=>{
           setuser(getCurrentUser(""))
    })

    return(
        <>
            <Default>
                 <Box sx={parentBox}>
                    <Box sx={contentBox}>

                       <Box sx={contentBoxChildBox}>
                           <Text className={style.H7} sx={{ textAlign: 'center' }} >Tell us about yourself</Text>
                           <Text className={style.p4} sx={{ textAlign: 'center' }}>supperlink.com/{profileData.username} is yours!</Text>
                       </Box>
                       
                       <Box sx={contentBoxChildBox2}>
                            
                             <Box sx={contentBoxChildBox2InnerBox}
                             onClick={() => { imageRef.current.click() }}
                             >
                                      {showLoading ? <Spinner sx={{position:'absolute'}}></Spinner> :''}
                                     
                                      <Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                      {profileData.imageUrl===""?
                                        <>
                                            <img src={uploadIcon} style={{ width: '24px', height: '24px' }} />
                                            <Text className={style.p6} sx={{ textAlign: 'center' }}>Add Avatar</Text>
                                            <Text className={style.p6} sx={{ textAlign: 'center' }}>(max. 3MB)</Text>
                                        </>
                                      :
                                         <>
                                           
                                            <Avatar size={120} sx={{objectFit:'cover'}} src={profileData.imageUrl}></Avatar> 
                                         </>
                                      }
                                     
                                          
                                  </Box>
                             </Box>
                             <input ref={imageRef} type={"file"} onChange={(e) => handlePickComputerFile(e.target.files[0])} hidden />

                             {/* input design */}

                             <Box sx={{
                                width:'350px',
                                display:'flex',
                                flexDirection:'column',
                                gap:'20px'
                             }}>
                                <TextInput
                                contrast 
                                className={style.p4}

                                    sx={inputStyle}
                                    placeholder="name"
                                    value={profileData.name}
                                    onChange={(e)=>{
                                         handleChangeInput("name",e.target.value)
                                    }}
                                    validationStatus={profileData.nameValidation}
                                ></TextInput>
                                <Box className={style.p7}  
                                  >
                                    <FormControl>
                                        <FormControl.Label sx={{fontSize:'10px'}}>Description(optional)</FormControl.Label>
                                        <Textarea 
                                        value={profileData.description}
                                        onChange={(e)=>{
                                            handleChangeInput("description",e.target.value)
                                       }}
                                        resize="none" cols={56} rows={7} contrast className={style.p4} />
                                    </FormControl>
                                </Box>
                             </Box> 
                             {/* ------------ */}

                             

                       </Box>

                        <Box sx={{
                              display:'flex',
                              flexDirection:'row',
                              gap:'20px'
                        }}> 
                            <Button
                                variant="invisible"
                                className={style.p5}
                                sx={NextBtnStyle}
                                onClick={()=>{
                                    handleClickNext("")
                                }}
                            >
                                Next
                            </Button>
                            <Button
                               // variant="invisible"
                                className={style.p5}
                                sx={LaterBtStyle}
                                onClick={()=>{
                                    handleLaterClick("")
                                }}
                            >
                                Later
                            </Button>
                        </Box>

                    </Box>
                </Box>

            </Default>
            <Mobile>


                <Box sx={parentBox}>
                    <Box sx={contentBox}>
                       <Box sx={contentBoxChildBox}>
                           <Text className={style.H7} sx={{ textAlign: 'center' }} >Tell us about yourself</Text>
                           <Text className={style.p4} sx={{ textAlign: 'center' }}>supperlink.com/{profileData.username} is yours!</Text>
                       </Box>

                       <Box sx={contentBoxChildBox2Mobile}>
                            
                             <Box sx={contentBoxChildBox2InnerBoxMobile}
                             onClick={() => { imageRef.current.click() }}
                             >
                                   {showLoading ? <Spinner sx={{position:'absolute'}}></Spinner> :''} 

                                   <Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                       

                                      {profileData.imageUrl===""?
                                        <>
                                           <img src={uploadIcon} style={{width:'24px',height:'24px'}} /> 
                                          <Text className={style.p6} sx={{textAlign:'center'}}>Add Avatar</Text>  
                                          <Text className={style.p6} sx={{textAlign:'center'}}>(max. 3MB)</Text>   
                                        </>
                                      :
                                         <>
                                           
                                            <Avatar size={120} sx={{objectFit:'cover'}} src={profileData.imageUrl}></Avatar> 
                                         </>
                                      }



                                   </Box>
                             </Box>
                             <input ref={imageRef} type={"file"} onChange={(e) => handlePickComputerFile(e.target.files[0])} hidden />

                             {/* input design */}

                             <Box sx={{
                                width:'250px',
                                display:'flex',
                                flexDirection:'column',
                                gap:'20px'
                             }}>
                                <TextInput
                                contrast 
                                className={style.p4}
                                    sx={inputStyle}
                                    placeholder="name"
                                    value={profileData.name}
                                    onChange={(e)=>{
                                         handleChangeInput("name",e.target.value)
                                    }}
                                    validationStatus={profileData.nameValidation}
                                ></TextInput>
                                <Box className={style.p7}  
                                  >
                                    <FormControl>
                                        <FormControl.Label sx={{fontSize:'10px'}}>Description(optional)</FormControl.Label>
                                        <Textarea 
                                         value={profileData.description}
                                         onChange={(e)=>{
                                            handleChangeInput("description",e.target.value)
                                       }}
                                        resize="none" cols={56} rows={7} contrast className={style.p4} />
                                    </FormControl>
                                </Box>
                             </Box> 
                             {/* ------------ */}

                             

                       </Box>

                       <Box sx={{
                              display:'flex',
                              flexDirection:'row',
                              gap:'20px'
                        }}> 
                            <Button
                                variant="invisible"
                                className={style.p5}
                                sx={NextBtnStyle}
                                onClick={()=>{
                                    handleClickNext("")
                                }}
                            >
                                Next
                            </Button>
                            <Button
                               // variant="invisible"
                                className={style.p5}
                                sx={LaterBtStyle}
                            >
                                Later
                            </Button>
                        </Box>


                    </Box>
                </Box>

            </Mobile>
        </>
    )
}