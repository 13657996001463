import { Box, Text, ActionMenu, ActionList, Avatar, IconButton } from "@primer/react";
import style from '../global.module.scss'
import { useMediaQuery } from 'react-responsive'
import profileIcon from '../assets/profile.svg'
import React from "react";
import { auth, db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ArrowUpRightIcon, PencilIcon, TrashIcon } from "@primer/octicons-react";
import { useDispatch } from "react-redux";
import { editPlatformWidgetOpenClose, editProfileOpenClose, profilereloadTrigger, swichThemes } from "./redux/features/dashboardSlice";
import PlatFormEditDialog from "./Dialogs/PlatFormEditDialog";
import EditPlatformWidgetDialog from "./Dialogs/EditPlatformWidgetDialog";
import AddProjectWidgetDialog from "./Dialogs/AddProjectWidgetDialog";
import ActionBar from "./ActionBar";
import { useSelector } from "react-redux";
import EditProfileDialog from "./Dialogs/EditProfileDialog";
import { deleteObject, getStorage, ref } from "firebase/storage";
import { getAnalytics, logEvent } from "firebase/analytics";
import platformUrl from "./objects/platformUrl";
import platformBg from "./objects/plaformBg";
import mixpanel from 'mixpanel-browser';
import posthog from "posthog-js";
mixpanel.init('7a2c125cf3bf07b7fef81e91d0190b14');
posthog.init('phc_mGFMcRORH045UBsthDPi8bGDGMAPCvc7qtACPde72Hh', { api_host: 'https://app.posthog.com' })

const storage = getStorage()
const analytics = getAnalytics();
const iconWithBgColor = platformBg
const platformIdUrlArray = platformUrl

const Desktop = ({ children }) => {
    //const isDesktop = useMediaQuery({ minWidth: 992 })
    const isDesktop = useMediaQuery({ minWidth: 1224 })
    return isDesktop ? children : null
}

const TabletPortrait = ({ children }) => {

    //991
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991, orientation: 'portrait' })
    return isTablet ? children : null
}
const TabletLandscape = ({ children }) => {

    const isTablet = useMediaQuery({ minWidth: 992, maxWidth: 1223, orientation: 'landscape' })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}
const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
}

 
export default function Home(props) {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const firebaseUser = useSelector((state) => state.firebaseStore.firebaseUser)
    const loading = useSelector((state) => state.firebaseStore.loading)
    const isprofRedload = useSelector((state) => state.dasboardStore.isprofRedload)
    const isMobileView = useSelector((state) => state.dasboardStore.isMobileView)
    const themeName = useSelector((state) => state.dasboardStore.themeName)

    const user = auth?.currentUser
    const [profileImage, setprofileImage] = React.useState(profileIcon)
    const [platform, setplatforms] = React.useState([])
    const [userDate, setuserDate] = React.useState(null)
    const [hoverBoxId, sethoverBoxId] = React.useState("")

    //------------------------ javascript css code -------------------

    const parentBox = {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        minWidth: '100vw',
        background: style.pageBg,
        overflowX: 'hidden',
        overflowY: 'auto',
        position: 'relative',
    }

    const parentBoxDarkTheme = {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        minWidth: '100vw',
        background: style.darkTheme,
        overflowX: 'hidden',
        overflowY: 'auto',
        position: 'relative',
    }


    const profileBox = {
        position: 'absolute',
        top: '30px',
        left: '0px',
        right: '0px',
        margin: 'auto',
        width: '1180px',
        maxWidth: '1180px',
        minWidth: '1180px',
        height: '40px',
        display: 'flex',
        justifyContent: 'end'
    }

    const dragContextBox = {
        position: 'absolute',
        top: '104px',
        left: '0px',
        right: '0px',
        margin: 'auto',
        width: '1180px',
        maxWidth: '1180px',
        minWidth: '1180px',
        height: 'fit-content',
        display: 'flex',
        gap: '15px',
        flexWrap: 'wrap',
        paddingBottom: '100px'
    }

    const dropableBox = {
        display: 'flex',
        gap: '20px',
        flexWrap: 'wrap',
    }


    const profileDragBox = {
        width: '580px',
        minWidth: '576px',
        height: '280px',
        minWidth: '280px',
        minHeight: '280px',
        background: themeName === 'light' ? '#ffffff' : '#000000',
        borderRadius: '3px',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #D0D7DE',
        position: 'relative',

    }

    //------------ Desktop mobile design ------

    const profileBoxDesktopMobile = {
        position: 'absolute',
        top: '30px',
        left: '0px',
        right: '0px',
        margin: 'auto',
        width: '390px',
        maxWidth: '390px',
        minWidth: '390px',
        height: '40px',
        display: 'flex',
        justifyContent: 'end'
    }

    const dragContextBoxDesktopMobile = {
        position: 'absolute',
        top: '104px',
        left: '0px',
        right: '0px',
        margin: 'auto',
        width: '390px',
        maxWidth: '390px',
        minWidth: '390px',
        height: 'fit-content',
        display: 'flex',
        gap: '15px',
        flexWrap: 'wrap',
        paddingBottom: '100px',
    }

    const dropableBoxDeskTopMobile = {
        display: 'flex',
        gap: '20px',
        flexDirection: 'column'
    }

    const profileDragBoxDesktopMobile = {
        width: '390px',
        minWidth: '390px',
        height: '380px',
        minWidth: '390px',
        minHeight: '380px',
        background: themeName === 'light' ? '#ffffff' : '#000000',
        borderRadius: '3px',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #D0D7DE',
        position: 'relative'
    }


    //-----------------------------------------

    //------------ Mobile design --------------

    const profileBoxMobile = {
        position: 'absolute',
        top: '30px',
        left: '0px',
        right: '0px',
        margin: 'auto',
        width: '85vw',
        maxWidth: '85vw',
        minWidth: '85vw',
        height: '40px',
        display: 'flex',
        justifyContent: 'end'
    }

    const dragContextBoxMobile = {
        position: 'absolute',
        top: '104px',
        left: '0px',
        right: '0px',
        margin: 'auto',
        width: '90vw',
        maxWidth: '90vw',
        minWidth: '90vw',
        height: 'fit-content',
        display: 'flex',
        gap: '15px',
        flexWrap: 'wrap',
        paddingBottom: '100px',
    }

    const dropableBoxMobile = {
        display: 'flex',
        gap: '20px',
        flexDirection: 'column'
    }

    const profileDragBoxMobile = {
        width: '90vw',
        minWidth: '90vw',
        height: '380px',
        minHeight: '380px',
        minWidth: '280px',
        background: themeName === 'light' ? '#ffffff' : '#000000',
        borderRadius: '3px',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #D0D7DE',
        position: 'relative'
    }

    //----------Tab design --------------


    const profileBoxTab = {
        position: 'absolute',
        top: '30px',
        left: '0px',
        right: '0px',
        margin: 'auto',
        width: 'calc(100vw - 100px)',
        maxWidth: 'calc(100vw - 100px)',
        minWidth: 'calc(100vw - 100px)',
        height: '40px',
        display: 'flex',
        justifyContent: 'end',
    }

    const dragContextBoxTab = {
        position: 'absolute',
        top: '104px',
        left: '0px',
        right: '0px',
        margin: 'auto',
        width: 'calc(100vw - 70px)',
        maxWidth: 'calc(100vw - 70px)',
        minWidth: 'calc(100vw - 70px)',
        height: 'fit-content',
        display: 'flex',
        gap: '15px',
        flexWrap: 'wrap',
        paddingBottom: '100px',
    }

    const dropableBoxTab = {
        display: 'flex',
        gap: '20px',
        flexWrap: 'wrap',
    }

    const profileDragBoxTab = {
        width: '435px',
        minWidth: '435px',
        height: '280px',
        minWidth: '435px',
        minHeight: '280px',
        background: themeName === 'light' ? '#ffffff' : '#000000',
        borderRadius: '3px',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #D0D7DE',
        position: 'relative'
    }

    //-----------------------------------


    //-----------------------------------------------------------

    const getUserPlatforms = async (props) => {

        if (props !== null && props !== "" && props !== undefined) {


            const userRef = doc(db, 'users', props);
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {

                const responseData = docSnap.data()

                const userTheme = responseData?.themeName || 'light'

                dispatch(swichThemes({
                    themeName: userTheme
                }))


                setuserDate(responseData)
                const userImage = responseData?.imageUrl
                setprofileImage(userImage)

                const responseArray = [...responseData.platforms]

                const check = responseArray.find((pData) => pData.id === "profile")
                if (check) {

                    const platformArray = [...responseData.platforms]
                    const updatedArray = [...platformArray].filter((row) => row.value !== "").map((row, index) => {

                        return ({ ...row, position: index })
                    })
                    setplatforms(updatedArray)


                } else {

                    const platformArray = [{ id: 'profile', icon: 'profile', symbol: '@', placeholder: 'profile', value: 'profile', position: 0, userDate: responseData }, ...responseData.platforms]
                    const updatedArray = [...platformArray].filter((row) => row.value !== "").map((row, index) => {

                        return ({ ...row, position: index })
                    })
                    setplatforms(updatedArray)

                }

                mixpanel.track('ActiveUsers', {
                    $distinct_id: firebaseUser?.uid,
                    time: new Date().getTime(),
                    user_id: firebaseUser?.uid,
                    user_email: firebaseUser?.email
                })

                posthog.capture(
                    'ActiveUsers',
                    {
                        distinct_id: firebaseUser?.uid,
                        $set_once: { user_id: firebaseUser?.uid, user_email: firebaseUser?.email },
                        user_id: firebaseUser?.uid,
                        user_email: firebaseUser?.email,
                        timestamp: new Date().toISOString(),
                    }
                )

                posthog.capture('UserPlatForms', {
                    distinct_id: firebaseUser?.uid,
                    user_id: firebaseUser?.uid,
                    user_email: firebaseUser?.email,
                    timestamp: new Date().toISOString(),
                    platforms: responseArray
                })



            } else {


            }




        } else {


        }


    }

    const handleLogout = (props) => {

        signOut(auth).then(() => {
            // Sign-out successful.
            navigate('/login')
            mixpanel.track('Logout', {
                $distinct_id: firebaseUser?.uid,
                time: new Date().getTime(),
                user_id: firebaseUser?.uid,
                user_email: firebaseUser?.email
            })
            posthog.reset()
 
        }).catch((error) => {
            // An error happened.
        });

    }

    const updatePlatforminFirebase = async (props) => {

        const userRef = doc(db, 'users', firebaseUser?.uid);
        const updateResponse = await updateDoc(userRef, { platforms: [...props] })

    }

    const onDragEnd = (result) => {

        console.log("onDragEnd", result)
        if (!result.destination) return;
        const { source, destination } = result;
        const destIndex = result?.destination?.index
        const sourceIndex = result?.source?.index
        console.log("abc", destIndex, destIndex)

        setplatforms((prevState) => {
            const updatedItems = [...prevState];
            const itemToMove = updatedItems.splice(sourceIndex, 1)[0];
            updatedItems.splice(destIndex, 0, itemToMove);

            const updatedDbarray = [...updatedItems].map((pDta, index) => { return ({ ...pDta, position: index }) })
            updatePlatforminFirebase(updatedDbarray)

            return updatedItems
        })





    }


    const openEditplatWidgetDialog = (props) => {


        dispatch(editPlatformWidgetOpenClose({
            platformWidgetopenClose: true,
            platformId: props.id
        }))

    }

    const handleDeletePlatform = async (props) => {

        const userRef = doc(db, 'users', firebaseUser?.uid);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
            const responseData = docSnap.data()
            const plaforms = [...responseData.platforms]
            const updatePlatforms = plaforms.filter((pData) => pData?.id !== props.id)
            // alert(JSON.stringify(updatePlatforms)) 
            const fileName = props?.selectedFileName
            const platfromType = props?.platformType

            if (fileName !== "" && platfromType === "custom") {

                // alert(fileName)

                const deleteRef = ref(storage, `platforms/${firebaseUser?.uid}/${fileName}`);

                // Delete the file
                deleteObject(deleteRef).then(async () => {

                    const userRef = doc(db, 'users', firebaseUser?.uid);
                    const updateResponse = await updateDoc(userRef, { platforms: updatePlatforms })
                    dispatch(profilereloadTrigger({
                        isprofRedload: true
                    }))


                }).catch((error) => {
                    // Uh-oh, an error occurred!
                });



            } else {

                const userRef = doc(db, 'users', firebaseUser?.uid);
                const updateResponse = await updateDoc(userRef, { platforms: updatePlatforms })
                dispatch(profilereloadTrigger({
                    isprofRedload: true
                }))


            }



        } else {


        }

    }

    const openEditProfileDialog = (props) => {

        dispatch(editProfileOpenClose({
            iseditprofileOpenClose: true
        }))
    }

    const handleOpenLinkNewTab = (props) => {

        mixpanel.track('LinkOpenInteral', {
            $distinct_id: firebaseUser?.uid,
            time: new Date().getTime(),
            user_id: firebaseUser?.uid,
            user_email: firebaseUser?.email,
            platform:props
        })

        posthog.capture(
            'LinkOpenInteral',
            {
                distinct_id: firebaseUser?.uid,
                $set_once: {
                    user_id: firebaseUser?.uid,
                    user_email: firebaseUser?.email,
                    platform:props
                },
                user_id: firebaseUser?.uid,
                user_email: firebaseUser?.email,
                platform:props,
                timestamp: new Date().toISOString(),
            }
        )


        if (props?.platformType === "custom") {

            window.open(props?.link, '_blank')

        } else {

            if (props?.id === "whatsapp") {

                const baseUrl = platformIdUrlArray?.filter((pData) => pData?.id === props?.id)[0]?.url + props?.countrycode + props?.value
                window.open(baseUrl, '_blank')
            } else {

                const baseUrl = platformIdUrlArray?.filter((pData) => pData?.id === props?.id)[0]?.url + props.value
                window.open(baseUrl, '_blank')
            }


        }

    }


    React.useEffect(() => {


        getUserPlatforms(firebaseUser?.uid)

        if (isprofRedload) {

            getUserPlatforms(firebaseUser?.uid)


        }

        logEvent(analytics,'active_users',{
            userId:firebaseUser?.uid,
            userEmail:firebaseUser?.email
        })


    }, [firebaseUser, isprofRedload])




    const DeskTopView = (props) => {

        return (<>

            <Box sx={
                themeName === 'light' ? parentBox : parentBoxDarkTheme
            }>

                <Box sx={profileBox}>
                    <ActionMenu>
                        <ActionMenu.Anchor>
                            <Box >

                                <img style={{ width: '40px', height: '40px', borderRadius: '50%', cursor: 'pointer', objectFit: 'cover' }}
                                    src={profileImage !== "" && profileImage !== null ? profileImage : profileIcon}
                                />
                            </Box>
                        </ActionMenu.Anchor>
                        <ActionMenu.Overlay width="small">
                            <ActionList sx={{ fontFamily: 'Inter' }}>
                                <ActionList.Item onSelect={() => {

                                }}>My bio</ActionList.Item>
                                <ActionList.Item onSelect={() => { }}>Upgrade</ActionList.Item>
                                <ActionList.Item onSelect={() => {
                                    handleLogout("")
                                }}>Logout</ActionList.Item>

                            </ActionList>
                        </ActionMenu.Overlay>
                    </ActionMenu>
                </Box>

                <Box sx={dragContextBox}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable
                            droppableId="droppable"
                        >
                            {(provided, snapshot) => (
                                <Box
                                    sx={dropableBox}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {platform.map((row, index) => (


                                        row?.value === "profile" ?


                                            <Droppable
                                                droppableId={"" + row.id + ""}
                                            >
                                                {(provided, snapshot) => (

                                                    <Box
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        <Draggable
                                                            draggableId={"" + row.id + ""}
                                                            index={index}
                                                            key={"" + row.id + ""}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <Box>
                                                                    <Box
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        sx={profileDragBox}

                                                                        onMouseEnter={() => {
                                                                            sethoverBoxId(row.id)
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            sethoverBoxId("")
                                                                        }}
                                                                    >

                                                                        <Box sx={{
                                                                            position: 'absolute',
                                                                            top: '14px',
                                                                            right: '21px',
                                                                            width: 'fit-content',
                                                                            height: 'fit-content',
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',

                                                                        }}>
                                                                            <IconButton
                                                                                onClick={() => { openEditProfileDialog("") }}
                                                                                sx={{ 
                                                                                    display: hoverBoxId === row.id ? 'flex' : 'none', 
                                                                                    marginLeft: '21px', 
                                                                                    background: 'transparent', 
                                                                                    border: 'none',
                                                                                    color:themeName==='dark'?'white':'black',
                                                                                    ":hover":{
                                                                                        color:'black'
                                                                                    }
                                                                                    }} icon={PencilIcon} />
                                                                        </Box>

                                                                        <Box sx={{
                                                                            marginTop: '50px',
                                                                            marginLeft: '45px',
                                                                            width: '500px',
                                                                            height: '154px',
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            gap: '40px'
                                                                        }}>

                                                                            <Box sx={{
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                                alignItems: 'center'
                                                                            }}>
                                                                                {userDate?.imageUrl === "" ?
                                                                                    <>

                                                                                        {/* <img src={uploadIcon} style={{ width: '24px', height: '24px' }} />
                                                                                        <Text className={style.p6} sx={{ textAlign: 'center' }}>Add Avatar</Text>
                                                                                        <Text className={style.p6} sx={{ textAlign: 'center' }}>(max. 3MB)</Text> */}
                                                                                        <Avatar size={120} src={profileIcon}></Avatar>
                                                                                    </>
                                                                                    :
                                                                                    <>

                                                                                        <Avatar size={120} src={userDate?.imageUrl} sx={{ objectFit: 'cover' }} ></Avatar>

                                                                                    </>
                                                                                }
                                                                            </Box>
                                                                            <Box sx={{
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                                gap: '15px',
                                                                            }}>
                                                                                <Text className={themeName === "light" ? style.H7 : style.H7dark} sx={{ width: '340px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{userDate?.name}</Text>
                                                                                <Text className={themeName === "light" ? style.p4 : style.p4dark} sx={{ width: '340px', overflow: 'hidden', textOverflow: 'ellipsis', height: '96px' }}>
                                                                                    {userDate?.description}
                                                                                </Text>
                                                                            </Box>

                                                                        </Box>

                                                                    </Box>
                                                                </Box>


                                                            )}
                                                        </Draggable>
                                                    </Box>
                                                )}
                                            </Droppable>




                                            :

                                            <Droppable
                                                droppableId={"" + row.id + ""}
                                            // droppableId={""+Math.random(10000,20000)+""}
                                            >
                                                {(provided, snapshot) => (

                                                    <Box
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >

                                                        <Draggable
                                                            draggableId={"" + row.id + ""}
                                                            index={index}
                                                            key={"" + row.id + ""}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <Box>
                                                                    <Box
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        sx={{
                                                                            position: 'relative',
                                                                            width: '280px',
                                                                            height: '280px',
                                                                            minWidth: '280px',
                                                                            minHeight: '280px',
                                                                            background: iconWithBgColor?.filter((data) => data?.icon === row?.icon)[0]?.bgColor || 'rgba(161, 161, 170, 0.15)',
                                                                            borderRadius: '3px',
                                                                            border: '1px solid #D0D7DE',
                                                                            ":hover": {
                                                                                // background: '#F4F4F5'
                                                                            },
                                                                        }}
                                                                        onMouseEnter={() => {
                                                                            sethoverBoxId(row.id)
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            sethoverBoxId("")
                                                                        }}
                                                                    >


                                                                        <Box sx={{
                                                                            position: 'absolute',
                                                                            top: '90px',
                                                                            left: '90px',
                                                                            margin: 'auto'
                                                                        }}>
                                                                            {row?.platformType === "custom" ?

                                                                                row.icon === "" ? <>
                                                                                    <Box sx={{
                                                                                        height: '100px',
                                                                                        width: '100px',
                                                                                        borderRadius: '50%',
                                                                                        background: '#A1A1AA',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center'
                                                                                    }}>
                                                                                        <Text className={style.H1} sx={{ textAlign: 'center' }} >{(row?.value?.substring(0, 1)?.toUpperCase())}</Text>
                                                                                    </Box>
                                                                                </> :

                                                                                    <>
                                                                                        <img
                                                                                            src={row.icon}
                                                                                            style={{ height: '100px', width: '100px', borderRadius: '50%', objectFit: 'cover' }}
                                                                                        >
                                                                                        </img>
                                                                                    </>


                                                                                :
                                                                                <img
                                                                                    src={iconWithBgColor?.filter((data) => data?.icon === row?.icon)[0]?.icon}
                                                                                    style={{ height: '100px', width: '100px' }}
                                                                                >
                                                                                </img>

                                                                            }

                                                                        </Box>

                                                                        <Box sx={{
                                                                            position: 'absolute',
                                                                            top: '14px',
                                                                            left: '0px',
                                                                            right: '0px',
                                                                            margin: 'auto',
                                                                            width: '100%',
                                                                            height: 'fit-content',
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',
                                                                        }}>
                                                                            <IconButton onClick={() => { openEditplatWidgetDialog(row) }}
                                                                                sx={{
                                                                                    display: hoverBoxId === row.id ? 'flex' : 'none', marginLeft: '21px', background: 'transparent', border: 'none', color: themeName === 'dark' ? 'white' : 'black',
                                                                                    ":hover": {
                                                                                        color: 'black'
                                                                                    }
                                                                                }} icon={PencilIcon} />
                                                                            <IconButton onClick={() => {
                                                                                handleDeletePlatform(row)
                                                                            }}
                                                                                sx={{
                                                                                    display: hoverBoxId === row.id ? 'flex' : 'none', position: 'absolute', right: '21px', background: 'transparent', border: 'none', color: themeName === 'dark' ? 'white' : 'black',
                                                                                    ":hover": {
                                                                                        color: 'black'
                                                                                    }
                                                                                }} icon={TrashIcon} />

                                                                        </Box>

                                                                        <Box sx={{
                                                                            position: 'absolute',
                                                                            bottom: '24px',
                                                                            left: '0px',
                                                                            right: '0px',
                                                                            margin: 'auto',
                                                                            width: '230px',
                                                                            height: '36px',
                                                                            background: '#F4F4F5',
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            gap: '15px',

                                                                            ":hover": {
                                                                                background: '#f3f4f6',
                                                                                cursor: 'pointer'
                                                                            }
                                                                        }}
                                                                            onClick={() => {

                                                                                handleOpenLinkNewTab(row)


                                                                            }}

                                                                        >

                                                                            <Text className={style.p3} sx={{ textAlign: 'center', width: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >

                                                                                {row?.label !== "" && row?.label !== null && row?.label !== undefined ? row.label :

                                                                                    row?.platformType !== "custom" ?
                                                                                        "@" + row.value
                                                                                        :
                                                                                        row.value

                                                                                }
                                                                            </Text>
                                                                            <Box >
                                                                                <ArrowUpRightIcon />
                                                                            </Box>

                                                                        </Box>


                                                                    </Box>
                                                                </Box>


                                                            )}
                                                        </Draggable>

                                                    </Box>

                                                )}

                                            </Droppable>



                                    ))}


                                </Box>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {/* ---------- */}
                </Box>


            </Box>
            <PlatFormEditDialog />
            <EditPlatformWidgetDialog />
            <AddProjectWidgetDialog />
            <EditProfileDialog />

            <ActionBar username={userDate?.username} />
        </>)
    }


    const MobileView = (props) => {

        return (<>
            <Box sx={
                //parentBox
                themeName === 'light' ? parentBox : parentBoxDarkTheme
            }>
                {/* Profile section ------------------- */}
                <Box sx={profileBoxMobile}>
                    <ActionMenu>
                        <ActionMenu.Anchor>
                            <Box >
                                <img style={{ width: '40px', height: '40px', borderRadius: '50%', cursor: 'pointer', objectFit: 'cover' }}
                                    src={profileImage !== "" && profileImage !== null ? profileImage : profileIcon}
                                />
                            </Box>
                        </ActionMenu.Anchor>
                        <ActionMenu.Overlay width="small">
                            <ActionList sx={{ fontFamily: 'Inter' }}>
                                <ActionList.Item onSelect={() => {

                                }}>My bio</ActionList.Item>
                                <ActionList.Item onSelect={() => { }}>Upgrade</ActionList.Item>
                                <ActionList.Item onSelect={() => {
                                    handleLogout("")
                                }}>Logout</ActionList.Item>

                            </ActionList>
                        </ActionMenu.Overlay>
                    </ActionMenu>
                </Box>
                {/* ---------------------------------- */}

                {/*--------- Drag contenx------------  */}

                <Box sx={dragContextBoxMobile}>

                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable" >
                            {(provided, snapshot) => (
                                <Box
                                    sx={dropableBoxMobile}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {platform.map((row, index) => (


                                        row?.value === "profile" ?

                                            <Droppable droppableId={"" + row.id + ""}>
                                                {(provided, snapshot) => (

                                                    <Box
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        <Draggable
                                                            draggableId={"" + row.id + ""}
                                                            index={index}
                                                            key={"" + row.id + ""}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <Box>
                                                                    <Box
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        sx={profileDragBoxMobile}
                                                                        onMouseEnter={() => {
                                                                            sethoverBoxId(row.id)
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            sethoverBoxId("")
                                                                        }}
                                                                    >

                                                                        <Box sx={{
                                                                            position: 'absolute',
                                                                            top: '14px',
                                                                            right: '21px',
                                                                            width: 'fit-content',
                                                                            height: 'fit-content',
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',

                                                                        }}>
                                                                            <IconButton
                                                                                onClick={() => { openEditProfileDialog("") }}
                                                                                sx={{ 
                                                                                display: hoverBoxId === row.id ? 'flex' : 'none', 
                                                                                marginLeft: '21px', 
                                                                                background: 'transparent', 
                                                                                border: 'none',
                                                                                color:themeName==='dark'?'white':'black',
                                                                                ":hover":{
                                                                                    color:'black'
                                                                                }
                                                                              }} 
                                                                                icon={PencilIcon} />
                                                                        </Box>

                                                                        <Box sx={{
                                                                            marginTop: '50px',
                                                                            marginLeft: '20px',
                                                                            width: '80vw',
                                                                            height: '154px',
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            gap: '40px',

                                                                        }}>

                                                                            <Box sx={{
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                                alignItems: 'center'
                                                                            }}>
                                                                                {userDate?.imageUrl === "" ?
                                                                                    <>
                                                                                        <Avatar size={120} src={profileIcon}></Avatar>
                                                                                        {/* <img src={uploadIcon} style={{ width: '24px', height: '24px' }} />
                                                                                             <Text className={style.p6} sx={{ textAlign: 'center' }}>Add Avatar</Text>
                                                                                             <Text className={style.p6} sx={{ textAlign: 'center' }}>(max. 3MB)</Text> */}
                                                                                    </>
                                                                                    :
                                                                                    <>

                                                                                        <Avatar size={120} src={userDate?.imageUrl} sx={{ objectFit: 'cover' }} ></Avatar>

                                                                                    </>
                                                                                }
                                                                            </Box>
                                                                            <Box sx={{
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                                gap: '15px',
                                                                            }}>
                                                                                <Text className={themeName === "light" ? style.H7 : style.H7dark} sx={{ width: '300px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{userDate?.name}</Text>
                                                                                <Text className={themeName === "light" ? style.p4 : style.p4dark} sx={{ width: '300px', overflow: 'hidden', textOverflow: 'ellipsis', height: '109px' }}>
                                                                                    {userDate?.description}
                                                                                </Text>
                                                                            </Box>

                                                                        </Box>

                                                                    </Box>
                                                                </Box>


                                                            )}
                                                        </Draggable>

                                                    </Box>
                                                )}
                                            </Droppable>


                                            :

                                            <Droppable droppableId={"" + row.id + ""}>
                                                {(provided, snapshot) => (

                                                    <Box
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >

                                                        <Draggable
                                                            draggableId={"" + row.id + ""}
                                                            index={index}
                                                            key={"" + row.id + ""}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <Box>
                                                                    <Box
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        sx={{
                                                                            position: 'relative',
                                                                            width: '80vw',
                                                                            minWidth: '90vw',
                                                                            height: '280px',
                                                                            minHeight: '280px',
                                                                            background: iconWithBgColor?.filter((data) => data?.icon === row?.icon)[0]?.bgColor || 'rgba(161, 161, 170, 0.15)',
                                                                            borderRadius: '3px',
                                                                            border: '1px solid #D0D7DE',
                                                                            ":hover": {
                                                                                // background: '#F4F4F5'
                                                                            },
                                                                        }}
                                                                        onMouseEnter={() => {
                                                                            sethoverBoxId(row.id)
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            sethoverBoxId("")
                                                                        }}
                                                                    >


                                                                        <Box sx={{
                                                                            position: 'absolute',
                                                                            top: '90px',
                                                                            left: '0px',
                                                                            right: '0px',
                                                                            margin: 'auto',
                                                                            height: 'fit-content',
                                                                            width: 'fit-content'
                                                                        }}>
                                                                            {row?.platformType === "custom" ?

                                                                                row.icon === "" ? <>
                                                                                    <Box sx={{
                                                                                        height: '100px',
                                                                                        width: '100px',
                                                                                        borderRadius: '50%',
                                                                                        background: '#A1A1AA',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center'
                                                                                    }}>
                                                                                        <Text className={style.H1} sx={{ textAlign: 'center' }} >{(row?.value?.substring(0, 1)?.toUpperCase())}</Text>
                                                                                    </Box>
                                                                                </> :

                                                                                    <>
                                                                                        <img
                                                                                            src={row.icon}
                                                                                            style={{ height: '100px', width: '100px', borderRadius: '50%', objectFit: 'cover' }}
                                                                                        >
                                                                                        </img>
                                                                                    </>


                                                                                :
                                                                                <img
                                                                                    src={iconWithBgColor?.filter((data) => data?.icon === row?.icon)[0]?.icon}
                                                                                    style={{ height: '100px', width: '100px' }}
                                                                                >
                                                                                </img>

                                                                            }

                                                                        </Box>

                                                                        <Box sx={{
                                                                            position: 'absolute',
                                                                            top: '14px',
                                                                            left: '0px',
                                                                            right: '0px',
                                                                            margin: 'auto',
                                                                            width: '100%',
                                                                            height: 'fit-content',
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',
                                                                        }}>
                                                                            <IconButton onClick={() => { openEditplatWidgetDialog(row) }}
                                                                                sx={{ display: hoverBoxId === row.id ? 'flex' : 'none', marginLeft: '21px', background: 'transparent', border: 'none', color:themeName==='dark'?'white':'black',
                                                                                ":hover":{
                                                                                    color:'black'
                                                                                } }} icon={PencilIcon} />
                                                                            <IconButton onClick={() => {
                                                                                handleDeletePlatform(row)
                                                                            }}
                                                                                sx={{ display: hoverBoxId === row.id ? 'flex' : 'none', position: 'absolute', right: '21px', background: 'transparent', border: 'none', color:themeName==='dark'?'white':'black',
                                                                                ":hover":{
                                                                                    color:'black'
                                                                                } }} icon={TrashIcon} />

                                                                        </Box>

                                                                        <Box sx={{
                                                                            position: 'absolute',
                                                                            bottom: '24px',
                                                                            left: '0px',
                                                                            right: '0px',
                                                                            margin: 'auto',
                                                                            width: '230px',
                                                                            height: '36px',
                                                                            background: '#F4F4F5',
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            gap: '15px',

                                                                            ":hover": {
                                                                                background: '#f3f4f6',
                                                                                cursor: 'pointer'
                                                                            }
                                                                        }}
                                                                            onClick={() => {

                                                                                handleOpenLinkNewTab(row)


                                                                            }}

                                                                        >

                                                                            <Text className={style.p3} sx={{ textAlign: 'center', width: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >
                                                                                {row?.label !== "" && row?.label !== null && row?.label !== undefined ? row.label :

                                                                                    row?.platformType !== "custom" ?
                                                                                        "@" + row.value
                                                                                        :
                                                                                        row.value

                                                                                }
                                                                            </Text>
                                                                            <Box >
                                                                                <ArrowUpRightIcon />
                                                                            </Box>

                                                                        </Box>


                                                                    </Box>
                                                                </Box>


                                                            )}
                                                        </Draggable>

                                                    </Box>

                                                )}
                                            </Droppable>



                                    ))}


                                </Box>
                            )}
                        </Droppable>
                    </DragDropContext>

                    {/* ---------- */}
                </Box>


                {/* ---------------------------------- */}

            </Box>

            <PlatFormEditDialog />
            <EditPlatformWidgetDialog />
            <AddProjectWidgetDialog />
            <EditProfileDialog />
            <ActionBar username={userDate?.username} />
        </>)
    }


    const DeskTopMobileView = (props) => {

        return (<>
            <Box sx={
                //{...parentBox}
                themeName === 'light' ? parentBox : parentBoxDarkTheme
            }>
                {/* Profile section ------------------- */}
                <Box sx={profileBoxDesktopMobile}>
                    <ActionMenu>
                        <ActionMenu.Anchor>
                            <Box>

                                <img style={{ width: '40px', height: '40px', borderRadius: '50%', cursor: 'pointer', objectFit: 'cover' }}
                                    src={profileImage !== "" && profileImage !== null ? profileImage : profileIcon}
                                />
                            </Box>
                        </ActionMenu.Anchor>
                        <ActionMenu.Overlay width="small">
                            <ActionList sx={{ fontFamily: 'Inter' }}>
                                <ActionList.Item onSelect={() => {

                                }}>My bio</ActionList.Item>
                                <ActionList.Item onSelect={() => { }}>Upgrade</ActionList.Item>
                                <ActionList.Item onSelect={() => {
                                    handleLogout("")
                                }}>Logout</ActionList.Item>

                            </ActionList>
                        </ActionMenu.Overlay>
                    </ActionMenu>
                </Box>





                {/* ---------------------------------- */}

                {/*--------- Drag contenx------------  */}

                <Box sx={dragContextBoxDesktopMobile}>

                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable" >
                            {(provided, snapshot) => (
                                <Box
                                    sx={dropableBoxDeskTopMobile}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {platform.map((row, index) => (


                                        row?.value === "profile" ?

                                            <Droppable droppableId={"" + row.id + ""}>
                                                {(provided, snapshot) => (

                                                    <Box
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        <Draggable
                                                            draggableId={"" + row.id + ""}
                                                            index={index}
                                                            key={"" + row.id + ""}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <Box>
                                                                    <Box
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        sx={profileDragBoxDesktopMobile}
                                                                        onMouseEnter={() => {
                                                                            sethoverBoxId(row.id)
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            sethoverBoxId("")
                                                                        }}
                                                                    >

                                                                        <Box sx={{
                                                                            position: 'absolute',
                                                                            top: '14px',
                                                                            right: '21px',
                                                                            width: 'fit-content',
                                                                            height: 'fit-content',
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',

                                                                        }}>
                                                                            <IconButton
                                                                                onClick={() => { openEditProfileDialog("") }}
                                                                                sx={{ display: hoverBoxId === row.id ? 'flex' : 'none', marginLeft: '21px', background: 'transparent', border: 'none',
                                                                                color:themeName==='dark'?'white':'black',
                                                                                ":hover":{
                                                                                    color:'black'
                                                                                }
                                                                                }} icon={PencilIcon} />
                                                                        </Box>

                                                                        <Box sx={{
                                                                            marginTop: '50px',
                                                                            marginLeft: '20px',
                                                                            width: '348px',
                                                                            height: '154px',
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            gap: '40px',

                                                                        }}>

                                                                            <Box sx={{
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                                alignItems: 'center'
                                                                            }}>
                                                                                {userDate?.imageUrl === "" ?
                                                                                    <>

                                                                                        <Avatar size={120} src={profileIcon}></Avatar>
                                                                                    </>
                                                                                    :
                                                                                    <>

                                                                                        <Avatar size={120} src={userDate?.imageUrl} sx={{ objectFit: 'cover' }} ></Avatar>

                                                                                    </>
                                                                                }
                                                                            </Box>
                                                                            <Box sx={{
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                                gap: '15px',
                                                                            }}>
                                                                                <Text className={themeName === "light" ? style.H7 : style.H7dark} sx={{ width: '300px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{userDate?.name}</Text>
                                                                                <Text className={themeName === "light" ? style.p4 : style.p4dark} sx={{ width: '300px', overflow: 'hidden', textOverflow: 'ellipsis', height: '96px' }}>
                                                                                    {userDate?.description}
                                                                                </Text>
                                                                            </Box>

                                                                        </Box>

                                                                    </Box>
                                                                </Box>


                                                            )}
                                                        </Draggable>

                                                    </Box>
                                                )}
                                            </Droppable>


                                            :

                                            <Droppable droppableId={"" + row.id + ""}>
                                                {(provided, snapshot) => (

                                                    <Box
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >

                                                        <Draggable
                                                            draggableId={"" + row.id + ""}
                                                            index={index}
                                                            key={"" + row.id + ""}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <Box>
                                                                    <Box
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        sx={{
                                                                            position: 'relative',
                                                                            width: '390px',
                                                                            minWidth: '390px',
                                                                            height: '280px',
                                                                            minHeight: '280px',
                                                                            background: iconWithBgColor?.filter((data) => data?.icon === row?.icon)[0]?.bgColor || 'rgba(161, 161, 170, 0.15)',
                                                                            borderRadius: '3px',
                                                                            border: '1px solid #D0D7DE',
                                                                            ":hover": {
                                                                                // background: '#F4F4F5'
                                                                            },
                                                                        }}
                                                                        onMouseEnter={() => {
                                                                            sethoverBoxId(row.id)
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            sethoverBoxId("")
                                                                        }}
                                                                    >


                                                                        <Box sx={{
                                                                            position: 'absolute',
                                                                            top: '90px',
                                                                            left: '0px',
                                                                            right: '0px',
                                                                            margin: 'auto',
                                                                            height: 'fit-content',
                                                                            width: 'fit-content'
                                                                        }}>
                                                                            {row?.platformType === "custom" ?

                                                                                row.icon === "" ? <>
                                                                                    <Box sx={{
                                                                                        height: '100px',
                                                                                        width: '100px',
                                                                                        borderRadius: '50%',
                                                                                        background: '#A1A1AA',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center'
                                                                                    }}>
                                                                                        <Text className={style.H1} sx={{ textAlign: 'center' }} >{(row?.value?.substring(0, 1)?.toUpperCase())}</Text>
                                                                                    </Box>
                                                                                </> :

                                                                                    <>
                                                                                        <img
                                                                                            src={row.icon}
                                                                                            style={{ height: '100px', width: '100px', borderRadius: '50%', objectFit: 'cover' }}
                                                                                        >
                                                                                        </img>
                                                                                    </>


                                                                                :
                                                                                <img
                                                                                    src={iconWithBgColor?.filter((data) => data?.icon === row?.icon)[0]?.icon}
                                                                                    style={{ height: '100px', width: '100px' }}
                                                                                >
                                                                                </img>

                                                                            }

                                                                        </Box>

                                                                        <Box sx={{
                                                                            position: 'absolute',
                                                                            top: '14px',
                                                                            left: '0px',
                                                                            right: '0px',
                                                                            margin: 'auto',
                                                                            width: '100%',
                                                                            height: 'fit-content',
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',
                                                                        }}>
                                                                            <IconButton onClick={() => { openEditplatWidgetDialog(row) }}
                                                                                sx={{ display: hoverBoxId === row.id ? 'flex' : 'none', marginLeft: '21px', background: 'transparent', border: 'none', color:themeName==='dark'?'white':'black',
                                                                                ":hover":{
                                                                                    color:'black'
                                                                                } }} icon={PencilIcon} />
                                                                            <IconButton onClick={() => {
                                                                                handleDeletePlatform(row)
                                                                            }}
                                                                                sx={{ display: hoverBoxId === row.id ? 'flex' : 'none', position: 'absolute', right: '21px', background: 'transparent', border: 'none', color:themeName==='dark'?'white':'black',
                                                                                ":hover":{
                                                                                    color:'black'
                                                                                } }} icon={TrashIcon} />

                                                                        </Box>

                                                                        <Box sx={{
                                                                            position: 'absolute',
                                                                            bottom: '24px',
                                                                            left: '0px',
                                                                            right: '0px',
                                                                            margin: 'auto',
                                                                            width: '230px',
                                                                            height: '36px',
                                                                            background: '#F4F4F5',
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            gap: '15px',

                                                                            ":hover": {
                                                                                background: '#f3f4f6',
                                                                                cursor: 'pointer'
                                                                            }
                                                                        }}
                                                                            onClick={() => {

                                                                                handleOpenLinkNewTab(row)


                                                                            }}

                                                                        >

                                                                            <Text className={style.p3} sx={{ textAlign: 'center', width: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >
                                                                                {row?.label !== "" && row?.label !== null && row?.label !== undefined ? row.label :

                                                                                    row?.platformType !== "custom" ?
                                                                                        "@" + row.value
                                                                                        :
                                                                                        row.value


                                                                                }
                                                                            </Text>
                                                                            <Box >
                                                                                <ArrowUpRightIcon />
                                                                            </Box>

                                                                        </Box>


                                                                    </Box>
                                                                </Box>


                                                            )}
                                                        </Draggable>

                                                    </Box>

                                                )}
                                            </Droppable>



                                    ))}


                                </Box>
                            )}
                        </Droppable>
                    </DragDropContext>

                    {/* ---------- */}
                </Box>


                {/* ---------------------------------- */}

            </Box>



            <PlatFormEditDialog />
            <EditPlatformWidgetDialog />
            <AddProjectWidgetDialog />
            <EditProfileDialog />
            <ActionBar username={userDate?.username} />
        </>)
    }

    return (
        <>

            <Desktop>

                {
                    isMobileView ?
                        DeskTopMobileView("")
                        :
                        DeskTopView("")


                }




            </Desktop>

            <Mobile>
                <MobileView />

            </Mobile>


            {/* ------------- TabLet Design */}

            <TabletPortrait>

                <Box sx={
                    themeName === 'light' ? parentBox : parentBoxDarkTheme

                } >

                    <Box sx={profileBoxTab}

                    >

                        <ActionMenu>
                            <ActionMenu.Anchor>
                                <Box >
                                    <img style={{ width: '40px', height: '40px', borderRadius: '50%', cursor: 'pointer', objectFit: 'cover' }}
                                        src={profileImage !== "" && profileImage !== null ? profileImage : profileIcon}
                                    />
                                </Box>
                            </ActionMenu.Anchor>
                            <ActionMenu.Overlay width="small">
                                <ActionList sx={{ fontFamily: 'Inter' }}>
                                    <ActionList.Item onSelect={() => {

                                    }}>My bio</ActionList.Item>
                                    <ActionList.Item onSelect={() => { }}>Upgrade</ActionList.Item>
                                    <ActionList.Item onSelect={() => {
                                        handleLogout("")
                                    }}>Logout</ActionList.Item>

                                </ActionList>
                            </ActionMenu.Overlay>
                        </ActionMenu>

                    </Box>

                    <Box

                        sx={dragContextBoxTab}


                    >

                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable" >
                                {(provided, snapshot) => (
                                    <Box
                                        sx={dropableBoxTab}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {platform.map((row, index) => (


                                            row?.value === "profile" ?

                                                <Droppable droppableId={"" + row.id + ""} >
                                                    {(provided, snapshot) => (

                                                        <Box
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            <Draggable
                                                                draggableId={"" + row.id + ""}
                                                                index={index}
                                                                key={"" + row.id + ""}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <Box>
                                                                        <Box
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            sx={profileDragBoxTab}
                                                                            onMouseEnter={() => {
                                                                                sethoverBoxId(row.id)
                                                                            }}
                                                                            onMouseLeave={() => {
                                                                                sethoverBoxId("")
                                                                            }}
                                                                        >

                                                                            <Box sx={{
                                                                                position: 'absolute',
                                                                                top: '14px',
                                                                                right: '21px',
                                                                                width: 'fit-content',
                                                                                height: 'fit-content',
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                alignItems: 'center',

                                                                            }}>
                                                                                <IconButton
                                                                                    onClick={() => { openEditProfileDialog("") }}
                                                                                    sx={{ display: hoverBoxId === row.id ? 'flex' : 'none', marginLeft: '21px', background: 'transparent', border: 'none',
                                                                                    color:themeName==='dark'?'white':'black',
                                                                                    ":hover":{
                                                                                        color:'black'
                                                                                    }
                                                                                    }} icon={PencilIcon} />
                                                                            </Box>

                                                                            <Box sx={{
                                                                                marginTop: '50px',
                                                                                marginLeft: '15px',
                                                                                width: '500px',
                                                                                height: '154px',
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                gap: '40px'
                                                                            }}>

                                                                                <Box sx={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column',
                                                                                    alignItems: 'center'
                                                                                }}>
                                                                                    {userDate?.imageUrl === "" ?
                                                                                        <>

                                                                                            <Avatar size={120} src={profileIcon}></Avatar>
                                                                                        </>
                                                                                        :
                                                                                        <>

                                                                                            <Avatar size={120} src={userDate?.imageUrl} sx={{ objectFit: 'cover' }} ></Avatar>

                                                                                        </>
                                                                                    }
                                                                                </Box>
                                                                                <Box sx={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column',
                                                                                    gap: '15px',

                                                                                }}>
                                                                                    <Text className={themeName === "light" ? style.H7 : style.H7dark} sx={{ width: '220px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', minHeight: '24px' }}>{userDate?.name}</Text>
                                                                                    <Text className={themeName === "light" ? style.p4 : style.p4dark} sx={{ width: '220px', overflow: 'hidden', textOverflow: 'ellipsis', height: 'auto' }}>
                                                                                        {userDate?.description}
                                                                                    </Text>
                                                                                </Box>

                                                                            </Box>

                                                                        </Box>
                                                                    </Box>


                                                                )}
                                                            </Draggable>
                                                        </Box>

                                                    )}

                                                </Droppable>




                                                :

                                                <Droppable droppableId={"" + row.id + ""} >
                                                    {(provided, snapshot) => (

                                                        <Box
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            <Draggable
                                                                draggableId={"" + row.id + ""}
                                                                index={index}
                                                                key={"" + row.id + ""}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <Box>
                                                                        <Box
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            sx={{
                                                                                position: 'relative',
                                                                                width: '280px',
                                                                                height: '280px',
                                                                                minWidth: '280px',
                                                                                minHeight: '280px',
                                                                                background: iconWithBgColor?.filter((data) => data?.icon === row?.icon)[0]?.bgColor || 'rgba(161, 161, 170, 0.15)',
                                                                                borderRadius: '3px',
                                                                                border: '1px solid #D0D7DE',
                                                                                ":hover": {
                                                                                    // background: '#F4F4F5'
                                                                                },
                                                                            }}
                                                                            onMouseEnter={() => {
                                                                                sethoverBoxId(row.id)
                                                                            }}
                                                                            onMouseLeave={() => {
                                                                                sethoverBoxId("")
                                                                            }}
                                                                        >


                                                                            <Box sx={{
                                                                                position: 'absolute',
                                                                                top: '90px',
                                                                                left: '90px',
                                                                                margin: 'auto'
                                                                            }}>
                                                                                {row?.platformType === "custom" ?

                                                                                    row.icon === "" ? <>
                                                                                        <Box sx={{
                                                                                            height: '100px',
                                                                                            width: '100px',
                                                                                            borderRadius: '50%',
                                                                                            background: '#A1A1AA',
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            justifyContent: 'center'
                                                                                        }}>
                                                                                            <Text className={style.H1} sx={{ textAlign: 'center' }} >{(row?.value?.substring(0, 1)?.toUpperCase())}</Text>
                                                                                        </Box>
                                                                                    </> :

                                                                                        <>
                                                                                            <img
                                                                                                src={row.icon}
                                                                                                style={{ height: '100px', width: '100px', borderRadius: '50%', objectFit: 'cover' }}
                                                                                            >
                                                                                            </img>
                                                                                        </>


                                                                                    :
                                                                                    <img
                                                                                        src={iconWithBgColor?.filter((data) => data?.icon === row?.icon)[0]?.icon}
                                                                                        style={{ height: '100px', width: '100px' }}
                                                                                    >
                                                                                    </img>

                                                                                }

                                                                            </Box>

                                                                            <Box sx={{
                                                                                position: 'absolute',
                                                                                top: '14px',
                                                                                left: '0px',
                                                                                right: '0px',
                                                                                margin: 'auto',
                                                                                width: '100%',
                                                                                height: 'fit-content',
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                alignItems: 'center',
                                                                            }}>
                                                                                <IconButton onClick={() => { openEditplatWidgetDialog(row) }}
                                                                                    sx={{ display: hoverBoxId === row.id ? 'flex' : 'none', marginLeft: '21px', background: 'transparent', border: 'none', color:themeName==='dark'?'white':'black',
                                                                                    ":hover":{
                                                                                        color:'black'
                                                                                    } }} icon={PencilIcon} />
                                                                                <IconButton onClick={() => {
                                                                                    handleDeletePlatform(row)
                                                                                }}
                                                                                    sx={{ display: hoverBoxId === row.id ? 'flex' : 'none', position: 'absolute', right: '21px', background: 'transparent', border: 'none', color:themeName==='dark'?'white':'black',
                                                                                    ":hover":{
                                                                                        color:'black'
                                                                                    } }} icon={TrashIcon} />

                                                                            </Box>

                                                                            <Box sx={{
                                                                                position: 'absolute',
                                                                                bottom: '24px',
                                                                                left: '0px',
                                                                                right: '0px',
                                                                                margin: 'auto',
                                                                                width: '230px',
                                                                                height: '36px',
                                                                                background: '#F4F4F5',
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                gap: '15px',

                                                                                ":hover": {
                                                                                    background: '#f3f4f6',
                                                                                    cursor: 'pointer'
                                                                                }
                                                                            }}
                                                                                onClick={() => {
                                                                                    row?.platformType === "custom" ?
                                                                                        window.open(row?.link, '_blank')
                                                                                        :
                                                                                        window.open(row.value, '_blank')
                                                                                }}

                                                                            >

                                                                                <Text className={style.p3} sx={{ textAlign: 'center', width: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >
                                                                                    {row?.label !== "" && row?.label !== null && row?.label !== undefined ? row.label :

                                                                                        row?.platformType !== "custom" ?
                                                                                            "@" + row.value
                                                                                            :
                                                                                            row.value

                                                                                    }
                                                                                </Text>
                                                                                <Box >
                                                                                    <ArrowUpRightIcon />
                                                                                </Box>

                                                                            </Box>


                                                                        </Box>
                                                                    </Box>


                                                                )}
                                                            </Draggable>

                                                        </Box>
                                                    )}
                                                </Droppable>



                                        ))}


                                    </Box>
                                )}
                            </Droppable>
                        </DragDropContext>

                        {/* ---------- */}
                    </Box>


                </Box>
                <PlatFormEditDialog />
                <EditPlatformWidgetDialog />
                <AddProjectWidgetDialog />
                <EditProfileDialog />

                <ActionBar username={userDate?.username} />
            </TabletPortrait>

            <TabletLandscape>

                <Box sx={
                    // parentBox
                    themeName === 'light' ? parentBox : parentBoxDarkTheme
                } >

                    <Box sx={profileBoxTab} >

                        <ActionMenu>
                            <ActionMenu.Anchor>
                                <Box >
                                    <img style={{ width: '40px', height: '40px', borderRadius: '50%', cursor: 'pointer', objectFit: 'cover' }}
                                        src={profileImage !== "" && profileImage !== null ? profileImage : profileIcon}
                                    />
                                </Box>
                            </ActionMenu.Anchor>
                            <ActionMenu.Overlay width="small">
                                <ActionList sx={{ fontFamily: 'Inter' }}>
                                    <ActionList.Item onSelect={() => {

                                    }}>My bio</ActionList.Item>
                                    <ActionList.Item onSelect={() => { }}>Upgrade</ActionList.Item>
                                    <ActionList.Item onSelect={() => {
                                        handleLogout("")
                                    }}>Logout</ActionList.Item>

                                </ActionList>
                            </ActionMenu.Overlay>
                        </ActionMenu>

                    </Box>

                    <Box

                        sx={dragContextBoxTab}


                    >

                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable" >
                                {(provided, snapshot) => (
                                    <Box
                                        sx={dropableBoxTab}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {platform.map((row, index) => (


                                            row?.value === "profile" ?

                                                <Droppable droppableId={"" + row.id + ""} >
                                                    {(provided, snapshot) => (

                                                        <Box
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            <Draggable
                                                                draggableId={"" + row.id + ""}
                                                                index={index}
                                                                key={"" + row.id + ""}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <Box>
                                                                        <Box
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            sx={profileDragBoxTab}
                                                                            onMouseEnter={() => {
                                                                                sethoverBoxId(row.id)
                                                                            }}
                                                                            onMouseLeave={() => {
                                                                                sethoverBoxId("")
                                                                            }}
                                                                        >

                                                                            <Box sx={{
                                                                                position: 'absolute',
                                                                                top: '14px',
                                                                                right: '21px',
                                                                                width: 'fit-content',
                                                                                height: 'fit-content',
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                alignItems: 'center',

                                                                            }}>
                                                                                <IconButton
                                                                                    onClick={() => { openEditProfileDialog("") }}
                                                                                    sx={{ display: hoverBoxId === row.id ? 'flex' : 'none', marginLeft: '21px', background: 'transparent', border: 'none',
                                                                                    color:themeName==='dark'?'white':'black',
                                                                                    ":hover":{
                                                                                        color:'black'
                                                                                    }
                                                                                    }}
                                                                                    icon={PencilIcon}
                                                                                />
                                                                            </Box>

                                                                            <Box sx={{
                                                                                marginTop: '50px',
                                                                                marginLeft: '15px',
                                                                                width: '500px',
                                                                                height: '154px',
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                gap: '40px'
                                                                            }}>

                                                                                <Box sx={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column',
                                                                                    alignItems: 'center'
                                                                                }}>
                                                                                    {userDate?.imageUrl === "" ?
                                                                                        <>

                                                                                            <Avatar size={120} src={profileIcon}></Avatar>
                                                                                        </>
                                                                                        :
                                                                                        <>

                                                                                            <Avatar size={120} src={userDate?.imageUrl} sx={{ objectFit: 'cover' }} ></Avatar>

                                                                                        </>
                                                                                    }
                                                                                </Box>
                                                                                <Box sx={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column',
                                                                                    gap: '15px',

                                                                                }}>
                                                                                    <Text className={themeName === "light" ? style.H7 : style.H7dark} sx={{ width: '220px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', minHeight: '24px' }}>{userDate?.name}</Text>
                                                                                    <Text className={themeName === "light" ? style.p4 : style.p4dark} sx={{ width: '220px', overflow: 'hidden', textOverflow: 'ellipsis', height: 'auto' }}>
                                                                                        {userDate?.description}
                                                                                    </Text>
                                                                                </Box>

                                                                            </Box>

                                                                        </Box>
                                                                    </Box>


                                                                )}
                                                            </Draggable>
                                                        </Box>

                                                    )}

                                                </Droppable>




                                                :

                                                <Droppable droppableId={"" + row.id + ""} >
                                                    {(provided, snapshot) => (

                                                        <Box
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            <Draggable
                                                                draggableId={"" + row.id + ""}
                                                                index={index}
                                                                key={"" + row.id + ""}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <Box>
                                                                        <Box
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            sx={{
                                                                                position: 'relative',
                                                                                width: '280px',
                                                                                height: '280px',
                                                                                minWidth: '280px',
                                                                                minHeight: '280px',
                                                                                background: iconWithBgColor?.filter((data) => data?.icon === row?.icon)[0]?.bgColor || 'rgba(161, 161, 170, 0.15)',
                                                                                borderRadius: '3px',
                                                                                border: '1px solid #D0D7DE',
                                                                                ":hover": {
                                                                                    // background: '#F4F4F5'
                                                                                },
                                                                            }}
                                                                            onMouseEnter={() => {
                                                                                sethoverBoxId(row.id)
                                                                            }}
                                                                            onMouseLeave={() => {
                                                                                sethoverBoxId("")
                                                                            }}
                                                                        >


                                                                            <Box sx={{
                                                                                position: 'absolute',
                                                                                top: '90px',
                                                                                left: '90px',
                                                                                margin: 'auto'
                                                                            }}>
                                                                                {row?.platformType === "custom" ?

                                                                                    row.icon === "" ? <>
                                                                                        <Box sx={{
                                                                                            height: '100px',
                                                                                            width: '100px',
                                                                                            borderRadius: '50%',
                                                                                            background: '#A1A1AA',
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            justifyContent: 'center'
                                                                                        }}>
                                                                                            <Text className={style.H1} sx={{ textAlign: 'center' }} >{(row?.value?.substring(0, 1)?.toUpperCase())}</Text>
                                                                                        </Box>
                                                                                    </> :

                                                                                        <>
                                                                                            <img
                                                                                                src={row.icon}
                                                                                                style={{ height: '100px', width: '100px', borderRadius: '50%', objectFit: 'cover' }}
                                                                                            >
                                                                                            </img>
                                                                                        </>


                                                                                    :
                                                                                    <img
                                                                                        src={iconWithBgColor?.filter((data) => data?.icon === row?.icon)[0]?.icon}
                                                                                        style={{ height: '100px', width: '100px' }}
                                                                                    >
                                                                                    </img>

                                                                                }

                                                                            </Box>

                                                                            <Box sx={{
                                                                                position: 'absolute',
                                                                                top: '14px',
                                                                                left: '0px',
                                                                                right: '0px',
                                                                                margin: 'auto',
                                                                                width: '100%',
                                                                                height: 'fit-content',
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                alignItems: 'center',
                                                                            }}>
                                                                                <IconButton onClick={() => { openEditplatWidgetDialog(row) }}
                                                                                    sx={{ display: hoverBoxId === row.id ? 'flex' : 'none', marginLeft: '21px', background: 'transparent', border: 'none', color:themeName==='dark'?'white':'black',
                                                                                    ":hover":{
                                                                                        color:'black'
                                                                                    } }} icon={PencilIcon} />
                                                                                <IconButton onClick={() => {
                                                                                    handleDeletePlatform(row)
                                                                                }}
                                                                                    sx={{ display: hoverBoxId === row.id ? 'flex' : 'none', position: 'absolute', right: '21px', background: 'transparent', border: 'none', color:themeName==='dark'?'white':'black',
                                                                                    ":hover":{
                                                                                        color:'black'
                                                                                    } }} icon={TrashIcon} />

                                                                            </Box>

                                                                            <Box sx={{
                                                                                position: 'absolute',
                                                                                bottom: '24px',
                                                                                left: '0px',
                                                                                right: '0px',
                                                                                margin: 'auto',
                                                                                width: '230px',
                                                                                height: '36px',
                                                                                background: '#F4F4F5',
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                gap: '15px',

                                                                                ":hover": {
                                                                                    background: '#f3f4f6',
                                                                                    cursor: 'pointer'
                                                                                }
                                                                            }}
                                                                                onClick={() => {
                                                                                    row?.platformType === "custom" ?
                                                                                        window.open(row?.link, '_blank')
                                                                                        :
                                                                                        window.open(row.value, '_blank')
                                                                                }}

                                                                            >

                                                                                <Text className={style.p3} sx={{ textAlign: 'center', width: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >
                                                                                    {row?.label !== "" && row?.label !== null && row?.label !== undefined ? row.label :

                                                                                        row?.platformType !== "custom" ?
                                                                                            "@" + row.value
                                                                                            :
                                                                                            row.value

                                                                                    }
                                                                                </Text>
                                                                                <Box >
                                                                                    <ArrowUpRightIcon />
                                                                                </Box>

                                                                            </Box>


                                                                        </Box>
                                                                    </Box>


                                                                )}
                                                            </Draggable>

                                                        </Box>
                                                    )}
                                                </Droppable>



                                        ))}


                                    </Box>
                                )}
                            </Droppable>
                        </DragDropContext>

                        {/* ---------- */}
                    </Box>


                </Box>
                <PlatFormEditDialog />
                <EditPlatformWidgetDialog />
                <AddProjectWidgetDialog />
                <EditProfileDialog />

                <ActionBar username={userDate?.username} />
            </TabletLandscape>


        </>
    )
}

