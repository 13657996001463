import React from "react";
import { Box, Text } from "@primer/react";
import style from './web.module.scss'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import MobileSideNav from "./MobileSideNav";
import posthog from "posthog-js";
posthog.init('phc_mGFMcRORH045UBsthDPi8bGDGMAPCvc7qtACPde72Hh', { api_host: 'https://app.posthog.com' })
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1224 })
    return isDesktop ? children : null
}

const TabletPortrait = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991, orientation: 'portrait' })
    return isTablet ? children : null
}
const TabletLandscape = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 992, maxWidth: 1223, orientation: 'landscape' })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}
const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
}


export default function Privacy() {

    const navigate = useNavigate()

    const headerSyle =
    {
        width: '100vw',
        top: '0',
        left: '0',
        height: '76px',
        zIndex: '999', // Adjust z-index as needed
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: style.pageBg
    }


    const parenBox = {
        position: 'relative',
        width: '100vw',
        minWidth: '100vw',
        maxWidth: '100vw',
        height: '100vh',
        minHeight: '100vh',
        maxHeight: '100vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: style.pageBg
    }

    const privacyPageEvent = (props) => {

        posthog.capture(
            'PrivacyPage',
            {
                distinct_id: 'PrivacyPage',
                timestamp: new Date().toISOString(),
            }
        )

    }

    React.useState(() => {

        privacyPageEvent("")

    })


    return (<>
        <Desktop>
            <Box sx={parenBox}>
                {/* -------------header  */}
                <Box
                    position="fixed"
                    sx={headerSyle}>
                    <Header />
                </Box>

                {/* Section one  */}

                <Box sx={{
                    width: '1280px',
                    minWidth: '1280px',
                    maxWidth: '1280px',
                    minHeight: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '201px',
                    gap: '24px',
                    paddingBottom: '96px'
                }}>
                    <Text className={style.p10}>Privacy Policy</Text>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>

                        <Text className={style.p8}> Your privacy is important to us. It is Superrlink policy to respect your privacy regarding any information we may collect from you across our website, and other sites we own and operate.
                        </Text>
                        <Text className={style.p8}>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we're collecting it and how it will be used.</Text>
                        <Text className={style.p8}>You can sign up with your Google account so your Superrlink account username will be prefilled with your name and your public profile picture</Text>
                        <Text className={style.p8}>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we'll protect within commercially acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification</Text>
                        <Text className={style.p8}>We don't share any personally identifying information publicly or with third-parties, except when required to by law.</Text>
                        <Text className={style.p8}>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</Text>
                        <Text className={style.p8}>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</Text>
                        <Text className={style.p8}>Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</Text>
                        <Text className={style.p8}>This policy is effective as of 21 Dec 2023.</Text>

                    </Box>
                </Box>



                {/* ------------ */}

                {/* Footer */}

                <Box sx={{
                    position: 'relative',
                    height: 'fit-content',
                    minHeight: 'fit-content',
                    width: '100vw',
                    minWidth: '100vw',
                    paddingBottom: '62px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <Footer />

                </Box>

                {/* ------------------------ */}

            </Box>

        </Desktop>

        <Mobile>
            <Box sx={parenBox}>
                {/* -------------header  */}
                <Box position="fixed"
                    sx={{
                        width: '100vw',
                        top: '0',
                        left: '0',
                        height: '76px',
                        zIndex: '999', // Adjust z-index as needed
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: style.pageBg
                    }}>
                    <Header />
                </Box>


                {/* Section one  */}

                <Box sx={{
                    width: '92vw',
                    minWidth: '92vw',
                    maxWidth: '92vw',
                    minHeight: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '201px',
                    gap: '24px',
                    paddingBottom: '96px'
                }}>
                    <Text className={style.p10}>Privacy Policy</Text>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>

                        <Text className={style.p8}> Your privacy is important to us. It is Superrlink policy to respect your privacy regarding any information we may collect from you across our website, and other sites we own and operate.
                        </Text>
                        <Text className={style.p8}>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we're collecting it and how it will be used.</Text>
                        <Text className={style.p8}>You can sign up with your Google account so your Superrlink account username will be prefilled with your name and your public profile picture</Text>
                        <Text className={style.p8}>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we'll protect within commercially acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification</Text>
                        <Text className={style.p8}>We don't share any personally identifying information publicly or with third-parties, except when required to by law.</Text>
                        <Text className={style.p8}>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</Text>
                        <Text className={style.p8}>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</Text>
                        <Text className={style.p8}>Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</Text>
                        <Text className={style.p8}>This policy is effective as of 21 Dec 2023.</Text>

                    </Box>
                </Box>



                {/* ------------ */}
                {/* Footer */}
                <Box sx={{
                    position: 'relative',
                    height: 'fit-content',
                    minHeight: 'fit-content',
                    width: '100vw',
                    minWidth: '100vw',
                    paddingBottom: '72px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <Footer />
                </Box>

            </Box>
            <MobileSideNav />
        </Mobile>
        <TabletPortrait>
            <Box sx={parenBox}>

                {/* -------------header  */}
                <Box position="fixed"
                    sx={{
                        width: '100vw',
                        top: '0',
                        left: '0',
                        height: '76px',
                        zIndex: '999', // Adjust z-index as needed
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: style.pageBg
                    }}>
                    <Header />
                </Box>

                {/* Section one  */}

                <Box sx={{
                    width: '778px',
                    minWidth: '778px',
                    maxWidth: '778px',
                    minHeight: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '201px',
                    gap: '24px',
                    paddingBottom: '96px'
                }}>
                    <Text className={style.p10}>Privacy Policy</Text>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>

                        <Text className={style.p8}> Your privacy is important to us. It is Superrlink policy to respect your privacy regarding any information we may collect from you across our website, and other sites we own and operate.
                        </Text>
                        <Text className={style.p8}>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we're collecting it and how it will be used.</Text>
                        <Text className={style.p8}>You can sign up with your Google account so your Superrlink account username will be prefilled with your name and your public profile picture</Text>
                        <Text className={style.p8}>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we'll protect within commercially acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification</Text>
                        <Text className={style.p8}>We don't share any personally identifying information publicly or with third-parties, except when required to by law.</Text>
                        <Text className={style.p8}>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</Text>
                        <Text className={style.p8}>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</Text>
                        <Text className={style.p8}>Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</Text>
                        <Text className={style.p8}>This policy is effective as of 21 Dec 2023.</Text>

                    </Box>
                </Box>

                {/* Footer */}

                <Box sx={{
                    position: 'relative',
                    height: 'fit-content',
                    minHeight: 'fit-content',
                    width: '100vw',
                    minWidth: '100vw',
                    paddingBottom: '62px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <Footer />
                </Box>

                {/* ------------------------ */}

            </Box>
        </TabletPortrait>

        <TabletLandscape>
            <Box sx={parenBox}>

                {/* -------------header  */}
                <Box position="fixed"
                    sx={{
                        width: '100vw',
                        top: '0',
                        left: '0',
                        height: '76px',
                        zIndex: '999', // Adjust z-index as needed
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: style.pageBg
                    }}>
                    <Header />
                </Box>

                {/* Section one  */}

                <Box sx={{
                    width: '958px',
                    minWidth: '958px',
                    maxWidth: '958px',
                    minHeight: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '201px',
                    gap: '24px',
                    paddingBottom: '96px'
                }}>
                    <Text className={style.p10}>Privacy Policy</Text>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>

                        <Text className={style.p8}> Your privacy is important to us. It is Superrlink policy to respect your privacy regarding any information we may collect from you across our website, and other sites we own and operate.
                        </Text>
                        <Text className={style.p8}>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we're collecting it and how it will be used.</Text>
                        <Text className={style.p8}>You can sign up with your Google account so your Superrlink account username will be prefilled with your name and your public profile picture</Text>
                        <Text className={style.p8}>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we'll protect within commercially acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification</Text>
                        <Text className={style.p8}>We don't share any personally identifying information publicly or with third-parties, except when required to by law.</Text>
                        <Text className={style.p8}>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</Text>
                        <Text className={style.p8}>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</Text>
                        <Text className={style.p8}>Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</Text>
                        <Text className={style.p8}>This policy is effective as of 21 Dec 2023.</Text>

                    </Box>
                </Box>


                {/* Footer */}

                <Box sx={{
                    position: 'relative',
                    height: 'fit-content',
                    minHeight: 'fit-content',
                    width: '100vw',
                    minWidth: '100vw',
                    paddingBottom: '72px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <Footer />
                </Box>

                {/* ------------------------ */}



            </Box>




        </TabletLandscape>


    </>)
}