import React from "react";
import { Box, Button, IconButton, Text } from "@primer/react";
import style from './web.module.scss'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import { ChevronDownIcon, ChevronUpIcon, GiftIcon } from "@primer/octicons-react";
import checkIcon from './assets/checkIcon.svg'
import Footer from "./Footer";
import MobileSideNav from "./MobileSideNav";
import posthog from "posthog-js";
posthog.init('phc_mGFMcRORH045UBsthDPi8bGDGMAPCvc7qtACPde72Hh', { api_host: 'https://app.posthog.com' })
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1224 })
    return isDesktop ? children : null
}

const TabletPortrait = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991, orientation: 'portrait' })
    return isTablet ? children : null
}
const TabletLandscape = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 992, maxWidth: 1223, orientation: 'landscape' })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}
const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
}


export default function Pricing() {

    const navigate = useNavigate()
    const parenBox = {
        position: 'relative',
        width: '100vw',
        minWidth: '100vw',
        maxWidth: '100vw',
        height: '100vh',
        minHeight: '100vh',
        maxHeight: '100vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: style.pageBg
    }

    const headerSyle =
    {
        width: '100vw',
        top: '0',
        left: '0',
        height: '76px',
        zIndex: '999', // Adjust z-index as needed
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: style.pageBg
    }

    const sectiononeStyle = {
        position: 'relative',
        height: '455px',
        minHeight: '455px',
        width: '100vw',
        minWidth: '100vw',
        marginTop: '76px',
        innerBox: {
            position: 'absolute',
            top: '128px',
            left: '0px',
            right: '0px',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            height: 'fit-content',
            width: 'fit-content',
            alignItems: 'center',
            zIndex: '1'
        }
    }

    const backgroundFilterStyle = {
        position: 'absolute',
        top: '300px',
        width: "100%",
        height: "100%",
        filter: "blur(100px) saturate(150%)",
        opacity: ".15",
        background: "radial-gradient(at 27% 37%,#cfe6f0 0,transparent 0),radial-gradient(at 97% 21%,#f845c6 0,transparent 50%),radial-gradient(at 52% 99%,#7957ff 0,transparent 50%),radial-gradient(at 10% 29%,#2466b8 0,transparent 50%),radial-gradient(at 97% 96%,#147787 0,transparent 50%),radial-gradient(at 33% 50%,#18b6aa 0,transparent 50%),radial-gradient(at 79% 53%,#c1cff2 0,transparent 50%)",

    }

    //---------- style mobile ---------------

    const sectiononeStyleMobile = {
        position: 'relative',
        height: '455px',
        minHeight: '455px',
        width: '98vw',
        minWidth: '98vw',
        marginTop: '76px',
        innerBox: {
            position: 'absolute',
            top: '96px',
            left: '0px',
            right: '0px',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            height: 'fit-content',
            width: 'fit-content',
            alignItems: 'center',
            zIndex: '1'
        }
    }

    const backgroundFilterStyleMobile = {
        position: 'absolute',
        top: '180px',
        width: "100%",
        height: "100%",
        filter: "blur(100px) saturate(150%)",
        opacity: ".15",
        background: "radial-gradient(at 27% 37%,#cfe6f0 0,transparent 0),radial-gradient(at 97% 21%,#f845c6 0,transparent 50%),radial-gradient(at 52% 99%,#7957ff 0,transparent 50%),radial-gradient(at 10% 29%,#2466b8 0,transparent 50%),radial-gradient(at 97% 96%,#147787 0,transparent 50%),radial-gradient(at 33% 50%,#18b6aa 0,transparent 50%),radial-gradient(at 79% 53%,#c1cff2 0,transparent 50%)",

    }

    //---------------------------------------


    const [billingCycle, setbillingCycle] = React.useState(true)

    const [faqs, setfaqs] = React.useState([
        { id: 1, question: `What is Superrlink?`, answer: `Superrlink is a application that allows you to show your bio in an incredible way. There are 2 plans designed for both Hobbyist and Pros. The Free plan allows you to present your bio with a limit of Widgets and only one Variant. The Plus plan allows you to present your bio without any limit on Widgets and Variants`, isexpand: false },
        { id: 2, question: `Can I switch between plans?`, answer: `Yes, you can switch between plans at any time. If you switch to a higher plan, the new plan will take effect immediately. If you switch to a lower plan, the changes will take effect at the end of your current billing period.`, isexpand: false },
        { id: 3, question: `Can I cancel my subscription at any time?`, answer: `Yes, you can cancel your subscription at any time. If you cancel your subscription, you will still be able to use Superrlink until the end of your billing period. After that, you will be downgraded to the free plan`, isexpand: false },
        { id: 4, question: `Do you offer refunds?`, answer: `We currently do not offer refunds. However, you can cancel your subscription at any time, after which you won't be charged again. We are constantly working on improving Superrlink, so this might change in the future.`, isexpand: false },
        { id: 5, question: `How can I contact you for support?`, answer: `If you have any problem, do not hesitate to write to us. You can do it via email: superrlinks@gmail.com`, isexpand: false },
    ])

    const handleClickFaqDropDown = (props) => {

          //   setfaqs((prevState)=>)      
               setfaqs((prevState)=>{
                    const newState = prevState.map((row,index)=>{
                         
                             const isExpand = row.isexpand
                             if(row.id===props.id){
                                 
                                 return({...row,isexpand:!isExpand})
                             }else{
                                 return({...row})
                             }
                    })
                    return newState
               })
    }

    const pricingPageEvent = (props) => {

        posthog.capture(
            'PricingPage',
            {
                distinct_id: 'PricingPage',
                timestamp: new Date().toISOString(),
            }
        )

    }

    React.useState(()=>{

          pricingPageEvent("")

    })


    return (
        <>
            <Desktop>
                <Box sx={parenBox}>
                    {/* -------------header  */}
                    <Box position="fixed"
                        sx={headerSyle}>
                        <Header />
                    </Box>

                    {/* Section one */}
                    <Box sx={sectiononeStyle}>

                        <Box sx={sectiononeStyle.innerBox}>
                            <Text className={style.homeHeadingprimary} sx={{ textAlign: 'center' }}>Pricing for</Text>
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', marginTop: '-16px' }}>
                                <Text className={style.homeHeadingsecondary} sx={{ textAlign: 'center', marginTop: '0px' }} >Hobbyists</Text>
                                <Text className={style.homeHeadingprimary} sx={{ textAlign: 'center' }}>and</Text>
                                <Text className={style.homeHeadingsecondary} sx={{ textAlign: 'center', marginTop: '0px' }} >Pros</Text>
                            </Box>

                            <Text className={style.p1} sx={{ textAlign: 'center', marginTop: '13px' }}>Craft your Superrlink bio without breaking your bank.<br></br>

                                Start for free, no credit card required.</Text>

                        </Box>

 
                    </Box>

                    {/* Background Filter */}
                    <Box style={backgroundFilterStyle}></Box>
                    {/*  */}

                    {/*  section two   */}

                    <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '44px',
                       
                    }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center' }}>
                            <Text className={style.p5}>Billed Monthly</Text>
                            <Box>
                                <label className={style.switch}>
                                    <input type="checkbox" checked={billingCycle} onChange={(e) => setbillingCycle(e.target.checked)} />
                                    <span className={`${style.slider + " " + style.round}`}></span>
                                </label>
                            </Box>
                            <Text className={style.p5}>Billed Yearly</Text>
                            {/* <Button
                                className={style.btnStylePricing}
                                variant="invisible"
                                sx={{
                                    minHeight: '32px',
                                    maxHeight: '32px',
                                    height: '32px',
                                    paddingLeft: '8px',
                                    paddingRight: '8px',
                                    background: style.btnbackgroundColor,
                                    color: style.whitecolor,
                                    borderRadius: '9999px',
                                    fontSize: '10px',
                                    '&:hover': {
                                        background: style.blackColor,
                                        backgroundColor: style.blackColor
                                    }
                                }}

                            >
                                <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}><GiftIcon></GiftIcon>2 months FREE</Box>
                            </Button> */}
                        </Box>


                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '32px',
                            width: 'fit-content',
                            height: 'fit-content',

                        }}>

                            <Box sx={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '320px',
                                minWidth: '320px',
                                maxWidth: '320px',
                                height: '773px',
                                minHeight: '773px',
                                maxHeight: '773px',
                                border: `1px solid ${style.borderColor}`,
                                borderRadius: '24px',
                                background: style.pricingBoxBg,
                                padding: '33px 33px 33px 33px',
                                gap: '24px'
                            }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Text className={style.H2}>Free
                                    </Text>
                                    <Text className={style.p6} sx={{ margin: 'auto 0px auto auto', color: style.pricingLabelColor }}>
                                        {/* Your Plan */}
                                        7 days
                                    </Text>
                                </Box>

                                <Text className={style.p7}> The essentials to start creating your Superrlink bio.</Text>

                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>

                                    <Text className={style.H4}>$0</Text><Text className={style.p8}>/month</Text>
                                </Box>

                                <Button
                                    className={style.btnStylePricingTransparent}
                                    variant="invisible"
                                    sx={{
                                        minWidth: '254px',
                                        maxWidth: '254px',
                                        width: '254px',
                                        minHeight: '58px',
                                        maxHeight: '58px',
                                        height: '58px',
                                        color: style.blackColor,
                                        border: `1px solid ${style.blackColor}`,
                                        borderRadius: '9999px',
                                        '&:hover': {
                                            background: style.btnbackgroundColor,
                                            backgroundColor: style.btnbackgroundColor,
                                            color: style.whitecolor,
                                        }
                                    }}
                                    onClick={() => {

                                    }}
                                >Get started</Button>

                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>1 Profile Widget</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>6 Platform Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>2 Images Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>2 Projects Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Minimalism Variant

                                        </Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Light Theme </Text>
                                    </Box>
                                </Box>


                            </Box>

                            <Box sx={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '320px',
                                minWidth: '320px',
                                maxWidth: '320px',
                                height: '773px',
                                minHeight: '773px',
                                maxHeight: '773px',
                                border: `1px solid ${style.pricingLabelColor}`,
                                borderRadius: '24px',
                                background: style.pricingBoxBg,
                                padding: '33px 33px 33px 33px',
                                gap: '24px'
                            }}>

                                <Box
                                    className={style.btnStylePricingTransparent}
                                    sx={{
                                        position: 'absolute',
                                        top:'-18px',
                                        left: '0px',
                                        right: '0px',
                                        margin: 'auto',
                                        minWidth: '128px',
                                        maxWidth: '128px',
                                        width: '128px',
                                        minHeight: '36px',
                                        maxHeight: '36px',
                                        height: '36px',
                                        background: style.pricingLabelColor,
                                        color: style.whitecolor,
                                        borderRadius: '9999px',
                                        display:'flex',
                                        alignItems:'center',
                                        justifyContent:'center'
                                    }}

                                >Popular</Box>

                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Text className={style.H2}>Plus</Text>
                                   
                                </Box>

                                <Text className={style.p7}> Create your Superrlink bio brilliantly with this plan.
                                </Text>

                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>

                                    <Text className={style.H4}>$30</Text><Text className={style.p8}>/month</Text>
                                </Box>

                                <Button
                                    className={style.btnStylePricingTransparent}
                                    variant="invisible"
                                    sx={{
                                        minWidth: '254px',
                                        maxWidth: '254px',
                                        width: '254px',
                                        minHeight: '58px',
                                        maxHeight: '58px',
                                        height: '58px',
                                        background: style.btnbackgroundColor,
                                        color: style.whitecolor,
                                        border: `1px solid ${style.blackColor}`,
                                        borderRadius: '9999px',
                                        '&:hover': {
                                            background: style.btnbackgroundColor,
                                            backgroundColor: style.btnbackgroundColor,
                                            color: style.whitecolor,
                                        }
                                    }}
                                    onClick={() => {

                                    }}
                                >Get started</Button>

                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>1 Profile Widget</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Unlimited Platform Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Unlimited Images Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Unlimited Projects Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Minimalism Variant</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Neubrutalism Variant</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Glassmorphism Variant</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Light & Dark Theme</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Verified Badge</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>New Variants (coming soon)</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Analytics (coming soon)

                                        </Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Exclusive Widgets (coming soon)</Text>
                                    </Box>

                                </Box>


                            </Box>

                        </Box>


                    </Box>

                    {/* section three (FAQ) */}
                    <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems:'center',
                        paddingTop:'96px',
                        paddingBottom:'36px'     
                    }}>

                        <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'row',
                            width: '1280px', 
                            minWidth: '1280px', 
                            maxWidth: '1280px', 
                            gap: '202px', 
                           
                            }}>

                            <Text className={style.H3}>Pricing FAQs</Text>
                            <Box sx={{ display: 'flex', flexDirection: 'column', padding:'20px 28px 20px 28px',width:'837px',minWidth:'837px',maxWidth:'837px',gap:'0px' }}>
                                     
                                {faqs.map((row, index) => {
                                    return (
                                        <>
                                             <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <Text className={style.p9}>{row.question}</Text>
                                                    <IconButton sx={{ border: 'none', margin: '0px 0px 0px auto' }} icon={row.isexpand ? ChevronUpIcon :ChevronDownIcon}
                                                     onClick={()=>{
                                                            handleClickFaqDropDown(row)  
                                                     }}
                                                    />
                                                </Box>
                                                <Box  className={style.faqCollanpse} 
                                                 sx={{ height: row.isexpand ? "70px" : "0px"}}
                                                 
                                                 >
                                                    <Text className={style.p7} >
                                                        {row.answer}
                                                    </Text>
                                                </Box>
                                               

                                            </Box>
                                        </>
                                    )
                                })}

                                
                            </Box>

                        </Box>

                       

                    </Box>



                    {/* -------------------- */}

                    {/* Footer */}

                    <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        paddingBottom: '32px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Footer />
                        
                    </Box>

                    {/* ------------------------ */}


                </Box>

            </Desktop>


            <Mobile>
                <Box sx={parenBox}>
                    {/* -------------header  */}
                    <Box position="fixed"
                        sx={{
                            width: '100vw',
                            top: '0',
                            left: '0',
                            height: '76px',
                            zIndex: '999', // Adjust z-index as needed
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: style.pageBg
                        }}>
                        <Header />
                    </Box>

                    {/* Section one */}
                    <Box sx={sectiononeStyleMobile}>

                        <Box sx={sectiononeStyleMobile.innerBox}>
                            <Text className={style.homeHeadingprimaryMobile} sx={{ textAlign: 'center',fontSize:'3rem' }}>Pricing for</Text>
                            <Text className={style.homeHeadingsecondaryMobile} sx={{ textAlign: 'center', marginTop: '0px',fontSize:'3rem' }} >Hobbyists</Text> 
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', marginTop: '0px' }}>
                               
                                <Text className={style.homeHeadingprimaryMobile} sx={{ textAlign: 'center',fontSize:'3rem' }}>and</Text>
                                <Text className={style.homeHeadingsecondaryMobile} sx={{ textAlign: 'center', marginTop: '0px',fontSize:'3rem' }} >Pros</Text>
                            </Box>

                            <Text className={style.p1} 
                            sx={{ textAlign: 'center', marginTop: '13px',maxWidth:'340px',fontSize:'16px' }}>
                                Craft your Superrlink bio without breaking your<br></br> bank.Start for free, no credit card required.</Text>

                        </Box>
                     </Box>

                    {/* Background Filter */}
                    <Box style={backgroundFilterStyleMobile}></Box>
                    {/*  */}

                    {/*  section two   */}

                    <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '44px',

                    }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'center' }}>
                            <Text className={style.p5}>Billed Monthly</Text>
                            <Box>
                                <label className={style.switch}>
                                    <input type="checkbox" checked={billingCycle} onChange={(e) => setbillingCycle(e.target.checked)} />
                                    <span className={`${style.slider + " " + style.round}`}></span>
                                </label>
                            </Box>
                            <Text className={style.p5}>Billed Yearly</Text>
                            {/* <Button
                                className={style.btnStylePricing}
                                variant="invisible"
                                sx={{
                                    minHeight: '32px',
                                    maxHeight: '32px',
                                    height: '32px',
                                    paddingLeft: '8px',
                                    paddingRight: '8px',
                                    background: style.btnbackgroundColor,
                                    color: style.whitecolor,
                                    borderRadius: '9999px',
                                    fontSize: '10px',
                                    '&:hover': {
                                        background: style.blackColor,
                                        backgroundColor: style.blackColor
                                    }
                                }}

                            >
                                <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}><GiftIcon></GiftIcon>2 months FREE</Box>
                            </Button> */}
                        </Box>


                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '32px',
                            width: 'fit-content',
                            height: 'fit-content',

                        }}>

                            <Box sx={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '320px',
                                minWidth: '320px',
                                maxWidth: '320px',
                                height: '773px',
                                minHeight: '773px',
                                maxHeight: '773px',
                                border: `1px solid ${style.borderColor}`,
                                borderRadius: '24px',
                                background: style.pricingBoxBg,
                                padding: '33px 33px 33px 33px',
                                gap: '24px'
                            }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Text className={style.H2}>Free
                                    </Text>
                                    <Text className={style.p6} sx={{ margin: 'auto 0px auto auto', color: style.pricingLabelColor }}>
                                         {/* Your Plan */}
                                         7 days
                                    </Text>
                                </Box>

                                <Text className={style.p7}> The essentials to start creating your Superrlink bio.</Text>

                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>

                                    <Text className={style.H4}>$0</Text><Text className={style.p8}>/month</Text>
                                </Box>

                                <Button
                                    className={style.btnStylePricingTransparent}
                                    variant="invisible"
                                    sx={{
                                        minWidth: '254px',
                                        maxWidth: '254px',
                                        width: '254px',
                                        minHeight: '58px',
                                        maxHeight: '58px',
                                        height: '58px',
                                        color: style.blackColor,
                                        border: `1px solid ${style.blackColor}`,
                                        borderRadius: '9999px',
                                        '&:hover': {
                                            background: style.btnbackgroundColor,
                                            backgroundColor: style.btnbackgroundColor,
                                            color: style.whitecolor,
                                        }
                                    }}
                                    onClick={() => {

                                    }}
                                >Get started</Button>

                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>1 Profile Widget</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>6 Platform Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>2 Images Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>2 Projects Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Minimalism Variant

                                        </Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Light Theme </Text>
                                    </Box>
                                </Box>


                            </Box>

                            <Box sx={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '320px',
                                minWidth: '320px',
                                maxWidth: '320px',
                                height: '773px',
                                minHeight: '773px',
                                maxHeight: '773px',
                                border: `1px solid ${style.pricingLabelColor}`,
                                borderRadius: '24px',
                                background: style.pricingBoxBg,
                                padding: '33px 33px 33px 33px',
                                gap: '24px'
                            }}>

                                <Box
                                    className={style.btnStylePricingTransparent}
                                    sx={{
                                        position: 'absolute',
                                        top: '-18px',
                                        left: '0px',
                                        right: '0px',
                                        margin: 'auto',
                                        minWidth: '128px',
                                        maxWidth: '128px',
                                        width: '128px',
                                        minHeight: '36px',
                                        maxHeight: '36px',
                                        height: '36px',
                                        background: style.pricingLabelColor,
                                        color: style.whitecolor,
                                        borderRadius: '9999px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}

                                >Popular</Box>

                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Text className={style.H2}>Plus</Text>

                                </Box>

                                <Text className={style.p7}> Create your Superrlink bio brilliantly with this plan.
                                </Text>

                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>

                                    <Text className={style.H4}>$30</Text><Text className={style.p8}>/month</Text>
                                </Box>

                                <Button
                                    className={style.btnStylePricingTransparent}
                                    variant="invisible"
                                    sx={{
                                        minWidth: '254px',
                                        maxWidth: '254px',
                                        width: '254px',
                                        minHeight: '58px',
                                        maxHeight: '58px',
                                        height: '58px',
                                        background: style.btnbackgroundColor,
                                        color: style.whitecolor,
                                        border: `1px solid ${style.blackColor}`,
                                        borderRadius: '9999px',
                                        '&:hover': {
                                            background: style.btnbackgroundColor,
                                            backgroundColor: style.btnbackgroundColor,
                                            color: style.whitecolor,
                                        }
                                    }}
                                    onClick={() => {

                                    }}
                                >Get started</Button>

                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>1 Profile Widget</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Unlimited Platform Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Unlimited Images Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Unlimited Projects Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Minimalism Variant</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Neubrutalism Variant</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Glassmorphism Variant</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Light & Dark Theme</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Verified Badge</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>New Variants (coming soon)</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Analytics (coming soon)

                                        </Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Exclusive Widgets (coming soon)</Text>
                                    </Box>

                                </Box>


                            </Box>

                        </Box>


                    </Box>
                    

                  {/* section three (FAQ) */}
                  <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems:'center',
                        paddingTop:'56px',
                        paddingBottom:'56px',
                              
                    }}>

                        <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'column',
                            width: '98vw', 
                            minWidth: '98vw', 
                            maxWidth: '98vw', 
                            gap: '30px', 
                          
                            }}>

                            <Text className={style.H3}>Pricing FAQs</Text>
                            <Box sx={{ 
                                display: 'flex', 
                                flexDirection: 'column', 
                                padding:'20px 28px 20px 28px',
                                width:'98vw',
                                minWidth:'98vw',
                                maxWidth:'98vw',
                                gap:'0px',
                               
                                
                               }}
                                
                                >
                                     
                                {faqs.map((row, index) => {
                                    return (
                                        <>
                                             <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <Text className={style.p9}>{row.question}</Text>
                                                    <IconButton sx={{ border: 'none', margin: '0px 0px 0px auto' }} icon={row.isexpand ? ChevronUpIcon :ChevronDownIcon}
                                                     onClick={()=>{
                                                            handleClickFaqDropDown(row)  
                                                     }}
                                                    />
                                                </Box>
                                                <Box  className={style.faqCollanpse} 
                                                 sx={{ height: row.isexpand ? "160px" : "0px"}}
                                                 
                                                 >
                                                    <Text className={style.p7} >
                                                        {row.answer}
                                                    </Text>
                                                </Box>
                                               

                                            </Box>
                                        </>
                                    )
                                })}

                                
                            </Box>

                        </Box>

                       

                    </Box>

                    {/* Footer */}
                    <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        paddingBottom: '72px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Footer />
                    </Box>

                    {/* ------------------------ */}

                </Box>
     
                 <MobileSideNav/>

            </Mobile>


            <TabletPortrait>

                   <Box sx={parenBox}>

                    {/* -------------header  */}
                    <Box position="fixed"
                        sx={{
                            width: '100vw',
                            top: '0',
                            left: '0',
                            height: '76px',
                            zIndex: '999', // Adjust z-index as needed
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: style.pageBg
                        }}>
                        <Header />
                    </Box>

                    {/* Section one */}
                    <Box sx={sectiononeStyle}>

                        <Box sx={sectiononeStyle.innerBox}>
                            <Text className={style.homeHeadingprimary} sx={{ textAlign: 'center' }}>Pricing for</Text>
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', marginTop: '-16px' }}>
                                <Text className={style.homeHeadingsecondary} sx={{ textAlign: 'center', marginTop: '0px' }} >Hobbyists</Text>
                                <Text className={style.homeHeadingprimary} sx={{ textAlign: 'center' }}>and</Text>
                                <Text className={style.homeHeadingsecondary} sx={{ textAlign: 'center', marginTop: '0px' }} >Pros</Text>
                            </Box>

                            <Text className={style.p1} sx={{ textAlign: 'center', marginTop: '13px' }}>Craft your Superrlink bio without breaking your bank.<br></br>

                                Start for free, no credit card required.</Text>

                        </Box>


                    </Box>
                   
                    {/* Background Filter */}
                    <Box style={backgroundFilterStyle}></Box>
                    {/*  */}


                    {/*  section two   */}

                    <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '44px',

                    }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center' }}>
                            <Text className={style.p5}>Billed Monthly</Text>
                            <Box>
                                <label className={style.switch}>
                                    <input type="checkbox" checked={billingCycle} onChange={(e) => setbillingCycle(e.target.checked)} />
                                    <span className={`${style.slider + " " + style.round}`}></span>
                                </label>
                            </Box>
                            <Text className={style.p5}>Billed Yearly</Text>
                            {/* <Button
                                className={style.btnStylePricing}
                                variant="invisible"
                                sx={{
                                    minHeight: '32px',
                                    maxHeight: '32px',
                                    height: '32px',
                                    paddingLeft: '8px',
                                    paddingRight: '8px',
                                    background: style.btnbackgroundColor,
                                    color: style.whitecolor,
                                    borderRadius: '9999px',
                                    fontSize: '10px',
                                    '&:hover': {
                                        background: style.blackColor,
                                        backgroundColor: style.blackColor
                                    }
                                }}

                            >
                                <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}><GiftIcon></GiftIcon>2 months FREE</Box>
                            </Button> */}
                        </Box>


                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '32px',
                            width: 'fit-content',
                            height: 'fit-content',

                        }}>

                            <Box sx={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '320px',
                                minWidth: '320px',
                                maxWidth: '320px',
                                height: '773px',
                                minHeight: '773px',
                                maxHeight: '773px',
                                border: `1px solid ${style.borderColor}`,
                                borderRadius: '24px',
                                background: style.pricingBoxBg,
                                padding: '33px 33px 33px 33px',
                                gap: '24px'
                            }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Text className={style.H2}>Free
                                    </Text>
                                    <Text className={style.p6} sx={{ margin: 'auto 0px auto auto', color: style.pricingLabelColor }}>
                                         {/* Your Plan */}
                                         7 days
                                    </Text>
                                </Box>

                                <Text className={style.p7}> The essentials to start creating your Superrlink bio.</Text>

                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>

                                    <Text className={style.H4}>$0</Text><Text className={style.p8}>/month</Text>
                                </Box>

                                <Button
                                    className={style.btnStylePricingTransparent}
                                    variant="invisible"
                                    sx={{
                                        minWidth: '254px',
                                        maxWidth: '254px',
                                        width: '254px',
                                        minHeight: '58px',
                                        maxHeight: '58px',
                                        height: '58px',
                                        color: style.blackColor,
                                        border: `1px solid ${style.blackColor}`,
                                        borderRadius: '9999px',
                                        '&:hover': {
                                            background: style.btnbackgroundColor,
                                            backgroundColor: style.btnbackgroundColor,
                                            color: style.whitecolor,
                                        }
                                    }}
                                    onClick={() => {

                                    }}
                                >Get started</Button>

                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>1 Profile Widget</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>6 Platform Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>2 Images Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>2 Projects Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Minimalism Variant

                                        </Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Light Theme </Text>
                                    </Box>
                                </Box>


                            </Box>

                            <Box sx={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '320px',
                                minWidth: '320px',
                                maxWidth: '320px',
                                height: '773px',
                                minHeight: '773px',
                                maxHeight: '773px',
                                border: `1px solid ${style.pricingLabelColor}`,
                                borderRadius: '24px',
                                background: style.pricingBoxBg,
                                padding: '33px 33px 33px 33px',
                                gap: '24px'
                            }}>

                                <Box
                                    className={style.btnStylePricingTransparent}
                                    sx={{
                                        position: 'absolute',
                                        top: '-18px',
                                        left: '0px',
                                        right: '0px',
                                        margin: 'auto',
                                        minWidth: '128px',
                                        maxWidth: '128px',
                                        width: '128px',
                                        minHeight: '36px',
                                        maxHeight: '36px',
                                        height: '36px',
                                        background: style.pricingLabelColor,
                                        color: style.whitecolor,
                                        borderRadius: '9999px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}

                                >Popular</Box>

                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Text className={style.H2}>Plus</Text>

                                </Box>

                                <Text className={style.p7}> Create your Superrlink bio brilliantly with this plan.
                                </Text>

                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>

                                    <Text className={style.H4}>$30</Text><Text className={style.p8}>/month</Text>
                                </Box>

                                <Button
                                    className={style.btnStylePricingTransparent}
                                    variant="invisible"
                                    sx={{
                                        minWidth: '254px',
                                        maxWidth: '254px',
                                        width: '254px',
                                        minHeight: '58px',
                                        maxHeight: '58px',
                                        height: '58px',
                                        background: style.btnbackgroundColor,
                                        color: style.whitecolor,
                                        border: `1px solid ${style.blackColor}`,
                                        borderRadius: '9999px',
                                        '&:hover': {
                                            background: style.btnbackgroundColor,
                                            backgroundColor: style.btnbackgroundColor,
                                            color: style.whitecolor,
                                        }
                                    }}
                                    onClick={() => {

                                    }}
                                >Get started</Button>

                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>1 Profile Widget</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Unlimited Platform Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Unlimited Images Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Unlimited Projects Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Minimalism Variant</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Neubrutalism Variant</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Glassmorphism Variant</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Light & Dark Theme</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Verified Badge</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>New Variants (coming soon)</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Analytics (coming soon)

                                        </Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Exclusive Widgets (coming soon)</Text>
                                    </Box>

                                </Box>


                            </Box>

                        </Box>


                    </Box>

                       {/* section three (FAQ) */}
                       <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems:'center',
                        paddingTop:'96px',
                        paddingBottom:'36px'     
                    }}>

                        <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'row',
                            width: '750px', 
                            minWidth: '750px', 
                            maxWidth: '750px', 
                            gap: '32px', 
                           
                            }}>

                            <Text className={style.H3}>Pricing FAQs</Text>
                            <Box sx={{ 
                                display: 'flex', 
                                flexDirection: 'column', 
                                padding:'20px 28px 20px 28px',
                                width:'495px',
                                minWidth:'495px',
                                maxWidth:'495px',
                                gap:'0px',
                                
                                }}>
                                     
                                {faqs.map((row, index) => {
                                    return (
                                        <>
                                             <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <Text className={style.p9}>{row.question}</Text>
                                                    <IconButton sx={{ border: 'none', margin: '0px 0px 0px auto' }} icon={row.isexpand ? ChevronUpIcon :ChevronDownIcon}
                                                     onClick={()=>{
                                                            handleClickFaqDropDown(row)  
                                                     }}
                                                    />
                                                </Box>
                                                <Box  className={style.faqCollanpse} 
                                                 sx={{ height: row.isexpand ? "120px" : "0px"}}>
                                                    <Text className={style.p7} >
                                                        {row.answer}
                                                    </Text>
                                                </Box>
                                               

                                            </Box>
                                        </>
                                    )
                                })}

                                
                            </Box>

                        </Box>

                       

                    </Box>





                    {/* Footer */}

                    <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        paddingBottom: '62px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Footer />
                    </Box>

                    {/* ------------------------ */}


                </Box>
              </TabletPortrait>


            <TabletLandscape>
                <Box sx={parenBox}>

                    {/* -------------header  */}
                    <Box position="fixed"
                        sx={{
                            width: '100vw',
                            top: '0',
                            left: '0',
                            height: '76px',
                            zIndex: '999', // Adjust z-index as needed
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: style.pageBg
                        }}>
                        <Header />
                    </Box>


                    {/* Section one */}
                    <Box sx={sectiononeStyle}>

                        <Box sx={sectiononeStyle.innerBox}>
                            <Text className={style.homeHeadingprimary} sx={{ textAlign: 'center' }}>Pricing for</Text>
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', marginTop: '-16px' }}>
                                <Text className={style.homeHeadingsecondary} sx={{ textAlign: 'center', marginTop: '0px' }} >Hobbyists</Text>
                                <Text className={style.homeHeadingprimary} sx={{ textAlign: 'center' }}>and</Text>
                                <Text className={style.homeHeadingsecondary} sx={{ textAlign: 'center', marginTop: '0px' }} >Pros</Text>
                            </Box>

                            <Text className={style.p1} sx={{ textAlign: 'center', marginTop: '13px' }}>Craft your Superrlink bio without breaking your bank.<br></br>

                                Start for free, no credit card required.</Text>

                        </Box>


                    </Box>

                    {/* Background Filter */}
                    <Box style={backgroundFilterStyle}></Box>
                    {/*  */}


                    {/*  section two   */}

                    <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '44px',

                    }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center' }}>
                            <Text className={style.p5}>Billed Monthly</Text>
                            <Box>
                                <label className={style.switch}>
                                    <input type="checkbox" checked={billingCycle} onChange={(e) => setbillingCycle(e.target.checked)} />
                                    <span className={`${style.slider + " " + style.round}`}></span>
                                </label>
                            </Box>
                            <Text className={style.p5}>Billed Yearly</Text>
                            {/* <Button
                                className={style.btnStylePricing}
                                variant="invisible"
                                sx={{
                                    minHeight: '32px',
                                    maxHeight: '32px',
                                    height: '32px',
                                    paddingLeft: '8px',
                                    paddingRight: '8px',
                                    background: style.btnbackgroundColor,
                                    color: style.whitecolor,
                                    borderRadius: '9999px',
                                    fontSize: '10px',
                                    '&:hover': {
                                        background: style.blackColor,
                                        backgroundColor: style.blackColor
                                    }
                                }}

                            >
                                <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}><GiftIcon></GiftIcon>2 months FREE</Box>
                            </Button> */}
                        </Box>


                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '32px',
                            width: 'fit-content',
                            height: 'fit-content',

                        }}>

                            <Box sx={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '320px',
                                minWidth: '320px',
                                maxWidth: '320px',
                                height: '773px',
                                minHeight: '773px',
                                maxHeight: '773px',
                                border: `1px solid ${style.borderColor}`,
                                borderRadius: '24px',
                                background: style.pricingBoxBg,
                                padding: '33px 33px 33px 33px',
                                gap: '24px'
                            }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Text className={style.H2}>Free
                                    </Text>
                                    <Text className={style.p6} sx={{ margin: 'auto 0px auto auto', color: style.pricingLabelColor }}>
                                        {/* Your Plan */}
                                        7 days
                                    </Text>
                                </Box>

                                <Text className={style.p7}> The essentials to start creating your Superrlink bio.</Text>

                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>

                                    <Text className={style.H4}>$0</Text><Text className={style.p8}>/month</Text>
                                </Box>

                                <Button
                                    className={style.btnStylePricingTransparent}
                                    variant="invisible"
                                    sx={{
                                        minWidth: '254px',
                                        maxWidth: '254px',
                                        width: '254px',
                                        minHeight: '58px',
                                        maxHeight: '58px',
                                        height: '58px',
                                        color: style.blackColor,
                                        border: `1px solid ${style.blackColor}`,
                                        borderRadius: '9999px',
                                        '&:hover': {
                                            background: style.btnbackgroundColor,
                                            backgroundColor: style.btnbackgroundColor,
                                            color: style.whitecolor,
                                        }
                                    }}
                                    onClick={() => {

                                    }}
                                >Get started</Button>

                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>1 Profile Widget</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>6 Platform Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>2 Images Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>2 Projects Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Minimalism Variant

                                        </Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Light Theme </Text>
                                    </Box>
                                </Box>


                            </Box>

                            <Box sx={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '320px',
                                minWidth: '320px',
                                maxWidth: '320px',
                                height: '773px',
                                minHeight: '773px',
                                maxHeight: '773px',
                                border: `1px solid ${style.pricingLabelColor}`,
                                borderRadius: '24px',
                                background: style.pricingBoxBg,
                                padding: '33px 33px 33px 33px',
                                gap: '24px'
                            }}>

                                <Box
                                    className={style.btnStylePricingTransparent}
                                    sx={{
                                        position: 'absolute',
                                        top: '-18px',
                                        left: '0px',
                                        right: '0px',
                                        margin: 'auto',
                                        minWidth: '128px',
                                        maxWidth: '128px',
                                        width: '128px',
                                        minHeight: '36px',
                                        maxHeight: '36px',
                                        height: '36px',
                                        background: style.pricingLabelColor,
                                        color: style.whitecolor,
                                        borderRadius: '9999px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}

                                >Popular</Box>

                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Text className={style.H2}>Plus</Text>

                                </Box>

                                <Text className={style.p7}> Create your Superrlink bio brilliantly with this plan.
                                </Text>

                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                                   <Text className={style.H4}>$30</Text><Text className={style.p8}>/month</Text>
                                </Box>

                                <Button
                                    className={style.btnStylePricingTransparent}
                                    variant="invisible"
                                    sx={{
                                        minWidth: '254px',
                                        maxWidth: '254px',
                                        width: '254px',
                                        minHeight: '58px',
                                        maxHeight: '58px',
                                        height: '58px',
                                        background: style.btnbackgroundColor,
                                        color: style.whitecolor,
                                        border: `1px solid ${style.blackColor}`,
                                        borderRadius: '9999px',
                                        '&:hover': {
                                            background: style.btnbackgroundColor,
                                            backgroundColor: style.btnbackgroundColor,
                                            color: style.whitecolor,
                                        }
                                    }}
                                    onClick={() => {

                                    }}
                                >Get started</Button>

                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>1 Profile Widget</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Unlimited Platform Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Unlimited Images Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Unlimited Projects Widgets</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Minimalism Variant</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Neubrutalism Variant</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Glassmorphism Variant</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Light & Dark Theme</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Verified Badge</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>New Variants (coming soon)</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Analytics (coming soon)

                                        </Text>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>

                                        <img src={checkIcon} height={16} width={16} />
                                        <Text className={style.p7}>Exclusive Widgets (coming soon)</Text>
                                    </Box>

                                </Box>


                            </Box>

                        </Box>


                    </Box>

                    {/* section three (FAQ) */}
                    <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        paddingTop: '96px',
                        paddingBottom: '36px'
                    }}>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '950px',
                            minWidth: '950px',
                            maxWidth: '950px',
                            gap: '52px',
                           

                        }}>

                            <Text className={style.H3}>Pricing FAQs</Text>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '20px 28px 20px 28px',
                                width: '645px',
                                minWidth: '645px',
                                maxWidth: '645px',
                                gap: '0px',
                                
                            }}>

                                {faqs.map((row, index) => {
                                    return (
                                        <>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <Text className={style.p9}>{row.question}</Text>
                                                    <IconButton sx={{ border: 'none', margin: '0px 0px 0px auto' }} icon={row.isexpand ? ChevronUpIcon : ChevronDownIcon}
                                                        onClick={() => {
                                                            handleClickFaqDropDown(row)
                                                        }}
                                                    />
                                                </Box>
                                                <Box className={style.faqCollanpse}
                                                    sx={{ height: row.isexpand ? "120px" : "0px" }}>
                                                    <Text className={style.p7} >
                                                        {row.answer}
                                                    </Text>
                                                </Box>


                                            </Box>
                                        </>
                                    )
                                })}


                            </Box>

                        </Box>



                    </Box>





                    {/* Footer */}

                    <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        paddingBottom: '72px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Footer />
                    </Box>

                    {/* ------------------------ */}
 
                </Box>

            </TabletLandscape>
 

        </>
    )
}