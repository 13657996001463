
import { Box, Text, Avatar } from "@primer/react";
import style from '../global.module.scss'
import { useMediaQuery } from 'react-responsive'
import profileIcon from '../assets/profile.svg'
import React from "react";
import { db } from "../firebase";
import { collection, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { ArrowUpRightIcon } from "@primer/octicons-react";
import platformUrl from "./objects/platformUrl";
import platformBg from "./objects/plaformBg";
import mixpanel from 'mixpanel-browser';
import posthog from "posthog-js";
mixpanel.init('7a2c125cf3bf07b7fef81e91d0190b14');
posthog.init('phc_mGFMcRORH045UBsthDPi8bGDGMAPCvc7qtACPde72Hh', { api_host: 'https://app.posthog.com' })

const iconWithBgColor = platformBg
const platformIdUrlArray = platformUrl

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1224 })
    return isDesktop ? children : null
}
const TabletPortrait = ({ children }) => {

    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991, orientation: 'portrait' })
    return isTablet ? children : null
}
const TabletLandscape = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 992, maxWidth: 1223, orientation: 'landscape' })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}
const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
}

export default function OpenDashboard(props) {

    const { username } = useParams();
    const [platform, setplatforms] = React.useState([])
    const [userDate, setuserDate] = React.useState(null)
    const [themeName, setthemeName] = React.useState("light")

    //------------------- javascript theme css --------------------------
    const parentBox = {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        minWidth: '100vw',
        background: style.pageBg,
        overflowX: 'hidden',
        overflowY: 'auto',
        position: 'relative',
    }
    const parentBoxDarkTheme = {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        minWidth: '100vw',
        background: style.darkTheme,
        overflowX: 'hidden',
        overflowY: 'auto',
        position: 'relative',
    }
    const dragContextBox = {
        position: 'absolute',
        top: '104px',
        left: '0px',
        right: '0px',
        margin: 'auto',
        width: '1180px',
        maxWidth: '1180px',
        minWidth: '1180px',
        height: 'fit-content',
        display: 'flex',
        gap: '15px',
        flexWrap: 'wrap',
        paddingBottom: '50px'
    }
    const dropableBox = {
        display: 'flex',
        gap: '20px',
        flexWrap: 'wrap',

    }
    const profileDragBox = {
        width: '580px',
        minWidth: '576px',
        height: '280px',
        minWidth: '280px',
        minHeight: '280px',
        // background: '#ffffff',
        background: themeName === 'light' ? '#ffffff' : '#000000',
        borderRadius: '3px',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #D0D7DE',
        position: 'relative'
    }

    //------------ Mobile design --------------

    const dragContextBoxMob = {

        position: 'absolute',
        top: '40px',
        left: '0px',
        right: '0px',
        margin: 'auto',
        width: '98vw',
        maxWidth: '98vw',
        minWidth: '98vw',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        paddingBottom: '50px',
        alignItems: 'center',
        justifyContent: 'center',

    }
    const dropableBoxMob = {

        display: 'flex',
        gap: '20px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
    const profileDragBoxMob = {


        width: '90vw',
        minWidth: '90vw',
        maxWidth: '90vw',
        height: '280px',
        minHeight: '98vw',
        background: themeName === 'light' ? '#ffffff' : '#000000',
        borderRadius: '3px',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #D0D7DE',
        position: 'relative'

    }
    // ------------------ tab design ----------------
    const dragContextBoxTab = {
        position: 'absolute',
        top: '104px',
        left: '0px',
        right: '0px',
        margin: 'auto',
        width: '95vw',
        maxWidth: '95vw',
        minWidth: '95vw',
        height: 'fit-content',
        display: 'flex',
        gap: '15px',
        flexWrap: 'wrap',
        paddingBottom: '50px',
        paddingLeft: '93px',

    }
    //-------------------------------------------------------------------
    const getUserPlatforms = async (props) => {

              if (props !== null && props !== "" && props !== undefined) {


              const q = query(collection(db, "users"), where("username", "==", username.trim()));
              const unsubscribe = onSnapshot(q, (querySnapshot) => {
              
              querySnapshot.forEach((doc) => {
                const responseData = doc.data()
                const userTheme = responseData?.themeName || 'light'
                setthemeName(userTheme)
                setuserDate(responseData)
                const responseArray = [...responseData.platforms]
                const check = responseArray.find((pData) => pData.id === "profile")
                if (check) {
                    const platformArray = [...responseData.platforms]
                    const updatedArray = [...platformArray].filter((row) => row.value !== "").map((row, index) => {

                        return ({ ...row, position: index })
                    })
                    setplatforms(updatedArray)
                } else {

                    const platformArray = [{ id: 'profile', icon: 'profile', symbol: '@', placeholder: 'profile', value: 'profile', position: 0, userDate: responseData }, ...responseData.platforms]
                    const updatedArray = [...platformArray].filter((row) => row.value !== "").map((row, index) => {

                        return ({ ...row, position: index })
                    })
                    setplatforms(updatedArray)
                }
              });
               
            });
            
          
          
          
            // const q = query(collection(db, "users"), where("username", "==", username));
            // const querySnapshot = await getDocs(q);
            // querySnapshot.forEach((doc) => {

            //     const responseData = doc.data()
            //     const userTheme = responseData?.themeName || 'light'
            //     setthemeName(userTheme)
            //     setuserDate(responseData)
            //     const responseArray = [...responseData.platforms]
            //     const check = responseArray.find((pData) => pData.id === "profile")
            //     if (check) {
            //         const platformArray = [...responseData.platforms]
            //         const updatedArray = [...platformArray].filter((row) => row.value !== "").map((row, index) => {

            //             return ({ ...row, position: index })
            //         })
            //         setplatforms(updatedArray)
            //     } else {

            //         const platformArray = [{ id: 'profile', icon: 'profile', symbol: '@', placeholder: 'profile', value: 'profile', position: 0, userDate: responseData }, ...responseData.platforms]
            //         const updatedArray = [...platformArray].filter((row) => row.value !== "").map((row, index) => {

            //             return ({ ...row, position: index })
            //         })
            //         setplatforms(updatedArray)
            //     }

            // });



        } else {


        }


    }

    const handleOpenLinkNewTab = (props) => {


        mixpanel.track('LinkOpenExternal', {
            $distinct_id: userDate?.userId,
            time: new Date().getTime(),
            user_id: userDate?.userId,
            user_email: userDate?.email,
            platform:props
        })

        posthog.capture(
            'LinkOpenExternal',
            {
                distinct_id: userDate?.userId,
                $set_once: {
                    user_id: userDate?.userId,
                    user_email: userDate?.email,
                    platform:props
                },
            }
        )


        if (props?.platformType === "custom") {

            window.open(props?.link, '_blank')

        } else {


            if (props?.id === "whatsapp") {

                const baseUrl = platformIdUrlArray?.filter((pData) => pData?.id === props?.id)[0]?.url + props?.countrycode + props?.value
                window.open(baseUrl, '_blank')

            } else {

                const baseUrl = platformIdUrlArray?.filter((pData) => pData?.id === props?.id)[0]?.url + props.value
                window.open(baseUrl, '_blank')
            }


        }

    }


    React.useEffect(() => {
        getUserPlatforms(username)
    }, [])



    return (
        <>

            <Desktop >
                <Box sx={

                    themeName === "light" ? parentBox : parentBoxDarkTheme

                }>
                    <Box sx={dragContextBox}>
                        <Box sx={dropableBox} >
                            {platform.map((row, index) => (row?.value === "profile" ?


                                <Box><Box sx={profileDragBox}>

                                    <Box sx={{
                                        position: 'absolute',
                                        top: '14px',
                                        right: '21px',
                                        width: 'fit-content',
                                        height: 'fit-content',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',

                                    }}>

                                    </Box>

                                    <Box sx={{
                                        marginTop: '50px',
                                        marginLeft: '45px',
                                        width: '500px',
                                        height: '154px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '40px'
                                    }}>

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}>
                                            {userDate?.imageUrl === "" ?
                                                <>

                                                    <Avatar size={120} src={profileIcon}></Avatar>
                                                </>
                                                :
                                                <>

                                                    <Avatar size={120} src={userDate?.imageUrl} sx={{ objectFit: 'cover' }}></Avatar>

                                                </>
                                            }
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '15px',
                                        }}>
                                            <Text className={themeName === "light" ? style.H7 : style.H7dark} sx={{ width: '340px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{userDate?.name}</Text>
                                            <Text className={themeName === "light" ? style.p4 : style.p4dark} sx={{ width: '340px', overflow: 'hidden', textOverflow: 'ellipsis', height: '96px' }}>
                                                {userDate?.description}
                                            </Text>
                                        </Box>

                                    </Box>

                                </Box>
                                </Box>





                                :


                                <Box>
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            width: '280px',
                                            height: '280px',
                                            minWidth: '280px',
                                            minHeight: '280px',
                                            background: iconWithBgColor?.filter((data) => data?.icon === row?.icon)[0]?.bgColor || 'rgba(161, 161, 170, 0.15)',
                                            borderRadius: '3px',
                                            border: '1px solid #D0D7DE',
                                            ":hover": {

                                            },
                                        }}

                                    >


                                        <Box sx={{
                                            position: 'absolute',
                                            top: '90px',
                                            left: '90px',
                                            margin: 'auto'
                                        }}>
                                            {row?.platformType === "custom" ?

                                                row.icon === "" ? <>
                                                    <Box sx={{
                                                        height: '100px',
                                                        width: '100px',
                                                        borderRadius: '50%',
                                                        background: '#A1A1AA',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <Text className={style.H1} sx={{ textAlign: 'center' }} >{(row?.value?.substring(0, 1)?.toUpperCase())}</Text>
                                                    </Box>
                                                </> :

                                                    <>
                                                        <img
                                                            src={row.icon}
                                                            style={{ height: '100px', width: '100px', borderRadius: '50%', objectFit: 'cover' }}
                                                        >
                                                        </img>
                                                    </>


                                                :
                                                <img
                                                    src={iconWithBgColor?.filter((data) => data?.icon === row?.icon)[0]?.icon}
                                                    style={{ height: '100px', width: '100px' }}
                                                >
                                                </img>

                                            }

                                        </Box>

                                        <Box sx={{
                                            position: 'absolute',
                                            top: '14px',
                                            left: '0px',
                                            right: '0px',
                                            margin: 'auto',
                                            width: '100%',
                                            height: 'fit-content',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}>

                                        </Box>

                                        <Box sx={{
                                            position: 'absolute',
                                            bottom: '24px',
                                            left: '0px',
                                            right: '0px',
                                            margin: 'auto',
                                            width: '230px',
                                            height: '36px',
                                            background: '#F4F4F5',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: '15px',

                                            ":hover": {
                                                background: '#f3f4f6',
                                                cursor: 'pointer'
                                            }
                                        }}
                                            onClick={() => {

                                                handleOpenLinkNewTab(row)


                                            }}

                                        >

                                            <Text className={style.p3} sx={{ textAlign: 'center', width: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >

                                                {row?.label !== "" && row?.label !== null && row?.label !== undefined ? row.label :

                                                    row?.platformType !== "custom" ?
                                                        "@" + row.value
                                                        :
                                                        row.value

                                                }
                                            </Text>
                                            <Box >
                                                <ArrowUpRightIcon />
                                            </Box>

                                        </Box>


                                    </Box>
                                </Box>
                            ))}


                        </Box>





                    </Box>


                </Box>




            </Desktop>

            <Mobile>
                <Box sx={
                    //parentBox
                    themeName === "light" ? parentBox : parentBoxDarkTheme
                }>
                    <Box sx={dragContextBoxMob}>
                        <Box sx={dropableBoxMob} >
                            {platform.map((row, index) => (row?.value === "profile" ?


                                <Box>

                                    <Box sx={profileDragBoxMob} >
                                        <Box sx={{
                                            marginTop: '50px',
                                            width: '100%',
                                            height: '154px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '40px',
                                            paddingLeft: '20px',
                                            paddingRight: '20px'
                                        }}>

                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center'
                                            }}>
                                                {userDate?.imageUrl === "" ?
                                                    <>
                                                        <Avatar size={120} src={profileIcon}></Avatar>
                                                    </>
                                                    :
                                                    <>
                                                        <Avatar size={120} src={userDate?.imageUrl} sx={{ objectFit: 'cover' }}></Avatar>
                                                    </>
                                                }
                                            </Box>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '15px',
                                            }}>
                                                <Text className={themeName === "light" ? style.H7 : style.H7dark} sx={{ width: '320px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', }}>{userDate?.name}</Text>
                                                <Text className={themeName === "light" ? style.p4 : style.p4dark} sx={{ width: '320px', overflow: 'hidden', textOverflow: 'ellipsis', height: '115px' }}>
                                                    {userDate?.description}
                                                </Text>
                                            </Box>

                                        </Box>

                                    </Box>
                                </Box>





                                :


                                <Box>
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            width: '90vw',
                                            minWidth: '90vw',

                                            // width: '280px',
                                            // minWidth: '280px',

                                            height: '280px',
                                            minHeight: '280px',
                                            background: iconWithBgColor?.filter((data) => data?.icon === row?.icon)[0]?.bgColor || 'rgba(161, 161, 170, 0.15)',
                                            borderRadius: '3px',
                                            border: '1px solid #D0D7DE',
                                            ":hover": {

                                            },
                                        }}

                                    >


                                        <Box sx={{

                                            position: 'absolute',
                                            top: '90px',
                                            left: '0px',
                                            right: '0px',
                                            margin: 'auto',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'

                                            // position: 'absolute',
                                            // top: '90px',
                                            // left: '90px',
                                            // margin: 'auto',
                                        }}>
                                            {row?.platformType === "custom" ?

                                                row.icon === "" ? <>
                                                    <Box sx={{
                                                        height: '100px',
                                                        width: '100px',
                                                        borderRadius: '50%',
                                                        background: '#A1A1AA',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <Text className={style.H1} sx={{ textAlign: 'center' }} >{(row?.value?.substring(0, 1)?.toUpperCase())}</Text>
                                                    </Box>
                                                </> :

                                                    <>
                                                        <img
                                                            src={row.icon}
                                                            style={{ height: '100px', width: '100px', borderRadius: '50%', objectFit: 'cover' }}
                                                        >
                                                        </img>
                                                    </>


                                                :
                                                <img
                                                    src={iconWithBgColor?.filter((data) => data?.icon === row?.icon)[0]?.icon}
                                                    style={{ height: '100px', width: '100px' }}
                                                >
                                                </img>

                                            }

                                        </Box>

                                        <Box sx={{
                                            position: 'absolute',
                                            top: '14px',
                                            left: '0px',
                                            right: '0px',
                                            margin: 'auto',
                                            width: '100%',
                                            height: 'fit-content',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}>

                                        </Box>

                                        <Box sx={{
                                            position: 'absolute',
                                            bottom: '24px',
                                            left: '0px',
                                            right: '0px',
                                            margin: 'auto',
                                            width: '230px',
                                            height: '36px',
                                            background: '#F4F4F5',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: '15px',

                                            ":hover": {
                                                background: '#f3f4f6',
                                                cursor: 'pointer'
                                            }
                                        }}
                                            onClick={() => {
                                                handleOpenLinkNewTab(row)
                                            }}

                                        >

                                            <Text className={style.p3} sx={{ textAlign: 'center', width: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >

                                                {row?.label !== "" && row?.label !== null && row?.label !== undefined ? row.label :

                                                    row?.platformType !== "custom" ?
                                                        "@" + row.value
                                                        :
                                                        row.value
                                                }
                                            </Text>

                                            <Box >
                                                <ArrowUpRightIcon />
                                            </Box>

                                        </Box>


                                    </Box>
                                </Box>
                            ))}


                        </Box>





                    </Box>



                </Box>

            </Mobile>

            <TabletPortrait>
                <Box sx={
                    //parentBox
                    themeName === "light" ? parentBox : parentBoxDarkTheme
                }>
                    <Box sx={dragContextBoxTab}>
                        <Box sx={dropableBox} >
                            {platform.map((row, index) => (row?.value === "profile" ?


                                <Box><Box sx={profileDragBox}>

                                    <Box sx={{
                                        position: 'absolute',
                                        top: '14px',
                                        right: '21px',
                                        width: 'fit-content',
                                        height: 'fit-content',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',

                                    }}>

                                    </Box>

                                    <Box sx={{
                                        marginTop: '50px',
                                        marginLeft: '45px',
                                        width: '500px',
                                        height: '154px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '40px'
                                    }}>

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}>
                                            {userDate?.imageUrl === "" ?
                                                <>

                                                    <Avatar size={120} src={profileIcon}></Avatar>
                                                </>
                                                :
                                                <>

                                                    <Avatar size={120} src={userDate?.imageUrl} sx={{ objectFit: 'cover' }} ></Avatar>

                                                </>
                                            }
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '15px',
                                        }}>
                                            <Text className={themeName === "light" ? style.H7 : style.H7dark} sx={{ width: '340px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{userDate?.name}</Text>
                                            <Text className={themeName === "light" ? style.p4 : style.p4dark} sx={{ width: '340px', overflow: 'hidden', textOverflow: 'ellipsis', height: '104px' }}>
                                                {userDate?.description}
                                            </Text>
                                        </Box>

                                    </Box>

                                </Box>
                                </Box>





                                :


                                <Box>
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            width: '280px',
                                            height: '280px',
                                            minWidth: '280px',
                                            minHeight: '280px',
                                            background: iconWithBgColor?.filter((data) => data?.icon === row?.icon)[0]?.bgColor || 'rgba(161, 161, 170, 0.15)',
                                            borderRadius: '3px',
                                            border: '1px solid #D0D7DE',
                                            ":hover": {

                                            },
                                        }}

                                    >


                                        <Box sx={{
                                            position: 'absolute',
                                            top: '90px',
                                            left: '90px',
                                            margin: 'auto'
                                        }}>
                                            {row?.platformType === "custom" ?

                                                row.icon === "" ? <>
                                                    <Box sx={{
                                                        height: '100px',
                                                        width: '100px',
                                                        borderRadius: '50%',
                                                        background: '#A1A1AA',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <Text className={style.H1} sx={{ textAlign: 'center' }} >{(row?.value?.substring(0, 1)?.toUpperCase())}</Text>
                                                    </Box>
                                                </> :

                                                    <>
                                                        <img
                                                            src={row.icon}
                                                            style={{ height: '100px', width: '100px', borderRadius: '50%', objectFit: 'cover' }}
                                                        >
                                                        </img>
                                                    </>


                                                :
                                                <img
                                                    src={iconWithBgColor?.filter((data) => data?.icon === row?.icon)[0]?.icon}
                                                    style={{ height: '100px', width: '100px' }}
                                                >
                                                </img>

                                            }

                                        </Box>

                                        <Box sx={{
                                            position: 'absolute',
                                            top: '14px',
                                            left: '0px',
                                            right: '0px',
                                            margin: 'auto',
                                            width: '100%',
                                            height: 'fit-content',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}>

                                        </Box>

                                        <Box sx={{
                                            position: 'absolute',
                                            bottom: '24px',
                                            left: '0px',
                                            right: '0px',
                                            margin: 'auto',
                                            width: '230px',
                                            height: '36px',
                                            background: '#F4F4F5',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: '15px',

                                            ":hover": {
                                                background: '#f3f4f6',
                                                cursor: 'pointer'
                                            }
                                        }}
                                            onClick={() => { handleOpenLinkNewTab(row) }}

                                        >

                                            <Text className={style.p3} sx={{ textAlign: 'center', width: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >
                                                {row?.label !== "" && row?.label !== null && row?.label !== undefined ? row.label :
                                                    row?.platformType !== "custom" ?
                                                        "@" + row.value
                                                        :
                                                        row.value
                                                }
                                            </Text>
                                            <Box >
                                                <ArrowUpRightIcon />
                                            </Box>

                                        </Box>


                                    </Box>
                                </Box>
                            ))}


                        </Box>





                    </Box>


                </Box>
            </TabletPortrait>

            <TabletLandscape>
                <Box sx={
                    //  parentBox
                    themeName === "light" ? parentBox : parentBoxDarkTheme
                }>
                    <Box sx={dragContextBoxTab}>
                        <Box sx={dropableBox} >
                            {platform.map((row, index) => (row?.value === "profile" ?


                                <Box><Box sx={profileDragBox}>

                                    <Box sx={{
                                        position: 'absolute',
                                        top: '14px',
                                        right: '21px',
                                        width: 'fit-content',
                                        height: 'fit-content',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',

                                    }}>

                                    </Box>

                                    <Box sx={{
                                        marginTop: '50px',
                                        marginLeft: '45px',
                                        width: '500px',
                                        height: '154px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '40px'
                                    }}>

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}>
                                            {userDate?.imageUrl === "" ?
                                                <>

                                                    <Avatar size={120} src={profileIcon}></Avatar>
                                                </>
                                                :
                                                <>

                                                    <Avatar size={120} src={userDate?.imageUrl} sx={{ objectFit: 'cover' }} ></Avatar>

                                                </>
                                            }
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '15px',
                                        }}>
                                            <Text className={themeName === "light" ? style.H7 : style.H7dark} sx={{ width: '340px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{userDate?.name}</Text>
                                            <Text className={themeName === "light" ? style.p4 : style.p4dark} sx={{ width: '340px', overflow: 'hidden', textOverflow: 'ellipsis', height: '105px' }}>
                                                {userDate?.description}
                                            </Text>
                                        </Box>

                                    </Box>

                                </Box>
                                </Box>





                                :


                                <Box>
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            width: '280px',
                                            height: '280px',
                                            minWidth: '280px',
                                            minHeight: '280px',
                                            background: iconWithBgColor?.filter((data) => data?.icon === row?.icon)[0]?.bgColor || 'rgba(161, 161, 170, 0.15)',
                                            borderRadius: '3px',
                                            border: '1px solid #D0D7DE',
                                            ":hover": {

                                            },
                                        }}

                                    >


                                        <Box sx={{
                                            position: 'absolute',
                                            top: '90px',
                                            left: '90px',
                                            margin: 'auto'
                                        }}>
                                            {row?.platformType === "custom" ?

                                                row.icon === "" ? <>
                                                    <Box sx={{
                                                        height: '100px',
                                                        width: '100px',
                                                        borderRadius: '50%',
                                                        background: '#A1A1AA',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <Text className={style.H1} sx={{ textAlign: 'center' }} >{(row?.value?.substring(0, 1)?.toUpperCase())}</Text>
                                                    </Box>
                                                </> :

                                                    <>
                                                        <img
                                                            src={row.icon}
                                                            style={{ height: '100px', width: '100px', borderRadius: '50%', objectFit: 'cover' }}
                                                        >
                                                        </img>
                                                    </>


                                                :
                                                <img
                                                    src={iconWithBgColor?.filter((data) => data?.icon === row?.icon)[0]?.icon}
                                                    style={{ height: '100px', width: '100px' }}
                                                >
                                                </img>

                                            }

                                        </Box>

                                        <Box sx={{
                                            position: 'absolute',
                                            top: '14px',
                                            left: '0px',
                                            right: '0px',
                                            margin: 'auto',
                                            width: '100%',
                                            height: 'fit-content',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}>

                                        </Box>

                                        <Box sx={{
                                            position: 'absolute',
                                            bottom: '24px',
                                            left: '0px',
                                            right: '0px',
                                            margin: 'auto',
                                            width: '230px',
                                            height: '36px',
                                            background: '#F4F4F5',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: '15px',

                                            ":hover": {
                                                background: '#f3f4f6',
                                                cursor: 'pointer'
                                            }
                                        }}
                                            onClick={() => { handleOpenLinkNewTab(row) }}

                                        >

                                            <Text className={style.p3} sx={{ textAlign: 'center', width: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >
                                                {row?.label !== "" && row?.label !== null && row?.label !== undefined ? row.label :

                                                    row?.platformType !== "custom" ?
                                                        "@" + row.value
                                                        :
                                                        row.value


                                                }
                                            </Text>
                                            <Box >
                                                <ArrowUpRightIcon />
                                            </Box>

                                        </Box>


                                    </Box>
                                </Box>
                            ))}


                        </Box>





                    </Box>


                </Box>
            </TabletLandscape>

        </>
    )
}