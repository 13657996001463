import { Box, Button, IconButton, Text } from "@primer/react"
import { useLocation, useNavigate } from "react-router-dom"
import style from './web.module.scss'
import { useDispatch, useSelector } from "react-redux"
import { openCloseMobileNav } from "../redux/features/webSlice"
import { XIcon } from "@primer/octicons-react"
import React from "react"
import posthog from "posthog-js";
posthog.init('phc_mGFMcRORH045UBsthDPi8bGDGMAPCvc7qtACPde72Hh', { api_host: 'https://app.posthog.com' })
export default function MobileSideNav() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const isOpenSideNav = useSelector((state)=> state.webStore.isOpenSideNav)  

    const closeMobileSideNav = (props) => {

           dispatch(
              openCloseMobileNav({
                isOpenSideNav:false
              })
           )
    }

    const loginClickEvent = (props) => {

        posthog.capture(
            'LoginClickLanding',
            {
                distinct_id: 'LoginClickLanding',
                timestamp: new Date().toISOString(),
            }
        )

    }

    const signUpClickEvent = (props) => {

        posthog.capture(
            'SignupClickLanding',
            {
                distinct_id: 'SignupClickLanding',
                timestamp: new Date().toISOString(),
            }
        )

    }

  


    return (<>
        <Box sx={{
            zIndex: '1000',
            position: 'fixed',
            top:'0px',
            right: '0px',
            display: isOpenSideNav?'flex':'none',
            height: '180vh',
            width: '70vw',
            minWidth: '70vw',
            maxWidth: '70vw',
            minHeight: '100vh',
            maxHeight: '100vh',
            background: 'white',
            flexDirection: 'column',
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
            paddingTop: '3.5rem',
            gap: '2rem'
        }}>
          
            <IconButton 
            sx={{ position: 'absolute', right: '25px', top: '25px', border: 'none',background:'transparent',display:'flex',alignItems:'center',justifyContent:'center' }} 
            icon={()=> <XIcon size={32} />}
            onClick={()=>{
                 closeMobileSideNav("")
            }}
             />  

            <Text className={style.linkHeader} 
            onClick={() => 
               
               {
                closeMobileSideNav("") 
                navigate('/pricing')
               }
               

            } sx={{fontSize:'1.25rem'}
            } >Pricing</Text>
            <Text className={style.linkHeader} onClick={() =>
            
               {
                loginClickEvent("")
                closeMobileSideNav("") 
                navigate('/login')
               }
        
             } sx={{fontSize:'1.25rem'}} >Log in</Text>
            <Button
                className={style.btnStyle}
                variant="invisible"
                sx={{
                    minWidth: '244px',
                    minHeight: '44px',
                    maxHeight: '44px',
                    fontSize:'.875rem',
                    background: style.btnbackgroundColor,
                    color: style.whitecolor,
                    borderRadius: '9999px',
                    '&:hover': {
                        background: style.blackColor,
                        backgroundColor: style.blackColor

                    }
                }}
                onClick={() => {
                    signUpClickEvent("")
                    closeMobileSideNav("") 
                    navigate('/signup')
                }}
            >Try for free</Button>

        </Box>

    </>)
}