import React from "react";
import { Box, Button,Text, TextInput } from "@primer/react";
import style from '../global.module.scss'
import { useMediaQuery } from 'react-responsive'
import twitter from '../assets/socialIcons/twitter.svg'
import instagram from '../assets/socialIcons/Instagram.svg'
import linkedIn from '../assets/socialIcons/linkedIn.svg'
import youtube from '../assets/socialIcons/youtube.svg'
import spotify from '../assets/socialIcons/spotify.svg'
import marxiix from '../assets/socialIcons/marxiix.svg'
import thread from '../assets/socialIcons/thread.svg'
import discord from '../assets/socialIcons/discord.svg'
import github from '../assets/socialIcons/github.svg'
import gumroad from '../assets/socialIcons/gumroad.svg'
import medium from '../assets/socialIcons/medium.svg'
import figma from '../assets/socialIcons/figma.svg'
import drible from '../assets/socialIcons/drible.svg'
import sms from '../assets/socialIcons/sms.svg'
import behance from '../assets/socialIcons/behance.svg'
import whatsapp from '../assets/socialIcons/whatsapp.svg'
import clubhouse from '../assets/socialIcons/clubhouse.svg'
import mastodon from '../assets/socialIcons/mastodon.svg'
import tiktok from '../assets/socialIcons/tiktok.svg'
import producthunt from '../assets/socialIcons/producthunt.svg'
import coffe from '../assets/socialIcons/coffe.svg'
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}
const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
}

const parentBox = {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
    background: style.pageBg,
    
}

const contentBox = {

    position: 'absolute',
    top: '10px',
    left: '0px',
    right: '0px',
    margin: 'auto',
    width: ['80%', '601px'],
    borderRadius: '14px',
    border: '1px solid #D1D5DB',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '30px',
    paddingTop: '40px',
    paddingBottom: '40px',
    background: '#FFFFFF',
    zIndex:'1'
}

const contentBoxChildBox = {
    display: 'flex',
    flexDirection: 'column',
    width: ['90%', '426px'],
    height: 'fit-content',
    gap: '10px',
}

const NextBtnStyle = {

    minWidth: '120px',
    minHeight: '48px',
    maxHeight: '48px',
    background: '#000000',
    color: '#FFFFFF',
    borderRadius: '12px',
    '&:hover': {
        background: '#000000',
        backgroundColor: '#000000'

    }
}

const LaterBtStyle = {
    minWidth: '120px',
    minHeight: '48px',
    maxHeight: '48px',
    background: '#F4F4F5',
    borderRadius: '12px',
    border:'none' 
}

const platformBoxGrid = {
    display:'grid',
    gridTemplateColumns:'repeat(3, 1fr)',
    gridTemplateRows:'repeat(7, auto)',
    columnGap:'25px',
    rowGap:'20px',

}

const platformBoxGridContainer = {

    width: '165px',
    height: '48px',
    border: '2px solid #E4E4E7',
    borderRadius: '8px',
    background: '#F4F4F5',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textInput:{
        border: 'none', 
        background: 'transparent', 
        height: '48px' 
    }
    ,
    textInputTrailing:{

        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        gap: '11px' 
    }
}

 




//----------- Mobile Responsive code ----------

const contentBoxMobile = {
    
    width: ['80%', '350px'],
    height:'fit-content',
    borderRadius:'14px',
    border:'1px solid #D1D5DB',
    position: 'absolute',
    top: '10px',
    left: '0px',
    right: '0px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '30px',
    paddingTop: '40px',
    paddingBottom: '40px',
    background: '#FFFFFF',
    zIndex:'1'
}

const platformBoxGridMobile = {

     display:'grid',
     gridTemplateColumns:'repeat(1, 1fr)',
     gridTemplateRows:'repeat(21, auto)',
     columnGap:'25px',
     rowGap:'20px',
}

const platformBoxGridContainerMobile = {

    width: '245px',
    height: '48px',
    border: '2px solid #E4E4E7',
    borderRadius: '8px',
    background: '#F4F4F5',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textInput:{
        border: 'none', 
        background: 'transparent', 
        height: '48px' 
    }
    ,
    textInputTrailing:{
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        gap: '11px' 
    }
}





export default function AddPlatForms() {

    const navigate = useNavigate()  
    const user = auth?.currentUser
     

    const [platforms,setplatforms] = React.useState([
        {id:'twitter',icon:twitter,symbol:'@',placeholder:'username',value:'',position:0},
        {id:'instagram',icon:instagram,symbol:'@',placeholder:'username',value:'',position:1},
        {id:'linkedIn',icon:linkedIn,symbol:'@',placeholder:'username',value:'',position:2},
        {id:'youtube',icon:youtube,symbol:'@',placeholder:'username',value:'',position:3},
        {id:'spotify',icon:spotify,symbol:'@',placeholder:'username',value:'',position:4},
        {id:'marxiix',icon:marxiix,symbol:'@',placeholder:'username',value:'',position:5},
        {id:'thread',icon:thread,symbol:'@',placeholder:'username',value:'',position:6},
        {id:'discord',icon:discord,symbol:'@',placeholder:'username',value:'',position:7},
        {id:'github',icon:github,symbol:'@',placeholder:'username',value:'',position:8},
        {id:'gumroad',icon:gumroad,symbol:'@',placeholder:'username',value:'',position:9},
        {id:'medium',icon:medium,symbol:'@',placeholder:'username',value:'',position:10},
        {id:'figma',icon:figma,symbol:'@',placeholder:'username',value:'',position:11},
        {id:'drible',icon:drible,symbol:'@',placeholder:'username',value:'',position:12},
        {id:'sms',icon:sms,symbol:'@',placeholder:'username',value:'',position:13},
        {id:'behance',icon:behance,symbol:'@',placeholder:'username',value:'',position:14},
        {id:'whatsapp',icon:whatsapp,symbol:'@',placeholder:'username',value:'',position:15},
        {id:'clubhouse',icon:clubhouse,symbol:'@',placeholder:'username',value:'',position:16},
        {id:'mastodon',icon:mastodon,symbol:'@',placeholder:'username',value:'',position:17},
        {id:'tiktok',icon:tiktok,symbol:'@',placeholder:'username',value:'',position:18},
        {id:'producthunt',icon:producthunt,symbol:'@',placeholder:'username',value:'',position:19},
        {id:'coffe',icon:coffe,symbol:'@',placeholder:'username',value:'',position:20}
    ])
    

    const handleChangeUsernameInput = (props,index) => {

           setplatforms((prevState)=>{
                 const newState = prevState.map((newrow,newindex)=>{
                         if(newrow.position===index){

                             if(newrow.id ==="whatsapp"){

                                  if(!isNaN(props)){
                                      return({...newrow,value:props,countrycode:''})
                                  }else{
                                      return({...newrow,value:''})
                                  }  

                             }else{
                                return({...newrow,value:props})

                             }

                            
                         }else{
                             return({...newrow}) 
                         }
                 })
                 return newState
           })
    }

    

    
    const nexBtnClick =async (props) => {

         
          if(user?.uid!==null&&user.uid!==""&&user.uid!==undefined){
 
                 
                 
                 
                 const userRef = doc(db, 'users', user.uid);
                 const docSnap = await getDoc(userRef);
                 const updatePlatforms = [...platforms]
                 .filter((row)=> row.value!==""&&row.value!==null)
                 .map((row,index)=>{
                       
                        return({...row,position:index})
                 })


                 await updateDoc(userRef,{platforms:updatePlatforms})

                 if (docSnap.exists()) {

                       const responseData = docSnap.data()
                       const userName = responseData?.username
                       navigate(`/${userName}`) 
                       window.location.reload()  
                 }else{


                 }


                // navigate('/dashboard')     
 
          }else{

                  
               
          }
            
    }

    const laterBtnClick =async (props) => {

                const userRef = doc(db, 'users', user.uid);
                const updatePlatforms = [...platforms].filter((row)=> row.value!==""&&row.value!==null).map((row,index)=>{
                       
                    return({...row,position:index})
                })
                await updateDoc(userRef,{platforms:updatePlatforms})
                navigate('/dashboard')

    }




    React.useState(()=>{

         
         
    })
        

    return (<>
        <Default >
                <Box sx={parentBox}>

                  <Box sx={contentBox}>
                  <Box sx={contentBoxChildBox}>
                           <Text className={style.H7} sx={{ textAlign: 'center' }} >Let's add up to 14 of your favorite platforms</Text>
                           <Text className={style.p4} sx={{ textAlign: 'center' }}>Make your platforms shine in your bio!</Text>
                  </Box>
                  {/* platform layout------ */}

                    <Box sx={platformBoxGrid}>

                           {platforms.map((row,index)=>{

                               return(
                                   <>
                                       <Box sx={platformBoxGridContainer}>

                                           <TextInput
                                               className={style.p4}
                                               leadingVisual={() => <Box 
                                               sx={platformBoxGridContainer.textInputTrailing}>
                                                   <img src={row.icon}></img>@
                                               </Box>}
                                               sx={platformBoxGridContainer.textInput}
                                               placeholder="username"
                                               value={row.value}
                                               onChange={(e)=>{
                                                  handleChangeUsernameInput(e.target.value,index)
                                               }}
                                           >
                                           </TextInput>

                                       </Box>
                                   </>
                               )
                           })}

                           
                     </Box> 
 

                  {/* --------------------- */}


                  <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '20px'
                        }}>
                            <Button
                                variant="invisible"
                                className={style.p5}
                                sx={NextBtnStyle}
                                onClick={()=>{
                                     nexBtnClick("")
                                }}
                            >
                                Next
                            </Button>
                            <Button
                                // variant="invisible"
                                className={style.p5}
                                sx={LaterBtStyle}
                                onClick={()=>{
                                      laterBtnClick("")
                                }}
                            >
                                Later
                            </Button>
                        </Box>

                 </Box>

               


                </Box>
        </Default>
        <Mobile>
            <Box sx={parentBox}>
                <Box sx={contentBoxMobile}>

                    <Box sx={contentBoxChildBox}>
                        <Text className={style.H7} sx={{ textAlign: 'center' }} >Let's add up to 14 of your favorite platforms</Text>
                        <Text className={style.p4} sx={{ textAlign: 'center' }}>Make your platforms shine in your bio!</Text>
                    </Box>

                    <Box sx={platformBoxGridMobile}>

                        {platforms.map((row, index) => {

                            return (
                                <>
                                    <Box sx={platformBoxGridContainerMobile}>

                                        <TextInput
                                            className={style.p4}
                                            leadingVisual={() => <Box
                                                sx={platformBoxGridContainerMobile.textInputTrailing}>
                                                <img src={row.icon}></img>@
                                            </Box>}
                                            sx={platformBoxGridContainerMobile.textInput}
                                            placeholder="username"
                                            value={row.value}
                                               onChange={(e)=>{
                                                  handleChangeUsernameInput(e.target.value,index)
                                             }}
                                        >
                                        </TextInput>

                                    </Box>
                                </>
                            )
                        })}


                    </Box> 

                    <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '20px'
                        }}>
                            <Button
                                variant="invisible"
                                className={style.p5}
                                sx={NextBtnStyle}
                                onClick={()=>{
                                    nexBtnClick("")
                                }}
                            >
                                Next
                            </Button>
                            <Button
                                // variant="invisible"
                                className={style.p5}
                                sx={LaterBtStyle}
                                onClick={()=>{
                                    laterBtnClick("")
                                }}
                            >
                                Later
                            </Button>
                        </Box>


                </Box>
            </Box>
        </Mobile>

    </>)

}