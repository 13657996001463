import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";

export const getFirebaseUser = createAsyncThunk("getFirebaseUser",async (data)=>{
    return({firebaseUser:data.firebaseUser})
})





export const firebaseSlice = createSlice({
    name:'firebaseSlice',
    initialState:{
       firebaseUser:null,
       loading:false
    },
    extraReducers:{
       [getFirebaseUser.pending]: (state, action) =>{
           
           state.firebaseUser = null
           state.loading = true

       },
       [getFirebaseUser.fulfilled]:(state, action)=>{
           
           state.firebaseUser = action.payload.firebaseUser
           state.loading = false
       },
       [getFirebaseUser.rejected]:(state,action) => {
         
           state.firebaseUser = null
           state.loading = true
       },
       

    }
})

export default firebaseSlice.reducer
