 
 import { initializeApp } from "firebase/app";
 import { getFirestore } from "firebase/firestore";
 import {getAuth, GoogleAuthProvider, onAuthStateChanged} from 'firebase/auth'
import { getAnalytics } from "firebase/analytics";
 
 
 
 const firebaseConfig = {
    apiKey: "AIzaSyAMDz5eS5puv0fojvbqSRu-7JDs_kVRFmc",
  //  authDomain: "superrlink-61f8e.firebaseapp.com",
  //  authDomain:"app.superrlink.com",
    authDomain:"superrlink.com",
    projectId: "superrlink-61f8e",
    storageBucket: "superrlink-61f8e.appspot.com",
    messagingSenderId: "741765791639",
    appId: "1:741765791639:web:d4474e5e411516efec0ed2",
    measurementId: "G-ZQZ07Z0XXL"
 };
 
 const app = initializeApp(firebaseConfig);
 
 export const analytics = getAnalytics(app)

 export const db = getFirestore(app);
 export const auth =  getAuth(app);
 export const googleProvider = new GoogleAuthProvider();
  
 
  


 