import { Box, Dialog, Text, TextInput, Button, Select } from "@primer/react"
import React from "react"
import style from '../../global.module.scss'
import { useMediaQuery } from 'react-responsive'
import { useDispatch, useSelector } from "react-redux"
import { editPlatformWidgetOpenClose, profilereloadTrigger } from "../redux/features/dashboardSlice"
import twitter from '../../assets/socialIcons/twitter.svg'
import instagram from '../../assets/socialIcons/Instagram.svg'
import linkedIn from '../../assets/socialIcons/linkedIn.svg'
import youtube from '../../assets/socialIcons/youtube.svg'
import spotify from '../../assets/socialIcons/spotify.svg'
import marxiix from '../../assets/socialIcons/marxiix.svg'
import thread from '../../assets/socialIcons/thread.svg'
import discord from '../../assets/socialIcons/discord.svg'
import github from '../../assets/socialIcons/github.svg'
import gumroad from '../../assets/socialIcons/gumroad.svg'
import medium from '../../assets/socialIcons/medium.svg'
import figma from '../../assets/socialIcons/figma.svg'
import drible from '../../assets/socialIcons/drible.svg'
import sms from '../../assets/socialIcons/sms.svg'
import behance from '../../assets/socialIcons/behance.svg'
import whatsapp from '../../assets/socialIcons/whatsapp.svg'
import clubhouse from '../../assets/socialIcons/clubhouse.svg'
import mastodon from '../../assets/socialIcons/mastodon.svg'
import tiktok from '../../assets/socialIcons/tiktok.svg'
import producthunt from '../../assets/socialIcons/producthunt.svg'
import coffe from '../../assets/socialIcons/coffe.svg'
import { doc, getDoc, updateDoc } from "firebase/firestore"
import { db } from "../../firebase"
import countries from "../objects/countries"


const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}
const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
}

const countryArray = countries

const iconArraystatic = [
    { id: 'twitter', icon: twitter },
    { id: 'instagram', icon: instagram },
    { id: 'linkedIn', icon: linkedIn },
    { id: 'youtube', icon: youtube },
    { id: 'spotify', icon: spotify },
    { id: 'marxiix', icon: marxiix },
    { id: 'thread', icon: thread },
    { id: 'discord', icon: discord },
    { id: 'github', icon: github },
    { id: 'gumroad', icon: gumroad },
    { id: 'medium', icon: medium },
    { id: 'figma', icon: figma },
    { id: 'drible', icon: drible },
    { id: 'sms', icon: sms },
    { id: 'behance', icon: behance },
    { id: 'whatsapp', icon: whatsapp },
    { id: 'clubhouse', icon: clubhouse },
    { id: 'mastodon', icon: mastodon },
    { id: 'tiktok', icon: tiktok },
    { id: 'producthunt', icon: producthunt },
    { id: 'coffe', icon: coffe }
]


const inputStyle = {
    width: '235px',
    maxWidth: '235px',
    minHeight: '48px',
    maxHeight: '48px',
    borderRadius: '8px',
    minWidth: '235px',

}

const inputStyleWhatsapp = {
    width: '210px',
    maxWidth: '210px',
    minHeight: '48px',
    maxHeight: '48px',
    borderRadius: '8px',
    minWidth: '210px',

}

const inputSelectStyle = {
    width: '75px',
    maxWidth: '75px',
    minWidth: '75px',
    minHeight: '48px',
    maxHeight: '48px',
    borderRadius: '8px',
   
}

const NextBtnStyle = {

    minWidth: '120px',
    minHeight: '48px',
    maxHeight: '48px',
    background: '#000000',
    color: '#FFFFFF',
    borderRadius: '12px',
    '&:hover': {
        background: '#000000',
        backgroundColor: '#000000'

    }
}

//------- mobile ----------


const inputStyleMobile = {
    width: '80vw',
    maxWidth: '80vw',
    minHeight: '48px',
    maxHeight: '48px',
    borderRadius: '8px',
    minWidth: '80vw',

}


export default function EditPlatformWidgetDialog() {


    const dispatch = useDispatch()
    const [iconArray, seticonArray] = React.useState(iconArraystatic)
    const firebaseUser = useSelector((state) => state.firebaseStore.firebaseUser)
    const platformWidgetopenClose = useSelector((state) => state.dasboardStore.platformWidgetopenClose)
    const platformId = useSelector((state) => state.dasboardStore.platformId)
    const [platform, setplatform] = React.useState([])
    const [inputField, setinputField] = React.useState({
        username: '',
        usernameValidation: '',
        label: '',
        platFormType:''
    })
    const [selectedCountryCode,setselectedCountryCode] = React.useState("")
    const [countryInputError,setcountryInputError] = React.useState("")
    const closeDialog = (props) => {

        dispatch(editPlatformWidgetOpenClose({
            platformWidgetopenClose: false,
            platformId: null
        }))

        setinputField((prevState) => {
            return ({ ...prevState, username: '', usernameValidation: '', label: '',platFormType:'' })
        })
        setselectedCountryCode("")
        setcountryInputError("")
    }

    const handleChangeInputField = (props, value) => {

        if (props === "username") {

            if(platformId==="whatsapp"){

                  setinputField((prevState) => {

                    if(!isNaN(value)){
                        
                       
                        return ({ ...prevState, username: value, usernameValidation: '' })
                        
                    }else{
                        return ({ ...prevState, username:''})
                        
                    }
                   })
                   

            }else{
                setinputField((prevState) => {
                    return ({ ...prevState, username: value, usernameValidation: '' })
                })

            }

           
        } else if (props === "label") {
            setinputField((prevState) => {
                return ({ ...prevState, label: value })
            })
        }

    }

    const getUserPlatforms = async (props, platformId) => {

        const userRef = doc(db, 'users', props);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {

            const responseData = docSnap.data()
            const platformArray = [...responseData.platforms]
            const updatedArray = [...platformArray].filter((row) => row.value !== "").map((row, index) => {
                return ({ ...row, position: index })
            })

            console.log("updateArry", updatedArray)
            console.log("platformId", platformId)
            const singlePlatForm = updatedArray.filter((data) => data.id === platformId + "")

            const platFormType = singlePlatForm[0]?.platformType
            
            if(platFormType==="custom"){

                setinputField((prevState) => { return ({ ...prevState, username: singlePlatForm[0]?.link, label: singlePlatForm[0]?.label,platFormType:'custom' }) })
            
            }else{

                setinputField((prevState) => { return ({ ...prevState, username: singlePlatForm[0]?.value, label: singlePlatForm[0]?.label,platFormType:'' }) })
            
            }

            if(singlePlatForm[0]?.id==="whatsapp"){

                  setselectedCountryCode(singlePlatForm[0]?.countrycode)
            }


          //  setinputField((prevState) => { return ({ ...prevState, username: singlePlatForm[0]?.value, label: singlePlatForm[0]?.label }) })

            setplatform(updatedArray)
            seticonArray((prevState) => {
                const newState = [...prevState, { id: singlePlatForm[0]?.id, icon: singlePlatForm[0]?.icon }]
                return newState
            })

        } else {


        }

    }

    const updateWidgetInfirestore = async (props) => {


        if (inputField.username === "") {

            setinputField((prevState) => { return ({ ...prevState, usernameValidation: 'error' }) })

        }else if(platformId==="whatsapp" ){

                if(selectedCountryCode===""){
                      setcountryInputError("error")
                }else{

                   

                    const updatedPlatform = [...platform].map((row, index) => {

                        if (row.id === platformId ) {

                             
                           


                            if(row?.platformType==="custom"){

                               
                                return ({ ...row, value: inputField.username, label: inputField?.label,link:inputField.username,countrycode:selectedCountryCode })
                            }else{
                               // console.log("insideelse",inputField.username,inputField?.label || '',selectedCountryCode)


                                return ({ ...row, value: inputField.username, label: inputField?.label || '',countrycode:selectedCountryCode })
                            }
        
                           
                        } else {
                            return ({ ...row })
                        }
                    })

                    console.log("updatedPlatform",updatedPlatform)
        
                    const userRef = doc(db, 'users', firebaseUser.uid);
                    const updateResponse = await updateDoc(userRef, { platforms: updatedPlatform })
                    dispatch(profilereloadTrigger({
                        isprofRedload: true
                    }))
                    closeDialog("")

                       
                }
 


        } else {

            const updatedPlatform = [...platform].map((row, index) => {

                if (row.id === platformId) {

                    if (row?.platformType === "custom") {
                        return ({ ...row, value: inputField.username, label: inputField.label, link: inputField.username })
                    } else {


                        return ({ ...row, value: inputField.username, label: inputField.label || '' })
                    }


                } else {
                    return ({ ...row })
                }
            })
            console.log("updatedPlatform",updatedPlatform)
            const userRef = doc(db, 'users', firebaseUser.uid);
            const updateResponse = await updateDoc(userRef, { platforms: updatedPlatform })
            dispatch(profilereloadTrigger({
                isprofRedload: true
            }))
            closeDialog("")

        }


    }

    React.useEffect(() => {

        if (platformWidgetopenClose) {

            getUserPlatforms(firebaseUser?.uid, platformId)
        }

    }, [platformWidgetopenClose])


    return (
        <>
            <Default>
                <Dialog
                    isOpen={platformWidgetopenClose}
                    onDismiss={() => {
                        closeDialog("")
                    }}
                    sx={{
                        width: '569px',
                        height: '330px',
                        background: '#ffffff',
                        borderRadius: '14px',
                        border: '1px solid #D1D5DB'
                    }}
                >

                    <Box sx={{
                        position: 'absolute',
                        top: '40px',
                        left: '0px',
                        right: '0px',
                        margin: 'auto',
                        minHeight: 'fit-content',
                        width: 'fit-content',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '27px'
                    }}>

                        <Box sx={{
                            minHeight: 'fit-content',
                            width: 'fit-content',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                        }}>

                            <Box sx={{
                                minHeight: 'fit-content',
                                width: 'fit-content',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                            }}>

                                <Text className={style.H7}>Edit Platform Widget</Text>
                                <Text className={style.p4}>Platform
                                
                                </Text>
                            </Box>

                            {
                                iconArray.filter((data) => data.id === platformId)[0]?.icon !== ""
                                    ?
                                    <img src={iconArray.filter((data) => data.id === platformId)[0]?.icon} style={{ width: '32px', height: '32px',objectFit:'cover' }} />
                                    :
                                    <Box sx={{
                                        height: '32px',
                                        width: '32px',
                                        borderRadius: '50%',
                                        background: '#A1A1AA',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>

                                        <Text className={style.p5} sx={{ textAlign: 'center' }} >{(inputField?.label?.substring(0, 1)?.toUpperCase())}</Text>
                                    </Box>

                            }
                               
                            
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '13px' }}>


                            {platformId === "whatsapp" ?
                                <Select 
                                    validationStatus={countryInputError}
                                    contrast
                                    className={style.p4}
                                    sx={inputSelectStyle}
                                    onChange={(e) => {
                                        setcountryInputError("")
                                        setselectedCountryCode(e.target.value)
                                    }}
                                    defaultValue={selectedCountryCode}
                                    value={selectedCountryCode}
                                    placeholder={selectedCountryCode}
                                >
                                    <Select.Option value={selectedCountryCode} selected>{selectedCountryCode}</Select.Option>
                                    {countries.map((row, index) => {
                                        return (
                                            <Select.Option value={row.code}>{row.code+"-"}{row.name}</Select.Option>
                                        )
                                    })}

                                </Select>

                                : ""}
                            

                            <TextInput
                                contrast
                                className={style.p4}
                                sx={platformId==="whatsapp"? inputStyleWhatsapp :inputStyle}
                                placeholder="username"
                                value={inputField.username}
                                onChange={(e) => {
                                    handleChangeInputField("username", e.target.value)
                                }}

                            >
                            </TextInput>
                            <TextInput
                                contrast
                                className={style.p4}
                                sx={platformId==="whatsapp"? inputStyleWhatsapp :inputStyle}
                                placeholder="Label (optional)"
                                value={inputField.label}
                                onChange={(e) => {
                                    handleChangeInputField("label", e.target.value)
                                }}
                            >
                            </TextInput>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '20px',
                            justifyContent: 'center'
                        }}>
                            <Button
                                variant="invisible"
                                className={style.p5}
                                sx={NextBtnStyle}
                                onClick={() => {
                                    updateWidgetInfirestore("")

                                }}
                            >
                                Edit widget
                            </Button>

                        </Box>




                    </Box>


                </Dialog>
            </Default>


            {/* Mobile responsive ------- */}
            <Mobile>
                <Dialog
                    isOpen={platformWidgetopenClose}
                    onDismiss={() => {
                        closeDialog("")
                    }}
                    sx={{
                        width: '90vw',
                        minWidth: '90vw',
                        maxWidth: '90vw',
                        top: '20px',
                        height: 'auto',
                        minHeight: 'auto',
                        maxHeight:platformId==="whatsapp"?'auto':'400px',
                        background: '#ffffff',
                        borderRadius: '14px',
                        border: '1px solid #D1D5DB'
                    }}
                    style={{ borderRadius: '14px' }}
                >

                    <Box sx={{
                        position: 'absolute',
                        top: '40px',
                        left: '0px',
                        right: '0px',
                        margin: 'auto',
                        minHeight: 'fit-content',
                        width: 'fit-content',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '27px',
                    }}>

                        <Box sx={{
                            minHeight: 'fit-content',
                            width: 'fit-content',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                        }}>

                            <Box sx={{
                                minHeight: 'fit-content',
                                width: 'fit-content',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                            }}>

                                <Text className={style.H7}>Edit Platform Widget</Text>
                                <Text className={style.p4}>Platform</Text>
                            </Box>

                            {
                                iconArray.filter((data) => data.id === platformId)[0]?.icon !== ""
                                    ?
                                    <img src={iconArray.filter((data) => data.id === platformId)[0]?.icon} style={{ width: '32px', height: '32px', objectFit: 'cover' }} />
                                    :
                                    <Box sx={{
                                        height: '32px',
                                        width: '32px',
                                        borderRadius: '50%',
                                        background: '#A1A1AA',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>

                                        <Text className={style.p5} sx={{ textAlign: 'center' }} >{(inputField?.label?.substring(0, 1)?.toUpperCase())}</Text>
                                    </Box>

                            }

                        </Box>

                        {platformId === "whatsapp" ?
                            <Select contrast
                                validationStatus={countryInputError}
                                className={style.p4}
                                sx={inputSelectStyle}
                                onChange={(e) => {
                                    setcountryInputError("")
                                    setselectedCountryCode(e.target.value)
                                }}
                                defaultValue={selectedCountryCode}
                                value={selectedCountryCode}
                                placeholder={selectedCountryCode}
                            >
                                <Select.Option value={selectedCountryCode} selected>{selectedCountryCode}</Select.Option>
                                {countries.map((row, index) => {
                                    return (
                                        <Select.Option value={row.code}>{row.code + "-"}{row.name}</Select.Option>
                                    )
                                })}

                            </Select>

                            : ""}

                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '13px' }}>

                             <Box sx={{display:'flex',flexDirection:'row'}}>
                               
                                    <TextInput
                                contrast
                                className={style.p4}
                                sx={inputStyleMobile}
                                placeholder="username"
                                value={inputField.username}
                                onChange={(e) => {
                                    handleChangeInputField("username", e.target.value)
                                }}

                            >
                            </TextInput>
                             </Box>

                            
                            <TextInput
                                contrast
                                className={style.p4}
                                sx={inputStyleMobile}
                                placeholder="Label (optional)"
                                value={inputField.label}
                                onChange={(e) => {
                                    handleChangeInputField("label", e.target.value)
                                }}
                            >
                            </TextInput>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '20px',
                            justifyContent: 'center'
                        }}>
                            <Button
                                variant="invisible"
                                className={style.p5}
                                sx={NextBtnStyle}
                                onClick={() => {
                                    updateWidgetInfirestore("")

                                }}
                            >
                                Edit widget
                            </Button>

                        </Box>




                    </Box>


                </Dialog>

            </Mobile>

        </>
    )
}

