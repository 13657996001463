import { Box, Button, Text } from "@primer/react"
import logo from './assets/weblogo.svg'
import style from './web.module.scss'
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from 'react-responsive'
import menuIcon from './assets/menuIcon.svg'
import React from "react"
import { useDispatch } from "react-redux"
import { openCloseMobileNav } from "../redux/features/webSlice"
import posthog from "posthog-js";
posthog.init('phc_mGFMcRORH045UBsthDPi8bGDGMAPCvc7qtACPde72Hh', { api_host: 'https://app.posthog.com' })
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1224 })
    return isDesktop ? children : null
}

const TabletPortrait = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991, orientation: 'portrait' })
    return isTablet ? children : null
}
const TabletLandscape = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 992, maxWidth: 1223, orientation: 'landscape' })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}
const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
}

export default function Header() {

    const navigate = useNavigate()
    const dispatch = useDispatch()


    const handleopenMobileSideNav = (props) => {

        dispatch(openCloseMobileNav({
            isOpenSideNav: true
        }))
    }

    const loginClickEvent = (props) => {

        posthog.capture(
            'LoginClickLanding',
            {
                distinct_id: 'LoginClickLanding',
                timestamp: new Date().toISOString(),
            }
        )

    }

    const signUpClickEvent = (props) => {

        posthog.capture(
            'SignupClickLanding',
            {
                distinct_id: 'SignupClickLanding',
                timestamp: new Date().toISOString(),
            }
        )

    }

    return (<>
        <Desktop>
            <Box
                sx={{
                    width: '1280px',
                    minWidth: '1280px',
                    maxWidth: '1280px',
                    minHeight: '76px',
                    maxHeight: '76px',
                    height: '76px',
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',

                }}
            >
                <img src={logo} style={{ width: '236px', height: '45px', cursor: 'pointer' }} onClick={() => { navigate('/') }}></img>
                <Box sx={{
                    position: 'absolute',
                    right: '16px',
                    height: '44px',
                    minHeight: '44px',
                    maxHeight: '44px',
                    width: 'fit-content',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '40px'
                }}>

                    <Text className={style.linkHeader} onClick={() => navigate('/pricing')} >Pricing</Text>
                    <Text className={style.linkHeader} onClick={() => {

                        loginClickEvent("")
                        navigate('/login')

                    }
                    } >Log in</Text>

                    <Button
                        className={style.btnStyle}
                        variant="invisible"
                        sx={{
                            minWidth: '137px',
                            minHeight: '44px',
                            maxHeight: '44px',
                            background: style.btnbackgroundColor,
                            color: style.whitecolor,
                            borderRadius: '9999px',
                            '&:hover': {
                                background: style.blackColor,
                                backgroundColor: style.blackColor

                            }
                        }}
                        onClick={() => {
                            signUpClickEvent("")
                            navigate('/signup')
                        }}
                    >Try for free</Button>
                </Box>

            </Box>
        </Desktop>
        <Mobile>
            <Box
                sx={{
                    width: '98%',
                    minWidth: '98%',
                    maxWidth: '98%',
                    minHeight: '76px',
                    maxHeight: '76px',
                    height: '76px',
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',

                }}
            >
                <img src={logo} style={{ width: '158px', height: '30px', cursor: 'pointer' }} onClick={() => { navigate('/') }} ></img>
                <img src={menuIcon} style={{ width: '32px', height: '32px', cursor: 'pointer', position: 'absolute', right: '20px' }} onClick={() => handleopenMobileSideNav("")} />


                {/* <Box sx={{
                      zIndex:'1000',
                      position: 'absolute',
                      right: '0px',
                      display:  'flex',
                      height: '180vh',
                      width: '70vw',
                      minWidth:'70vw',
                      maxWidth:'70vw',
                      minHeight: '100vh',
                      maxHeight: '100vh',
                      background: 'white',
                      flexDirection:'column',
                      paddingLeft:'1rem',
                      paddingRight:'2rem',
                      paddingTop:'3rem',
                      gap:'20px'
                  }}>

                      

                      <Text className={style.linkHeader} onClick={() => navigate('/pricing')} >Pricing</Text>
                      <Text className={style.linkHeader} onClick={() => navigate('/login')} >Log in</Text>
                      <Button
                          className={style.btnStyle}
                          variant="invisible"
                          sx={{
                              minWidth: '244px',
                              minHeight: '44px',
                              maxHeight: '44px',
                              background: style.btnbackgroundColor,
                              color: style.whitecolor,
                              borderRadius: '9999px',
                              '&:hover': {
                                  background: style.blackColor,
                                  backgroundColor: style.blackColor

                              }
                          }}
                          onClick={() => {
                              navigate('/signup')
                          }}
                      >Try for free</Button>

                  </Box> */}



                {/* <Box sx={{
                      position: 'absolute',
                      right: '16px',
                      height: '44px',
                      minHeight: '44px',
                      maxHeight: '44px',
                      width: 'fit-content',
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '20px'
                  }}>
                      <Text className={style.linkHeader} onClick={() => navigate('/login')} >Log in</Text>

                      <Button
                          className={style.btnStyle}
                          variant="invisible"
                          sx={{
                              minWidth: '106px',
                              minHeight: '35px',
                              maxHeight: '35px',
                              background: style.btnbackgroundColor,
                              color: style.whitecolor,
                              borderRadius: '9999px',
                              '&:hover': {
                                  background: style.blackColor,
                                  backgroundColor: style.blackColor

                              }
                          }}
                          onClick={() => {
                              navigate('/signup')
                          }}
                      >Try for free</Button>
                  </Box> */}

            </Box>





        </Mobile>

        <TabletPortrait>
            <Box
                sx={{
                    width: '778px',
                    minWidth: '778px',
                    maxWidth: '778px',
                    minHeight: '76px',
                    maxHeight: '76px',
                    height: '76px',
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',

                }}
            >
                <img src={logo} style={{ width: '236px', height: '45px', cursor: 'pointer' }} onClick={() => { navigate('/') }}></img>
                <Box sx={{
                    position: 'absolute',
                    right: '16px',
                    height: '44px',
                    minHeight: '44px',
                    maxHeight: '44px',
                    width: 'fit-content',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '40px'
                }}>
                    <Text className={style.linkHeader} onClick={() => navigate('/pricing')} >Pricing</Text>
                    <Text className={style.linkHeader} onClick={() => {
                        loginClickEvent("")
                        navigate('/login')

                    }

                    } >Log in</Text>

                    <Button
                        className={style.btnStyle}
                        variant="invisible"
                        sx={{
                            minWidth: '137px',
                            minHeight: '44px',
                            maxHeight: '44px',
                            background: style.btnbackgroundColor,
                            color: style.whitecolor,
                            borderRadius: '9999px',
                            '&:hover': {
                                background: style.blackColor,
                                backgroundColor: style.blackColor

                            }
                        }}
                        onClick={() => {
                            signUpClickEvent("")
                            navigate('/signup')
                        }}
                    >Try for free</Button>
                </Box>

            </Box>
        </TabletPortrait>

        <TabletLandscape>

            <Box
                sx={{
                    width: '978px',
                    minWidth: '978px',
                    maxWidth: '978px',
                    minHeight: '76px',
                    maxHeight: '76px',
                    height: '76px',
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',

                }}
            >
                <img src={logo} style={{ width: '236px', height: '45px', cursor: 'pointer' }} onClick={() => { navigate('/') }}></img>
                <Box sx={{
                    position: 'absolute',
                    right: '16px',
                    height: '44px',
                    minHeight: '44px',
                    maxHeight: '44px',
                    width: 'fit-content',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '40px'
                }}>
                    <Text className={style.linkHeader} onClick={() => navigate('/pricing')} >Pricing</Text>
                    <Text className={style.linkHeader} onClick={() => {
                        loginClickEvent("")
                        navigate('/login')
                    }


                    } >Log in</Text>

                    <Button
                        className={style.btnStyle}
                        variant="invisible"
                        sx={{
                            minWidth: '137px',
                            minHeight: '44px',
                            maxHeight: '44px',
                            background: style.btnbackgroundColor,
                            color: style.whitecolor,
                            borderRadius: '9999px',
                            '&:hover': {
                                background: style.blackColor,
                                backgroundColor: style.blackColor

                            }
                        }}
                        onClick={() => {

                            signUpClickEvent("")
                            navigate('/signup')
                        }}
                    >Try for free</Button>
                </Box>

            </Box>

        </TabletLandscape>



    </>)
}