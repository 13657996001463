import { Box, Dialog, Text, TextInput, Button, IconButton, Tooltip } from "@primer/react"
import React from "react"
import style from '../../global.module.scss'
import { useMediaQuery } from 'react-responsive'
import twitter from '../../assets/socialIcons/twitter.svg'
import instagram from '../../assets/socialIcons/Instagram.svg'
import linkedIn from '../../assets/socialIcons/linkedIn.svg'
import youtube from '../../assets/socialIcons/youtube.svg'
import spotify from '../../assets/socialIcons/spotify.svg'
import marxiix from '../../assets/socialIcons/marxiix.svg'
import thread from '../../assets/socialIcons/thread.svg'
import discord from '../../assets/socialIcons/discord.svg'
import github from '../../assets/socialIcons/github.svg'
import gumroad from '../../assets/socialIcons/gumroad.svg'
import medium from '../../assets/socialIcons/medium.svg'
import figma from '../../assets/socialIcons/figma.svg'
import drible from '../../assets/socialIcons/drible.svg'
import sms from '../../assets/socialIcons/sms.svg'
import behance from '../../assets/socialIcons/behance.svg'
import whatsapp from '../../assets/socialIcons/whatsapp.svg'
import clubhouse from '../../assets/socialIcons/clubhouse.svg'
import mastodon from '../../assets/socialIcons/mastodon.svg'
import tiktok from '../../assets/socialIcons/tiktok.svg'
import producthunt from '../../assets/socialIcons/producthunt.svg'
import coffe from '../../assets/socialIcons/coffe.svg'
import { useDispatch, useSelector } from "react-redux"
import { editPlatformOpenClose, profilereloadTrigger } from "../redux/features/dashboardSlice"
import { XIcon } from "@primer/octicons-react"
import { db } from "../../firebase"
import { doc, getDoc, updateDoc } from "firebase/firestore"

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}
const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
}

const iconArray = [
    { id: 'twitter', icon: twitter },
    { id: 'instagram', icon: instagram },
    { id: 'linkedIn', icon: linkedIn },
    { id: 'youtube', icon: youtube },
    { id: 'spotify', icon: spotify },
    { id: 'marxiix', icon: marxiix },
    { id: 'thread', icon: thread },
    { id: 'discord', icon: discord },
    { id: 'github', icon: github },
    { id: 'gumroad', icon: gumroad },
    { id: 'medium', icon: medium },
    { id: 'figma', icon: figma },
    { id: 'drible', icon: drible },
    { id: 'sms', icon: sms },
    { id: 'behance', icon: behance },
    { id: 'whatsapp', icon: whatsapp },
    { id: 'clubhouse', icon: clubhouse },
    { id: 'mastodon', icon: mastodon },
    { id: 'tiktok', icon: tiktok },
    { id: 'producthunt', icon: producthunt },
    { id: 'coffe', icon: coffe }
]

const allPlatforms = [
    {id:'twitter',icon:twitter,symbol:'@',placeholder:'username',value:'',position:0},
    {id:'instagram',icon:instagram,symbol:'@',placeholder:'username',value:'',position:1},
    {id:'linkedIn',icon:linkedIn,symbol:'@',placeholder:'username',value:'',position:2},
    {id:'youtube',icon:youtube,symbol:'@',placeholder:'username',value:'',position:3},
    {id:'spotify',icon:spotify,symbol:'@',placeholder:'username',value:'',position:4},
    {id:'marxiix',icon:marxiix,symbol:'@',placeholder:'username',value:'',position:5},
    {id:'thread',icon:thread,symbol:'@',placeholder:'username',value:'',position:6},
    {id:'discord',icon:discord,symbol:'@',placeholder:'username',value:'',position:7},
    {id:'github',icon:github,symbol:'@',placeholder:'username',value:'',position:8},
    {id:'gumroad',icon:gumroad,symbol:'@',placeholder:'username',value:'',position:9},
    {id:'medium',icon:medium,symbol:'@',placeholder:'username',value:'',position:10},
    {id:'figma',icon:figma,symbol:'@',placeholder:'username',value:'',position:11},
    {id:'drible',icon:drible,symbol:'@',placeholder:'username',value:'',position:12},
    {id:'sms',icon:sms,symbol:'@',placeholder:'username',value:'',position:13},
    {id:'behance',icon:behance,symbol:'@',placeholder:'username',value:'',position:14},
    {id:'whatsapp',icon:whatsapp,symbol:'@',placeholder:'username',value:'',position:15},
    {id:'clubhouse',icon:clubhouse,symbol:'@',placeholder:'username',value:'',position:16},
    {id:'mastodon',icon:mastodon,symbol:'@',placeholder:'username',value:'',position:17},
    {id:'tiktok',icon:tiktok,symbol:'@',placeholder:'username',value:'',position:18},
    {id:'producthunt',icon:producthunt,symbol:'@',placeholder:'username',value:'',position:19},
    {id:'coffe',icon:coffe,symbol:'@',placeholder:'username',value:'',position:20}
]

 

const contentBox = {

    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    margin: 'auto',
    width: ['80%', '601px'],
    borderRadius: '14px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '30px',
    paddingTop: '40px',
    paddingBottom: '40px',
    background: '#FFFFFF',
    zIndex: '1'
}

const contentBoxChildBox = {
    display: 'flex',
    flexDirection: 'column',
    width: ['90%', '426px'],
    height: 'fit-content',
    gap: '10px',
}

const NextBtnStyle = {

    minWidth: '120px',
    minHeight: '48px',
    maxHeight: '48px',
    background: '#000000',
    color: '#FFFFFF',
    borderRadius: '12px',
    '&:hover': {
        background: '#000000',
        backgroundColor: '#000000'

    }
}

const LaterBtStyle = {
    minWidth: '120px',
    minHeight: '48px',
    maxHeight: '48px',
    background: '#F4F4F5',
    borderRadius: '12px',
    border: 'none'
}

const platformBoxGrid = {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'repeat(7, auto)',
    columnGap: '25px',
    rowGap: '20px',
    minHeight: '260px'
}

const platformBoxGridContainer = {

    width: '165px',
    height: '48px',
    border: '2px solid #E4E4E7',
    borderRadius: '8px',
    background: '#F4F4F5',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textInput: {
        border: 'none',
        background: 'transparent',
        height: '48px'
    }
    ,
    textInputTrailing: {

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '11px'
    }
}

//------------ mobile responsive -----------

const contentBoxMobile = {

    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    margin: 'auto',
    width: ['100%', '90vw'],
    borderRadius: '14px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '30px',
    paddingTop: '40px',
    paddingBottom: '40px',
    background: '#FFFFFF',
    zIndex: '1',
    maxHeight:'90vh',
    overflowY:'auto' 
}

const contentBoxChildBoxMob = {
    display: 'flex',
    flexDirection: 'column',
    width: ['90%', '426px'],
    height: 'fit-content',
    gap: '10px',
}

const platformBoxGridMobile = {


    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    columnGap: '25px',
    rowGap: '20px',
    minHeight: 'auto'


}

const platformBoxGridContainerMobile = {

    width: '80vw',
    height: '48px',
    border: '2px solid #E4E4E7',
    borderRadius: '8px',
    background: '#F4F4F5',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textInput: {
        border: 'none',
        background: 'transparent',
        height: '48px'
    }
    ,
    textInputTrailing: {

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '11px'
    }
}


//-------------------------------------------

export default function PlatFormEditDialog(props) {

    const dispatch = useDispatch()
    const firebaseUser = useSelector((state) => state.firebaseStore.firebaseUser)
    const platformDialogOpenClose = useSelector((state) => state.dasboardStore.openClose)

    const [platforms, setplatforms] = React.useState([])
    const [copyplatform,setcopyplatform] = React.useState([])


    const handleChangeUsernameInput = (props, index) => {

        setplatforms((prevState) => {
            const newState = prevState.map((newrow, newindex) => {
                if (newrow.position === index) {

                    if (newrow.id === "whatsapp") {

                        if (!isNaN(props)) {
                            return ({ ...newrow, value: props,countrycode:'' })
                        } else {
                            return ({ ...newrow, value: '' })
                        }

                    } else {

                        return ({ ...newrow, value: props })
                    }

                    
                } else {
                    
                    
                    return ({ ...newrow })
                }
            })
            return newState
        })
    }

    const closeEditDialog = (props) => {
        dispatch(editPlatformOpenClose({
            openClose: false
        }))
    }

    function dataExistsInArray(data, array) {
        // Iterate through the array to check if data exists
        for (let i = 0; i < array.length; i++) {
            // Assuming 'id' is the unique identifier property
            if (data.id === array[i].id) {
                // Data exists in the array
                return true;
            }
        }
        // Data doesn't exist in the array
        return false;
    }

 
    const getallPlatforms = async (props) => {


        const userRef = doc(db, 'users', props);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {

            const responseData = docSnap.data()
            const platformArray = [...responseData.platforms]
            // const updatedArray = [...platformArray]
            //     .filter((row) => row?.value !== "" && row?.id !== "profile")
            //     .map((row, index) => {

            //         return ({ ...row, position: index })
            //     })
            //setplatforms(updatedArray)

            
            setcopyplatform(platformArray)
            const newupdatedArray = allPlatforms.filter(obj => {
                if (dataExistsInArray(obj, platformArray)) {

                } else {
                    return ({ ...obj })
                }
            }).map((row,index)=>{
                  return({...row,position:index})
            })
            
            
            console.log("newupdatedArray", newupdatedArray)
            setplatforms(newupdatedArray) 

 
        } else {


        }

    }

    const updatallPlatforms = async (props) => {

        const updateArray = [...copyplatform,...platforms.filter((pData)=> pData.value!=="")].map((row,index)=>{
              return({...row,position:index})
        })
       
        console.log("updateArray",updateArray)
        if (updateArray.length > 0) {

            

              
            const userRef = doc(db, 'users', firebaseUser?.uid);
            const updateResponse = await updateDoc(userRef, { platforms: updateArray })

            dispatch(profilereloadTrigger({
                isprofRedload: true
            }))
            closeEditDialog("")


        } else {

            alert('no platform yet')
        }


    }


    React.useEffect(() => {

        if (platformDialogOpenClose) {
            getallPlatforms(firebaseUser?.uid)
        }

    }, [platformDialogOpenClose])



    return (
        <>

            <Default>
                <Dialog
                    isOpen={platformDialogOpenClose}
                    onDismiss={() => {
                        closeEditDialog("")
                    }}
                    sx={{
                        width: '601px',
                        // height: '645px',
                        minHeight: 'calc(79%)',
                        borderRadius: '14px',
                        background: '#ffffff',
                        border: '1px solid #D1D5DB',
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}>

                    <Box sx={contentBox}>

                        <IconButton
                            sx={{
                                position: 'absolute',
                                right: '20px',
                                top: '20px',
                                background: 'transparent',
                                border: 'none'
                            }} icon={XIcon}
                            onClick={() => {
                                closeEditDialog("")
                            }}
                        />


                        <Box sx={contentBoxChildBox}>
                            <Text className={style.H7} sx={{ textAlign: 'center' }} >Let's add up to {platforms.length} of your favorite platforms</Text>
                            <Text className={style.p4} sx={{ textAlign: 'center' }}>Make your platforms shine in your bio!</Text>
                        </Box>
                        {/* platform layout------ */}

                        <Box sx={platformBoxGrid}>

                            {platforms.map((row, index) => {

                                return (
                                    <>
                                        <Box sx={platformBoxGridContainer}>
                                        <Tooltip aria-label={
                                            
                                             row?.id==="sms"?"mail":
                                             row?.id==="coffe"?"Bymeacoffe":
                                             row?.id
                                             
                                            
                                            }>
                                            <TextInput
                                                className={style.p4}
                                                leadingVisual={() => <Box
                                                    sx={platformBoxGridContainer.textInputTrailing}>
                                                   
                                                        <img
                                                            style={{ height: '20px', width: '20px' }}
                                                            // src={iconArray.filter((data)=> data.id===row.id)[0]?.icon}
                                                            src={row?.icon}
                                                        ></img>@
                                                    
                                                    
                                                </Box>}
                                                sx={platformBoxGridContainer.textInput}
                                                placeholder="username"
                                                value={row.value}
                                                onChange={(e) => {
                                                    handleChangeUsernameInput(e.target.value, index)
                                                }}
                                            >
                                            </TextInput>
                                          </Tooltip>
                                        </Box>
                                    </>
                                )
                            })}


                        </Box>


                        {/* --------------------- */}


                        <Box sx={{
                            display: platforms.length > 0 ? 'flex' : 'none',
                            flexDirection: 'row',
                            gap: '20px'
                        }}>
                            <Button

                                variant="invisible"
                                className={style.p5}
                                sx={NextBtnStyle}
                                onClick={() => {
                                    updatallPlatforms("")
                                }}
                            >
                                Save
                            </Button>

                        </Box>

                    </Box>

                </Dialog>
            </Default>

            {/* ----- Mobile ------- */}


            <Mobile>
                <Dialog
                    isOpen={platformDialogOpenClose}
                    onDismiss={() => {
                        closeEditDialog("")
                    }}
                    sx={{
                        // display:'flex',
                        // flexDirection:'column',
                        // top: '20px',
                        // width: '90vw',
                        // minWidth: '90vw',
                        // maxWidth: '90vw',
                        // height: 'auto',
                        // minHeight: 'auto',
                        // maxHeight:'calc(90vh - 50px)',
                        // borderRadius: '14px',
                        // border: '1px solid #D1D5DB',
                        // overflowY: 'auto',
                        // overflowX: 'hidden',

                        width: '90vw',
                        minWidth: '90vw',
                        maxWidth: '90vw',
                        top: '20px',
                        height: 'fit-content',
                        background: 'transparent'

                    }}>


                    <Box sx={contentBoxMobile}>

                        <IconButton
                            sx={{
                                position: 'absolute',
                                right: '20px',
                                top: '20px',
                                background: 'transparent',
                                border: 'none'
                            }} icon={XIcon}
                            onClick={() => {
                                closeEditDialog("")
                            }}
                        />


                        <Box sx={contentBoxChildBoxMob}>
                            <Text className={style.H7} sx={{ textAlign: 'center' }} >Let's add up to 14 of your favorite platforms</Text>
                            <Text className={style.p4} sx={{ textAlign: 'center' }}>Make your platforms shine in your bio!</Text>
                        </Box>
                        {/* platform layout------ */}

                        <Box sx={platformBoxGridMobile}>

                            {platforms.map((row, index) => {

                                return (
                                    <>
                                    <Tooltip aria-label={
                                            
                                            row?.id==="sms"?"mail":
                                            row?.id==="coffe"?"Bymeacoffe":
                                            row?.id
                                            
                                           
                                           }>
                                        <Box sx={platformBoxGridContainerMobile}>

                                            <TextInput
                                                className={style.p4}
                                                leadingVisual={() => <Box
                                                    sx={platformBoxGridContainerMobile.textInputTrailing}>
                                                    <img
                                                        style={{ height: '20px', width: '20px' }}
                                                        src={row.icon}
                                                    // src={iconArray.filter((data) => data.id === row.id)[0]?.icon}
                                                    ></img>@
                                                </Box>}
                                                sx={platformBoxGridContainerMobile.textInput}
                                                placeholder="username"
                                                value={row.value}
                                                onChange={(e) => {
                                                    handleChangeUsernameInput(e.target.value, index)
                                                }}
                                            >
                                            </TextInput>

                                        </Box>
                                        </Tooltip>
                                    </>
                                )
                            })}




                        </Box>


                        {/* --------------------- */}


                        <Box sx={{

                            display: platforms.length > 0 ? 'flex' : 'none',
                            flexDirection: 'row',
                            gap: '20px'
                        }}>
                            <Button

                                variant="invisible"
                                className={style.p5}
                                sx={NextBtnStyle}
                                onClick={() => {
                                    updatallPlatforms("")
                                }}
                            >
                                Save
                            </Button>

                        </Box>

                    </Box>
                </Dialog>
            </Mobile>

        </>
    )
}