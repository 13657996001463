import React from "react";
import { Box, Button, FormControl,Text, TextInput } from "@primer/react";
import style from '../global.module.scss'
import { useMediaQuery } from 'react-responsive'
import rightShape from '../assets/rightshape.svg'
import leftShape from '../assets/leftshape.svg'
import { db, auth } from '../firebase';
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { AlertIcon, CheckCircleIcon } from "@primer/octicons-react";
import posthog from 'posthog-js'
import mixpanel from 'mixpanel-browser';
mixpanel.init('7a2c125cf3bf07b7fef81e91d0190b14');
posthog.init('phc_mGFMcRORH045UBsthDPi8bGDGMAPCvc7qtACPde72Hh', { api_host: 'https://app.posthog.com' })

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}
const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
}
const parentBox = {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
    background: style.pageBg,

}

const contentBox = {

    position: 'absolute',
    top: '75px',
    left: '0px',
    right: '0px',
    margin: 'auto',
    width: ['80%', '550px'],
    borderRadius: '14px',
    border: '1px solid #D1D5DB',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '30px',
    paddingTop: '40px',
    paddingBottom: '40px',
    background: '#FFFFFF',
    zIndex: '1'
}

const contentBoxChildBox = {
    display: 'flex',
    flexDirection: 'column',
    width: ['90%', '350px'],
    height: 'fit-content',
    gap: '18px',
}

const contentBoxChildBoxInput = {
    display: 'flex',
    flexDirection: 'column',
    width: ['90%', '350px'],
    height: 'fit-content',
    gap: '13px'
}

const inputStyle = {
    minHeight: '48px',
    maxHeight: '48px',
    borderRadius: '8px',
    // border: '2px solid #E4E4E7',

}



const BtnStyle = {
    minWidth: '350px',
    minHeight: '48px',
    maxHeight: '48px',
    background: '#000000',
    color: '#FFFFFF',
    borderRadius: '12px',
    '&:hover': {
        background: '#000000',
        backgroundColor: '#000000'

    }

}

const svgBox = {
    position: 'absolute',
    top: '255px',
    margin: 'auto',
    width: '100vw',
    height: '340px',
}

const svgBoxChild = {
    position: 'absolute',
    top: '0px',
    right: '0px',
    width: '314px',
    height: '107px',
}

const svgBoxChild2 = {
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    width: '314px',
    height: '107px',
}

//----------- Mobile Responsive code ----------

const contentBoxMobile = {

    width: ['80%', '350px'],
    height: '270px',
    borderRadius: '14px',
    border: '1px solid #D1D5DB',
    position: 'absolute',
    top: '75px',
    left: '0px',
    right: '0px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '30px',
    paddingTop: '40px',
    paddingBottom: '40px',
    background: '#FFFFFF',
    zIndex: '1'
}

const contentBoxChildBoxInputMobile = {
    display: 'flex',
    flexDirection: 'column',
    width: ['90%', '350px'],
    height: 'fit-content',
    gap: '13px'

}

const BtnStyleMobile = {
    minWidth: '240px',
    minHeight: '48px',
    maxHeight: '48px',
    background: '#000000',
    color: '#FFFFFF',
    borderRadius: '12px',
    '&:hover': {
        background: '#000000',
        backgroundColor: '#000000'

    }

}








//-----------------------------------------------

export default function AccountSetup() {


    const navigate = useNavigate()
    const [user, setuser] = React.useState(null)
    const [username, setusername] = React.useState({
        validationStatus: '',
        value: ''
    })

    const getCurrentUser = (props) => {
        const user = auth.currentUser;

        if (user) {
            // User is signed in
            console.log('Current user:', user);
            return user;
        } else {
            // No user is signed in
            console.log('No user signed in.');

            return null;
        }
    };



    const handleChangeUsername = async (props) => {

        if (props.length == 0) {

            const regex = /^[a-zA-Z0-9]*$/;

            if (regex.test(props) || props === '') {
                setusername((prevStae) => {
                    return ({ ...prevStae, value: props, validationStatus: '' })
                })
            }




        } else {

            const regex = /^[a-zA-Z0-9]*$/;

            if (regex.test(props) || props === '') {

                setusername((prevStae) => {
                    return ({ ...prevStae, value: props })
                })

                const getUserRef = doc(db, 'usernames', props);
                const docSnap = await getDoc(getUserRef);

                if (docSnap.exists()) {

                    setusername((prevStae) => {
                        return ({ ...prevStae, validationStatus: 'error' })
                    })

                } else {


                    setusername((prevStae) => {
                        return ({ ...prevStae, validationStatus: 'success' })
                    })

                }


            }






        }



        //  if(props.length > 0){


        //     setusername((prevStae)=> {
        //         return({...prevStae,value:props})
        //     })

        //     const getUserRef = doc(db, 'usernames', props);
        //     const docSnap = await getDoc(getUserRef);
        //     if (docSnap.exists()) {

        //         setusername((prevStae)=> {
        //             return({...prevStae,validationStatus:'error'})
        //         })

        //     }else{
        //         setusername((prevStae)=> {
        //            return({...prevStae,validationStatus:'success'})
        //         })
        //     }

        //  }else if(props.length<=0){
        //      setusername((prevStae)=> {
        //         return({...prevStae,value:props,validationStatus:'scs'})
        //      })

        //  } 


    }


    const checkUsername = async (props) => {

        const response = await fetch('https://api.ipify.org?format=json');
        const ipdata = await response.json();

        if (username.value !== null && username.value !== "") {

            const getUserRef = doc(db, 'usernames', username.value);
            const docSnap = await getDoc(getUserRef);
            if (docSnap.exists()) {

                const responseData = docSnap.data()
                const userName = responseData?.username
                console.log("username already taken", userName)
                setusername((prevStae) => {
                    return ({ ...prevStae, validationStatus: 'error' })
                })



            } else {
                console.log("username valid");
                setusername((prevStae) => {
                    return ({ ...prevStae, validationStatus: 'success' })
                })

                //--------- update users collection --------------------

                const usersRef = doc(db, "users", user?.uid)
                await updateDoc(usersRef, { username: username.value })


                //----------add in username collection ------------------
                const usernamesRef = doc(db, 'usernames', username.value);

                const data = {
                    userId: user?.uid,
                    userEmail: user?.email,
                    timeStamp: new Date(),
                    capital: true,
                    merge: true,
                    username: username.value
                }

                await setDoc(usernamesRef, data)

                mixpanel.people.set({
                    user_id: user?.uid,
                    user_email: user?.email,
                    $name: username.value,
                    $email: user?.email,
                    user_name: username.value
                })

                mixpanel.track('Signup', {
                    $distinct_id: user?.uid,
                    $ip: ipdata?.ip,
                    $set: {
                        user_id: user?.uid,
                        user_email: user?.email,
                        $name: username.value,
                        $email: user?.email,
                        user_name: username.value
                    }

                })



                posthog.identify(user?.uid, { name: username.value, email: user?.email })

                posthog.capture(
                    'SignUp',
                    {
                        distinct_id: user?.uid,
                        $set: { name: username.value, email: user?.email, userId: user?.uid },
                        $set_once: { username: username.value },
                        user_id: user?.uid,
                        user_email: user?.email,
                        timestamp: new Date().toISOString(),
                    }
                )


                navigate('/profilesetup')

            }



        } else {

            setusername((prevStae) => {
                return ({ ...prevStae, validationStatus: 'error' })
            })




        }

    }



    React.useState(() => {

        setuser(getCurrentUser(""))
    })



    return (<>
        <Default >
            <Box sx={parentBox}>

                <Box sx={contentBox}>

                    <Box sx={contentBoxChildBox}>
                        <Text className={style.H7} sx={{ textAlign: 'center' }} >Claim your superrlink bio</Text>
                        <Text className={style.p4} sx={{ textAlign: 'center' }}>You can still get your hands on the top picks.</Text>
                    </Box>

                    <Box sx={contentBoxChildBoxInput}>

                        <TextInput
                            className={style.p4}
                            leadingVisual={() => <Box>superrlink.com/</Box>}
                            sx={inputStyle}
                            onChange={(e) => { handleChangeUsername(e.target.value.toLowerCase().trim()) }}
                            value={username.value}
                            validationStatus={username.validationStatus}
                            trailingVisual={() =>
                                username.validationStatus === "error" && username.value.length > 0 ?
                                    <FormControl>

                                        <FormControl.Caption sx={{ color: 'red' }}><AlertIcon size={12} /></FormControl.Caption>
                                    </FormControl>
                                    :
                                    username.validationStatus === "success" && username.value.length > 0 ?
                                        <FormControl>

                                            <FormControl.Caption sx={{ color: 'green' }}> <CheckCircleIcon size={12} /></FormControl.Caption>
                                        </FormControl>
                                        :
                                        ""
                            }
                        >


                        </TextInput>
                        {/* {username.validationStatus==="error" && username.value.length>0?
                              <FormControl>
                                 
                              <FormControl.Caption sx={{color:'red'}}><AlertIcon size={12} /> username already taken</FormControl.Caption>
                              </FormControl>
                             :
                               ""
                             } */}

                        {/* {username.validationStatus==="success" && username.value.length>0?
                              <FormControl>
                                
                              <FormControl.Caption sx={{color:'green'}}> <CheckCircleIcon size={12} /> username available</FormControl.Caption>
                              </FormControl>
                             :
                               ""
                             }
                            */}


                        <Button
                            disabled={username.validationStatus === "success" && username.value.length > 0 ? false : true}
                            variant="invisible"
                            className={style.p5}
                            sx={BtnStyle}
                            onClick={() => {
                                checkUsername(username)
                            }}
                        >
                            Continue
                        </Button>

                    </Box>
                </Box>

                <Box sx={svgBox}>
                    <Box sx={svgBoxChild}>
                        <img src={rightShape} />
                    </Box>

                    <Box sx={svgBoxChild2}>
                        <img style={{ position: 'absolute', left: '0px' }} src={rightShape} />
                        <img style={{ position: 'absolute', left: '21px', top: '-10px' }} src={leftShape} />
                    </Box>
                </Box>



            </Box>
        </Default>
        <Mobile>
            <Box sx={parentBox}>
                <Box sx={contentBoxMobile}>

                    <Box sx={contentBoxChildBox}>
                        <Text className={style.H7} sx={{ textAlign: 'center' }} >Claim your superrlink bio</Text>
                        <Text className={style.p4} sx={{ textAlign: 'center' }}>You can still get your hands on the top picks.</Text>
                    </Box>
                    <Box sx={contentBoxChildBoxInputMobile}>
                        <TextInput className={style.p4}
                            sx={inputStyle}
                            leadingVisual={() => <Box>superrlink.com/</Box>}
                            value={username.value}
                            validationStatus={username.validationStatus}
                            onChange={(e) => {
                                handleChangeUsername(e.target.value.toLowerCase().trim())
                            }}
                            trailingVisual={() =>
                                username.validationStatus === "error" && username.value.length > 0 ?
                                    <FormControl>

                                        <FormControl.Caption sx={{ color: 'red' }}><AlertIcon size={12} /></FormControl.Caption>
                                    </FormControl>
                                    :
                                    username.validationStatus === "success" && username.value.length > 0 ?
                                        <FormControl>

                                            <FormControl.Caption sx={{ color: 'green' }}> <CheckCircleIcon size={12} /></FormControl.Caption>
                                        </FormControl>
                                        :
                                        ""
                            }
                        ></TextInput>
                        {/* {username.validationStatus==="error" && username.value.length>0?
                              <FormControl>
                              <FormControl.Caption sx={{color:'red'}}><AlertIcon size={12} /> username already taken</FormControl.Caption>
                              </FormControl>
                             :
                               ""
                             }
                             {username.validationStatus==="success" && username.value.length>0?
                              <FormControl>
                                
                              <FormControl.Caption sx={{color:'green'}}> <CheckCircleIcon size={12} /> username available</FormControl.Caption>
                              </FormControl>
                             :
                               ""
                             } */}
                        <Button
                            disabled={username.validationStatus === "success" && username.value.length > 0 ? false : true}
                            variant="invisible"
                            className={style.p5}
                            sx={BtnStyleMobile}
                            onClick={() => {
                                checkUsername(username)
                            }}
                        >
                            Continue
                        </Button>

                    </Box>


                </Box>
            </Box>
        </Mobile>
    </>)


}