import React from "react";
import { Box, Button, Text } from "@primer/react";
import style from './web.module.scss'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import boxOneImg from './assets/boxoneimg.png'
import boxTwoImg from './assets/boxtwoimg.png'
import boxThreeImg from './assets/boxthreeimg.png'
import boxFourImg from './assets/boxfourimg.png'
import boxfiveImg from './assets/boxfiveimg.png'
import Footer from "./Footer";
import MobileSideNav from "./MobileSideNav";
import posthog from "posthog-js";
posthog.init('phc_mGFMcRORH045UBsthDPi8bGDGMAPCvc7qtACPde72Hh', { api_host: 'https://app.posthog.com' })
const homeVideo = "https://firebasestorage.googleapis.com/v0/b/superrlink-61f8e.appspot.com/o/web%2Fhomevideo.mp4?alt=media&token=f5346f71-088b-4eac-9cf9-3a858214bd12"

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1224 })
    return isDesktop ? children : null
}

const TabletPortrait = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991, orientation: 'portrait' })
    return isTablet ? children : null
}
const TabletLandscape = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 992, maxWidth: 1223, orientation: 'landscape' })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}
const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
}


export default function LandingPage() {

    const navigate = useNavigate()
    const videoRef = React.useRef(undefined);

    const parenBox = {
        position: 'relative',
        width: '100vw',
        minWidth: '100vw',
        maxWidth: '100vw',
        height: '100vh',
        minHeight: '100vh',
        maxHeight: '100vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: style.pageBg
    }

    const landindPageEvent = (props) => {

        posthog.capture(
            'LandingPage',
            {
                distinct_id: 'LandingPage',
                timestamp: new Date().toISOString(),
            }
        )

    } 

    React.useState(()=>{


           landindPageEvent("")

    })
  


    return (

        <>
            <Desktop>
                <Box sx={parenBox}>
                    {/* -------------header  */}
                    <Box position="fixed"
                        sx={{
                            width: '100vw',
                            top: '0',
                            left: '0',
                            height: '76px',
                            zIndex: '999', // Adjust z-index as needed
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: style.pageBg
                        }}>
                        <Header />
                    </Box>

                    {/*-------------------  */}


                    {/* section 1 ---------- */}

                    <Box sx={{
                        position: 'relative',
                        height: '520px',
                        minHeight: '520px',
                        width: '100vw',
                        minWidth: '100vw',
                        marginTop: '76px'
                    }}>

                        <Box sx={{
                            position: 'absolute',
                            top: '128px',
                            left: '0px',
                            right: '0px',
                            margin: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            height: 'fit-content',
                            width: 'fit-content',
                            alignItems: 'center',
                            zIndex: '1'
                        }}>
                            <Text className={style.homeHeadingprimary} sx={{ textAlign: 'center' }}>Your Bio, But With</Text>
                            <Text className={style.homeHeadingsecondary} sx={{ textAlign: 'center', marginTop: '-16px' }} >Superpowers</Text>
                            <Text className={style.p1} sx={{ textAlign: 'center', marginTop: '13px' }}>Empower your bio page with your owner style<br></br>
                                to show everything you are.</Text>
                            <Button

                                className={style.btnStyle}
                                variant="invisible"
                                sx={{
                                    marginTop: '24px',
                                    minWidth: '180px',
                                    maxWidth: '180px',
                                    width: '180px',
                                    minHeight: '56px',
                                    maxHeight: '56px',
                                    height: '56px',
                                    background: style.btnbackgroundColor,
                                    color: style.whitecolor,
                                    borderRadius: '9999px',
                                    '&:hover': {
                                        background: style.blackColor,
                                        backgroundColor: style.blackColor

                                    }
                                }}
                                onClick={()=>{
                                    navigate('/signup')
                                }}
                            >Try for free</Button>
                        </Box>

                        <Box
                            style={{
                                position: 'absolute',
                                top: '300px',
                                width: "100%",
                                height: "100%",
                                filter: "blur(100px) saturate(150%)",
                                opacity: ".15",
                                background: "radial-gradient(at 27% 37%,#cfe6f0 0,transparent 0),radial-gradient(at 97% 21%,#f845c6 0,transparent 50%),radial-gradient(at 52% 99%,#7957ff 0,transparent 50%),radial-gradient(at 10% 29%,#2466b8 0,transparent 50%),radial-gradient(at 97% 96%,#147787 0,transparent 50%),radial-gradient(at 33% 50%,#18b6aa 0,transparent 50%),radial-gradient(at 79% 53%,#c1cff2 0,transparent 50%)",
                            }}
                        ></Box>
                    </Box>


                    {/* section 2 ------ */}
                    <Box sx={{
                        position: 'relative',
                        height: '784px',
                        minHeight: '784px',
                        width: '100vw',
                        minWidth: '100vw',
                    }}>
                        <Box sx={{
                            minWidth: '1246px',
                            width: '1246px',
                            height: '784px',
                            minHeight: '784px',
                            margin: 'auto',
                            borderRadius: '24px'
                        }}>
                            <video
                                ref={videoRef}
                                autoPlay
                                playsInline
                                loop
                                muted
                                style={{ height: "100%", width: "100%", maxWidth: "100%", borderRadius: '24px' }}>
                                <source src={homeVideo} />
                            </video>
                        </Box>
                    </Box>
                    {/* ------------------------ */}

                    {/* section3 --------------- */}
                    <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        paddingTop: '97px',
                        paddingBottom: '96px'
                    }}>

                        <Box sx={{
                            width: '1280px',
                            minWidth: '1280px',
                            maxWidth: '1280px',
                            height: '1078px',
                            margin: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '34px',

                        }}>

                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '34px' }}>
                                <Box sx={{
                                    minWidth: '623px',
                                    width: '623px',
                                    maxWidth: '623px',
                                    height: '523px',
                                    minHeight: '523px',
                                    maxHeight: '523px',
                                    border: `1px solid ${style.borderColor}`,
                                    borderRadius: '24px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: '#ffffff'
                                }}>

                                    <Box sx={{

                                        minHeight: '288px',
                                        maxHeight: '288px',
                                        height: '288px',
                                        background: '#F8FAFC',
                                        borderTopLeftRadius: '24px',
                                        borderTopRightRadius: '24px'
                                    }}>
                                        <img style={{
                                            width: '100%',
                                            height: '288px',
                                            maxHeight: '288px',
                                            objectFit: 'cover'
                                        }} src={boxOneImg} />
                                    </Box>

                                    <Box sx={{
                                        height: '233px',
                                        minHeight: '233px',
                                        maxHeight: '233px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '65px 32px 54px 32px',
                                        gap: '16px'
                                    }}>
                                        <Text className={style.H2}>Share magic with our stunning widgets</Text>
                                        <Text className={style.p2}>Customize all widgets according to your style and create an amazing biography.
                                        </Text>
                                    </Box>


                                </Box>
                                <Box sx={{
                                    minWidth: '623px',
                                    width: '623px',
                                    maxWidth: '623px',
                                    height: '523px',
                                    minHeight: '523px',
                                    maxHeight: '523px',
                                    border: `1px solid ${style.borderColor}`,
                                    borderRadius: '24px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: '#ffffff'
                                }}>

                                    <Box sx={{

                                        minHeight: '288px',
                                        maxHeight: '288px',
                                        height: '288px',
                                        background: '#F8FAFC',
                                        borderTopLeftRadius: '24px',
                                        borderTopRightRadius: '24px'
                                    }}>
                                        <img style={{
                                            width: '100%',
                                            height: '288px',
                                            maxHeight: '288px',
                                            objectFit: 'cover'
                                        }} src={boxTwoImg} />
                                    </Box>

                                    <Box sx={{
                                        height: '233px',
                                        minHeight: '233px',
                                        maxHeight: '233px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '65px 32px 54px 32px',
                                        gap: '16px'
                                    }}>
                                        <Text className={style.H2}>Unwrap your style with the perfect variant</Text>
                                        <Text className={style.p2}>Increase your identity by choosing the variant that truly captures your essence!.</Text>
                                    </Box>


                                </Box>



                            </Box>


                            <Box sx={{ minHeight: '30px', display: 'flex', gap: '34px' }}>
                                <Box sx={{
                                    minWidth: '403px',
                                    width: '403px',
                                    maxWidth: '403px',
                                    height: '523px',
                                    minHeight: '523px',
                                    maxHeight: '523px',
                                    border: `1px solid ${style.borderColor}`,
                                    borderRadius: '24px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: '#ffffff'
                                }}>

                                    <Box sx={{

                                        minHeight: '288px',
                                        maxHeight: '288px',
                                        height: '288px',
                                        background: '#F8FAFC',
                                        borderTopLeftRadius: '24px',
                                        borderTopRightRadius: '24px'
                                    }}>
                                        <img style={{
                                            width: '100%',
                                            height: '288px',
                                            maxHeight: '288px',
                                            objectFit: 'cover'
                                        }} src={boxThreeImg} />
                                    </Box>

                                    <Box sx={{
                                        height: '233px',
                                        minHeight: '233px',
                                        maxHeight: '233px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '65px 32px 54px 32px',
                                        gap: '16px'
                                    }}>
                                        <Text className={style.H2}>Drag and Drop elegantly
                                        </Text>
                                        <Text className={style.p2}>Effortlessly drag and drop elements for  a seamless design experience.
                                        </Text>
                                    </Box>


                                </Box>

                                <Box sx={{
                                    minWidth: '403px',
                                    width: '403px',
                                    maxWidth: '403px',
                                    height: '523px',
                                    minHeight: '523px',
                                    maxHeight: '523px',
                                    border: `1px solid ${style.borderColor}`,
                                    borderRadius: '24px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: '#ffffff'
                                }}>

                                    <Box sx={{

                                        minHeight: '288px',
                                        maxHeight: '288px',
                                        height: '288px',
                                        background: '#F8FAFC',
                                        borderTopLeftRadius: '24px',
                                        borderTopRightRadius: '24px'
                                    }}>
                                        <img style={{
                                            width: '100%',
                                            height: '288px',
                                            maxHeight: '288px',
                                            objectFit: 'cover'
                                        }} src={boxFourImg} />
                                    </Box>

                                    <Box sx={{
                                        height: '233px',
                                        minHeight: '233px',
                                        maxHeight: '233px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '65px 32px 54px 32px',
                                        gap: '16px'
                                    }}>
                                        <Text className={style.H2}>Light / Dark amazing </Text>
                                        <Text className={style.p2}>Elevate your journey: simply switch modes for tailored delight.


                                        </Text>
                                    </Box>


                                </Box>

                                <Box sx={{
                                    minWidth: '403px',
                                    width: '403px',
                                    maxWidth: '403px',
                                    height: '523px',
                                    minHeight: '523px',
                                    maxHeight: '523px',
                                    border: `1px solid ${style.borderColor}`,
                                    borderRadius: '24px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: '#ffffff'
                                }}>

                                    <Box sx={{

                                        minHeight: '288px',
                                        maxHeight: '288px',
                                        height: '288px',
                                        background: '#F8FAFC',
                                        borderTopLeftRadius: '24px',
                                        borderTopRightRadius: '24px'
                                    }}>
                                        <img style={{
                                            width: '100%',
                                            height: '288px',
                                            maxHeight: '288px',
                                            objectFit: 'cover'
                                        }} src={boxfiveImg} />
                                    </Box>

                                    <Box sx={{
                                        height: '233px',
                                        minHeight: '233px',
                                        maxHeight: '233px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '65px 32px 54px 32px',
                                        gap: '16px'
                                    }}>
                                        <Text className={style.H2}>Simplicity in One Dashboard
                                        </Text>
                                        <Text className={style.p2}>Unleash creativity! Manage seamlessly with our intuitive dashboard.




                                        </Text>
                                    </Box>


                                </Box>


                            </Box>



                        </Box>






                    </Box>

                    {/* section4 --------------- */}
                    <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        paddingTop: '97px',
                        paddingBottom: '96px',
                       
                    }}>

                         <Box sx={{
                              width: '1264px',
                              minWidth: '1264px',
                              maxWidth: '1264px',
                              height: '366px',
                              margin: 'auto',
                              borderRadius:'24px',
                              background:'#ffffff',
                              border: `1px solid ${style.borderColor}`,
                              padding:'97px 33px 97px 33px'
                         }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '24px',
                                alignItems:'center'
                            }}>
                                <Text className={style.H3}>Get Started - <span style={{color:'#C026D3'}}>It's free</span>
                                </Text>
                                <Text className={style.p3}>Craft your own Superrlink bio a few minutes and kick off sharing today.

                                </Text>
                                <Button

                                    className={style.btnStyle}
                                    variant="invisible"
                                    sx={{
                                        minWidth: '180px',
                                        maxWidth: '180px',
                                        width: '180px',
                                        minHeight: '56px',
                                        maxHeight: '56px',
                                        height: '56px',
                                        background: style.btnbackgroundColor,
                                        color: style.whitecolor,
                                        borderRadius: '9999px',
                                        '&:hover': {
                                            background: style.blackColor,
                                            backgroundColor: style.blackColor,
                                            
                                        }
                                    }}
                                    onClick={()=>{
                                        navigate('/signup')
                                    }}
                                >Claim now</Button>
                            </Box> 
                         </Box>
                    </Box>


                    {/* Footer */}

                    <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        paddingBottom: '32px',
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center'
                    }}>
                           <Footer/>
                    </Box>

                    {/* ------------------------ */}


                </Box>
            </Desktop>

            <Mobile>
                <Box sx={parenBox}>
                    {/* -------------header  */}
                    <Box position="fixed"
                        sx={{
                            width: '100vw',
                            top: '0',
                            left: '0',
                            height: '76px',
                            zIndex: '999', // Adjust z-index as needed
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: style.pageBg
                        }}>
                        <Header />
                    </Box>

                     {/* section 1 ---------- */}

                     <Box sx={{
                        position: 'relative',
                        height: '480px',
                        minHeight: '480px',
                        width: '100vw',
                        minWidth: '100vw',
                        marginTop: '76px',
                       
                    }}>

                        <Box sx={{
                            position: 'absolute',
                            top: '128px',
                            left: '0px',
                            right: '0px',
                            margin: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            height: 'fit-content',
                            width: 'fit-content',
                            alignItems: 'center',
                            zIndex: '1'
                        }}>
                            <Text className={style.homeHeadingprimaryMobile} sx={{ textAlign: 'center' }}>Your Bio, But With</Text>
                            <Text className={style.homeHeadingsecondaryMobile} sx={{ textAlign: 'center', marginTop: '-5px' }} >Superpowers</Text>
                            <Text className={style.p1} sx={{ textAlign: 'center', marginTop: '13px',maxWidth:'340px' }}>Empower your bio page with your<br></br> owner style
                                to show everything you are.</Text>
                            <Button

                                className={style.btnStyle}
                                variant="invisible"
                                sx={{
                                    marginTop: '24px',
                                    minWidth: '180px',
                                    maxWidth: '180px',
                                    width: '180px',
                                    minHeight: '56px',
                                    maxHeight: '56px',
                                    height: '56px',
                                    background: style.btnbackgroundColor,
                                    color: style.whitecolor,
                                    borderRadius: '9999px',
                                    '&:hover': {
                                        background: style.blackColor,
                                        backgroundColor: style.blackColor

                                    }
                                }}
                                onClick={()=>{
                                    navigate('/signup')
                                }}
                            >Try for free</Button>
                        </Box>

                        <Box
                            style={{
                                position: 'absolute',
                                top: '150px',
                                width: "100%",
                                height: "70%",
                                filter: "blur(100px) saturate(150%)",
                                opacity: ".15",
                                background: "radial-gradient(at 27% 37%,#cfe6f0 0,transparent 0),radial-gradient(at 97% 21%,#f845c6 0,transparent 50%),radial-gradient(at 52% 99%,#7957ff 0,transparent 50%),radial-gradient(at 10% 29%,#2466b8 0,transparent 50%),radial-gradient(at 97% 96%,#147787 0,transparent 50%),radial-gradient(at 33% 50%,#18b6aa 0,transparent 50%),radial-gradient(at 79% 53%,#c1cff2 0,transparent 50%)",
                            }}
                        ></Box>
                    </Box>

                    {/* section 2 ------ */}
                    <Box sx={{
                        position: 'relative',
                        height: '226px',
                        minHeight: '226px',
                        width: '100vw',
                        minWidth: '100vw',
                    }}>
                        <Box sx={{
                            minWidth: '358px',
                            width: '358px',
                            height: '226px',
                            minHeight: '226px',
                            margin: 'auto',
                            borderRadius: '24px',
                            
                        }}
                        
                        
                        >

                             
                            <video
                                ref={videoRef}
                                autoPlay
                                playsInline
                                loop
                                muted
                                style={{ height: "100%", width: "100%", maxWidth: "100%",borderRadius:'24px' }}
                            >
                                <source src={homeVideo} />
                            </video>
         
                            
                            {/* <video
                                
                                muted
                                type="video/mp4"
                                ref={videoRef}
                                playsInline
                                loop 
                                autoPlay
                                width={358}
                                height={226}
                                
                                // style={{ 
                                //  height: "226px",
                                //  width: "358px", 
                                //  maxWidth: "358px", 
                                //  borderRadius: '24px' ,
                                //  }}
                               
                                 >
                                <source  src={homeVideo} />
                            </video> */}
                             
                             

                        </Box>
                    </Box>

                    {/* section3 --------------- */}
                    <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        paddingTop: '97px',
                        paddingBottom: '0px'
                    }}>

                        <Box sx={{
                            width: '95%',
                            minWidth: '95%',
                            maxWidth: '95%',
                            height: 'auto',
                            margin: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '34px',
                            
                        }}>

                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '34px' }}>
                                <Box sx={{
                                    minWidth: '100%',
                                    width: '100%',
                                    maxWidth: '100%',
                                    height: '580px',
                                    minHeight: '580px',
                                    maxHeight: '580px',
                                    border: `1px solid ${style.borderColor}`,
                                    borderRadius: '24px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: '#ffffff'
                                }}>

                                    <Box sx={{

                                        minHeight: '288px',
                                        maxHeight: '288px',
                                        height: '288px',
                                        background: '#F8FAFC',
                                        borderTopLeftRadius: '24px',
                                        borderTopRightRadius: '24px'
                                    }}>
                                        <img style={{
                                            width: '100%',
                                            height: '288px',
                                            maxHeight: '288px',
                                            objectFit: 'cover'
                                        }} src={boxOneImg} />
                                    </Box>

                                    <Box sx={{
                                        height: '233px',
                                        minHeight: '233px',
                                        maxHeight: '233px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '65px 32px 54px 32px',
                                        gap: '16px'
                                    }}>
                                        <Text className={style.H2}>Share magic with our stunning widgets</Text>
                                        <Text className={style.p2}>Customize all widgets according to your style and create an amazing biography.
                                        </Text>
                                    </Box>


                                </Box>
                                <Box sx={{
                                    minWidth: '100%',
                                    width: '100%',
                                    maxWidth: '100%',
                                    height: '580px',
                                    minHeight: '580px',
                                    maxHeight: '580px',
                                    border: `1px solid ${style.borderColor}`,
                                    borderRadius: '24px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: '#ffffff'
                                }}>

                                    <Box sx={{

                                        minHeight: '288px',
                                        maxHeight: '288px',
                                        height: '288px',
                                        background: '#F8FAFC',
                                        borderTopLeftRadius: '24px',
                                        borderTopRightRadius: '24px'
                                    }}>
                                        <img style={{
                                            width: '100%',
                                            height: '288px',
                                            maxHeight: '288px',
                                            objectFit: 'cover'
                                        }} src={boxTwoImg} />
                                    </Box>

                                    <Box sx={{
                                        height: '233px',
                                        minHeight: '233px',
                                        maxHeight: '233px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '65px 32px 54px 32px',
                                        gap: '16px'
                                    }}>
                                        <Text className={style.H2}>Unwrap your style with the perfect variant</Text>
                                        <Text className={style.p2}>Increase your identity by choosing the variant that truly captures your essence!.</Text>
                                    </Box>


                                </Box>



                            </Box>


                            <Box sx={{ minHeight: '30px', display: 'flex',flexDirection:'column', gap: '34px' }}>
                                <Box sx={{
                                    minWidth: '100%',
                                    width: '100%',
                                    maxWidth: '100%',
                                    height: '520px',
                                    minHeight: '520px',
                                    maxHeight: '520px',
                                    border: `1px solid ${style.borderColor}`,
                                    borderRadius: '24px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: '#ffffff'
                                }}>

                                    <Box sx={{

                                        minHeight: '288px',
                                        maxHeight: '288px',
                                        height: '288px',
                                        background: '#F8FAFC',
                                        borderTopLeftRadius: '24px',
                                        borderTopRightRadius: '24px'
                                    }}>
                                        <img style={{
                                            width: '100%',
                                            height: '288px',
                                            maxHeight: '288px',
                                            objectFit: 'cover'
                                        }} src={boxThreeImg} />
                                    </Box>

                                    <Box sx={{
                                        height: '233px',
                                        minHeight: '233px',
                                        maxHeight: '233px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '65px 32px 54px 32px',
                                        gap: '16px'
                                    }}>
                                        <Text className={style.H2}>Drag and Drop elegantly
                                        </Text>
                                        <Text className={style.p2}>Effortlessly drag and drop elements for  a seamless design experience.
                                        </Text>
                                    </Box>


                                </Box>

                                <Box sx={{
                                    minWidth: '100%',
                                    width: '100%',
                                    maxWidth: '100%',
                                    height: '520px',
                                    minHeight: '520px',
                                    maxHeight: '520px',
                                    border: `1px solid ${style.borderColor}`,
                                    borderRadius: '24px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: '#ffffff'
                                }}>

                                    <Box sx={{

                                        minHeight: '288px',
                                        maxHeight: '288px',
                                        height: '288px',
                                        background: '#F8FAFC',
                                        borderTopLeftRadius: '24px',
                                        borderTopRightRadius: '24px'
                                    }}>
                                        <img style={{
                                            width: '100%',
                                            height: '288px',
                                            maxHeight: '288px',
                                            objectFit: 'cover'
                                        }} src={boxFourImg} />
                                    </Box>

                                    <Box sx={{
                                        height: '233px',
                                        minHeight: '233px',
                                        maxHeight: '233px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '65px 32px 54px 32px',
                                        gap: '16px'
                                    }}>
                                        <Text className={style.H2}>Light / Dark amazing </Text>
                                        <Text className={style.p2}>Elevate your journey: simply switch modes for tailored delight.


                                        </Text>
                                    </Box>


                                </Box>

                                <Box sx={{
                                    minWidth: '100%',
                                    width: '100%',
                                    maxWidth: '100%',
                                    height: '580px',
                                    minHeight: '580px',
                                    maxHeight: '580px',
                                    border: `1px solid ${style.borderColor}`,
                                    borderRadius: '24px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: '#ffffff'
                                }}>

                                    <Box sx={{

                                        minHeight: '288px',
                                        maxHeight: '288px',
                                        height: '288px',
                                        background: '#F8FAFC',
                                        borderTopLeftRadius: '24px',
                                        borderTopRightRadius: '24px'
                                    }}>
                                        <img style={{
                                            width: '100%',
                                            height: '288px',
                                            maxHeight: '288px',
                                            objectFit: 'cover'
                                        }} src={boxfiveImg} />
                                    </Box>

                                    <Box sx={{
                                        height: '233px',
                                        minHeight: '233px',
                                        maxHeight: '233px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '65px 32px 54px 32px',
                                        gap: '16px'
                                    }}>
                                        <Text className={style.H2}>Simplicity in One Dashboard
                                        </Text>
                                        <Text className={style.p2}>Unleash creativity! Manage seamlessly with our intuitive dashboard.




                                        </Text>
                                    </Box>


                                </Box>


                            </Box>



                        </Box>






                    </Box>

                    {/* section4 --------------- */}
                    <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        paddingTop: '97px',
                        paddingBottom: '96px',
                    }}>

                        <Box sx={{
                            width: '95%',
                            minWidth: '95%',
                            maxWidth: '95%',
                           // height: '416px',
                            margin: 'auto',
                            borderRadius: '24px',
                            background: '#ffffff',
                            border: `1px solid ${style.borderColor}`,
                            padding: '67px 16px 67px 16px'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '24px',
                                alignItems: 'center'
                            }}>
                                <Text className={style.H3} sx={{textAlign:'center'}}>Get Started - <span style={{ color: '#C026D3' }}>It's free</span>
                                </Text>
                                <Text className={style.p3} sx={{textAlign:'center',fontSize:'1.25rem',lineHeight:'1.75rem'}}>Craft your own Superrlink bio a few<br></br> minutes and kick off sharing<br></br> today.
                                
                                </Text>
                                <Button

                                    className={style.btnStyle}
                                    variant="invisible"
                                    sx={{
                                        minWidth: '180px',
                                        maxWidth: '180px',
                                        width: '180px',
                                        minHeight: '56px',
                                        maxHeight: '56px',
                                        height: '56px',
                                        background: style.btnbackgroundColor,
                                        color: style.whitecolor,
                                        borderRadius: '9999px',
                                        '&:hover': {
                                            background: style.blackColor,
                                            backgroundColor: style.blackColor,

                                        }
                                    }}
                                    onClick={() => {
                                        navigate('/signup')
                                    }}
                                >Claim now</Button>
                            </Box>
                        </Box>
                    </Box>

                     {/* Footer */}

                     <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        paddingBottom: '72px',
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center'
                    }}>
                           <Footer/>
                    </Box>

                    {/* ------------------------ */}

                   

                  </Box>
                  <MobileSideNav/>
            </Mobile>

            <TabletPortrait>
               
                  <Box sx={parenBox}>
                     
                    {/* -------------header  */}
                    <Box position="fixed"
                        sx={{
                            width: '100vw',
                            top: '0',
                            left: '0',
                            height: '76px',
                            zIndex: '999', // Adjust z-index as needed
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: style.pageBg
                        }}>
                        <Header />
                    </Box>
                    
                     {/* section 1 ---------- */}

                     <Box sx={{
                        position: 'relative',
                        height: '520px',
                        minHeight: '520px',
                        width: '100vw',
                        minWidth: '100vw',
                        marginTop: '76px'
                    }}>

                        <Box sx={{
                            position: 'absolute',
                            top: '128px',
                            left: '0px',
                            right: '0px',
                            margin: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            height: 'fit-content',
                            width: 'fit-content',
                            alignItems: 'center',
                            zIndex: '1'
                        }}>
                            <Text className={style.homeHeadingprimary} sx={{ textAlign: 'center' }}>Your Bio, But With</Text>
                            <Text className={style.homeHeadingsecondary} sx={{ textAlign: 'center', marginTop: '-16px' }} >Superpowers</Text>
                            <Text className={style.p1} sx={{ textAlign: 'center', marginTop: '13px' }}>Empower your bio page with your owner style<br></br>
                                to show everything you are.</Text>
                            <Button

                                className={style.btnStyle}
                                variant="invisible"
                                sx={{
                                    marginTop: '24px',
                                    minWidth: '180px',
                                    maxWidth: '180px',
                                    width: '180px',
                                    minHeight: '56px',
                                    maxHeight: '56px',
                                    height: '56px',
                                    background: style.btnbackgroundColor,
                                    color: style.whitecolor,
                                    borderRadius: '9999px',
                                    '&:hover': {
                                        background: style.blackColor,
                                        backgroundColor: style.blackColor

                                    }
                                }}
                                onClick={()=>{
                                    navigate('/signup')
                                }}
                            >Try for free</Button>
                        </Box>

                        <Box
                            style={{
                                position: 'absolute',
                                top: '300px',
                                width: "100%",
                                height: "100%",
                                filter: "blur(100px) saturate(150%)",
                                opacity: ".15",
                                background: "radial-gradient(at 27% 37%,#cfe6f0 0,transparent 0),radial-gradient(at 97% 21%,#f845c6 0,transparent 50%),radial-gradient(at 52% 99%,#7957ff 0,transparent 50%),radial-gradient(at 10% 29%,#2466b8 0,transparent 50%),radial-gradient(at 97% 96%,#147787 0,transparent 50%),radial-gradient(at 33% 50%,#18b6aa 0,transparent 50%),radial-gradient(at 79% 53%,#c1cff2 0,transparent 50%)",
                            }}
                        ></Box>
                    </Box> 
                      
                     {/* section 2 ------ */}
                     <Box sx={{
                        position: 'relative',
                        height: '488px',
                        minHeight: '488px',
                        width: '100vw',
                        minWidth: '100vw',
                    }}>
                        <Box sx={{
                            minWidth: '778px',
                            width: '778px',
                            height: '488px',
                            minHeight: '488px',
                            margin: 'auto',
                            borderRadius: '24px'
                        }}>
                            <video
                                ref={videoRef}
                                autoPlay
                                playsInline
                                loop
                                muted
                                style={{ height: "100%", width: "100%", maxWidth: "100%", borderRadius: '24px' }}>
                                <source src={homeVideo} />
                            </video>
                        </Box>
                    </Box>


                    {/* section3 --------------- */}
                    <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        paddingTop: '97px',
                        paddingBottom: '96px'
                    }}>

                        <Box sx={{
                            width: '778px',
                            minWidth: '778px',
                            maxWidth: '778px',
                            height: 'fit-content',
                            margin: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '34px',

                        }}>

                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '34px' }}>
                                <Box sx={{
                                    minWidth: '371px',
                                    width: '371px',
                                    maxWidth: '371px',
                                    height: '573px',
                                    minHeight: '573px',
                                    maxHeight: '573px',
                                    border: `1px solid ${style.borderColor}`,
                                    borderRadius: '24px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: '#ffffff'
                                }}>

                                    <Box sx={{

                                        minHeight: '288px',
                                        maxHeight: '288px',
                                        height: '288px',
                                        background: '#F8FAFC',
                                        borderTopLeftRadius: '24px',
                                        borderTopRightRadius: '24px'
                                    }}>
                                        <img style={{
                                            width: '100%',
                                            height: '288px',
                                            maxHeight: '288px',
                                            objectFit: 'cover'
                                        }} src={boxOneImg} />
                                    </Box>

                                    <Box sx={{
                                        height: '233px',
                                        minHeight: '233px',
                                        maxHeight: '233px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '65px 32px 54px 32px',
                                        gap: '16px'
                                    }}>
                                        <Text className={style.H2}>Share magic with our stunning widgets</Text>
                                        <Text className={style.p2}>Customize all widgets according to your style and create an amazing biography.
                                        </Text>
                                    </Box>


                                </Box>
                                <Box sx={{
                                    minWidth: '371px',
                                    width: '371px',
                                    maxWidth: '371px',
                                    height: '573px',
                                    minHeight: '573px',
                                    maxHeight: '573px',
                                    border: `1px solid ${style.borderColor}`,
                                    borderRadius: '24px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: '#ffffff'
                                }}>

                                    <Box sx={{

                                        minHeight: '288px',
                                        maxHeight: '288px',
                                        height: '288px',
                                        background: '#F8FAFC',
                                        borderTopLeftRadius: '24px',
                                        borderTopRightRadius: '24px'
                                    }}>
                                        <img style={{
                                            width: '100%',
                                            height: '288px',
                                            maxHeight: '288px',
                                            objectFit: 'cover'
                                        }} src={boxTwoImg} />
                                    </Box>

                                    <Box sx={{
                                        height: '233px',
                                        minHeight: '233px',
                                        maxHeight: '233px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '65px 32px 54px 32px',
                                        gap: '16px'
                                    }}>
                                        <Text className={style.H2}>Unwrap your style with the perfect variant</Text>
                                        <Text className={style.p2}>Increase your identity by choosing the variant that truly captures your essence!.</Text>
                                    </Box>


                                </Box>



                            </Box>


                            <Box sx={{ minHeight: '30px', display: 'flex', gap: '34px' }}>
                                <Box sx={{
                                    minWidth: '241px',
                                    width: '241px',
                                    maxWidth: '241px',
                                    height: '610px',
                                    minHeight: '610px',
                                    maxHeight: '610px',
                                    border: `1px solid ${style.borderColor}`,
                                    borderRadius: '24px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: '#ffffff'
                                }}>

                                    <Box sx={{

                                        minHeight: '288px',
                                        maxHeight: '288px',
                                        height: '288px',
                                        background: '#F8FAFC',
                                        borderTopLeftRadius: '24px',
                                        borderTopRightRadius: '24px'
                                    }}>
                                        <img style={{
                                            width: '100%',
                                            height: '288px',
                                            maxHeight: '288px',
                                            objectFit: 'cover'
                                        }} src={boxThreeImg} />
                                    </Box>

                                    <Box sx={{
                                        height: '233px',
                                        minHeight: '233px',
                                        maxHeight: '233px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '65px 32px 54px 32px',
                                        gap: '16px'
                                    }}>
                                        <Text className={style.H2}>Drag and Drop elegantly
                                        </Text>
                                        <Text className={style.p2}>Effortlessly drag and drop elements for  a seamless design experience.
                                        </Text>
                                    </Box>


                                </Box>

                                <Box sx={{
                                    minWidth: '241px',
                                    width: '241px',
                                    maxWidth: '241px',
                                    height: '610px',
                                    minHeight: '610px',
                                    maxHeight: '610px',
                                    border: `1px solid ${style.borderColor}`,
                                    borderRadius: '24px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: '#ffffff'
                                }}>

                                    <Box sx={{

                                        minHeight: '288px',
                                        maxHeight: '288px',
                                        height: '288px',
                                        background: '#F8FAFC',
                                        borderTopLeftRadius: '24px',
                                        borderTopRightRadius: '24px'
                                    }}>
                                        <img style={{
                                            width: '100%',
                                            height: '288px',
                                            maxHeight: '288px',
                                            objectFit: 'cover'
                                        }} src={boxFourImg} />
                                    </Box>

                                    <Box sx={{
                                        height: '233px',
                                        minHeight: '233px',
                                        maxHeight: '233px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '65px 32px 54px 32px',
                                        gap: '16px'
                                    }}>
                                        <Text className={style.H2}>Light / Dark amazing </Text>
                                        <Text className={style.p2}>Elevate your journey: simply switch modes for tailored delight.


                                        </Text>
                                    </Box>


                                </Box>

                                <Box sx={{
                                    minWidth: '241px',
                                    width: '241px',
                                    maxWidth: '241px',
                                    height: '610px',
                                    minHeight: '610px',
                                    maxHeight: '610px',
                                    border: `1px solid ${style.borderColor}`,
                                    borderRadius: '24px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: '#ffffff'
                                }}>

                                    <Box sx={{

                                        minHeight: '288px',
                                        maxHeight: '288px',
                                        height: '288px',
                                        background: '#F8FAFC',
                                        borderTopLeftRadius: '24px',
                                        borderTopRightRadius: '24px'
                                    }}>
                                        <img style={{
                                            width: '100%',
                                            height: '288px',
                                            maxHeight: '288px',
                                            objectFit: 'cover'
                                        }} src={boxfiveImg} />
                                    </Box>

                                    <Box sx={{
                                        height: '233px',
                                        minHeight: '233px',
                                        maxHeight: '233px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '65px 32px 54px 32px',
                                        gap: '16px'
                                    }}>
                                        <Text className={style.H2}>Simplicity in One Dashboard
                                        </Text>
                                        <Text className={style.p2}>Unleash creativity! Manage seamlessly with our intuitive dashboard.




                                        </Text>
                                    </Box>


                                </Box>


                            </Box>



                        </Box>






                    </Box>


                    {/* section4 --------------- */}
                    <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        paddingBottom: '56px',

                    }}>

                        <Box sx={{
                            width: '750px',
                            minWidth: '750px',
                            maxWidth: '750px',
                            height: '366px',
                            margin: 'auto',
                            borderRadius: '24px',
                            background: '#ffffff',
                            border: `1px solid ${style.borderColor}`,
                            padding: '97px 33px 97px 33px'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '24px',
                                alignItems: 'center'
                            }}>
                                <Text className={style.H3}>Get Started - <span style={{ color: '#C026D3' }}>It's free</span>
                                </Text>
                                <Text className={style.p3}>Craft your own Superrlink bio a few minutes and kick off sharing today.

                                </Text>
                                <Button

                                    className={style.btnStyle}
                                    variant="invisible"
                                    sx={{
                                        minWidth: '180px',
                                        maxWidth: '180px',
                                        width: '180px',
                                        minHeight: '56px',
                                        maxHeight: '56px',
                                        height: '56px',
                                        background: style.btnbackgroundColor,
                                        color: style.whitecolor,
                                        borderRadius: '9999px',
                                        '&:hover': {
                                            background: style.blackColor,
                                            backgroundColor: style.blackColor,

                                        }
                                    }}
                                    onClick={() => {
                                        navigate('/signup')
                                    }}
                                >Claim now</Button>
                            </Box>
                        </Box>
                    </Box>
                    
                    {/* Footer */}

                    <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        paddingBottom: '72px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Footer />
                    </Box>

                    {/* ------------------------ */}

                  
                    </Box> 

            </TabletPortrait>

            <TabletLandscape>


                <Box sx={parenBox}>

                    {/* -------------header  */}
                    <Box position="fixed"
                        sx={{
                            width: '100vw',
                            top: '0',
                            left: '0',
                            height: '76px',
                            zIndex: '999', // Adjust z-index as needed
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: style.pageBg
                        }}>
                        <Header />
                    </Box>

                    {/* section 1 ---------- */}

                    <Box sx={{
                        position: 'relative',
                        height: '520px',
                        minHeight: '520px',
                        width: '100vw',
                        minWidth: '100vw',
                        marginTop: '76px'
                    }}>

                        <Box sx={{
                            position: 'absolute',
                            top: '128px',
                            left: '0px',
                            right: '0px',
                            margin: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            height: 'fit-content',
                            width: 'fit-content',
                            alignItems: 'center',
                            zIndex: '1'
                        }}>
                            <Text className={style.homeHeadingprimary} sx={{ textAlign: 'center' }}>Your Bio, But With</Text>
                            <Text className={style.homeHeadingsecondary} sx={{ textAlign: 'center', marginTop: '-16px' }} >Superpowers</Text>
                            <Text className={style.p1} sx={{ textAlign: 'center', marginTop: '13px' }}>Empower your bio page with your owner style<br></br>
                                to show everything you are.</Text>
                            <Button

                                className={style.btnStyle}
                                variant="invisible"
                                sx={{
                                    marginTop: '24px',
                                    minWidth: '180px',
                                    maxWidth: '180px',
                                    width: '180px',
                                    minHeight: '56px',
                                    maxHeight: '56px',
                                    height: '56px',
                                    background: style.btnbackgroundColor,
                                    color: style.whitecolor,
                                    borderRadius: '9999px',
                                    '&:hover': {
                                        background: style.blackColor,
                                        backgroundColor: style.blackColor

                                    }
                                }}
                                onClick={() => {
                                    navigate('/signup')
                                }}
                            >Try for free</Button>
                        </Box>

                        <Box
                            style={{
                                position: 'absolute',
                                top: '300px',
                                width: "100%",
                                height: "100%",
                                filter: "blur(100px) saturate(150%)",
                                opacity: ".15",
                                background: "radial-gradient(at 27% 37%,#cfe6f0 0,transparent 0),radial-gradient(at 97% 21%,#f845c6 0,transparent 50%),radial-gradient(at 52% 99%,#7957ff 0,transparent 50%),radial-gradient(at 10% 29%,#2466b8 0,transparent 50%),radial-gradient(at 97% 96%,#147787 0,transparent 50%),radial-gradient(at 33% 50%,#18b6aa 0,transparent 50%),radial-gradient(at 79% 53%,#c1cff2 0,transparent 50%)",
                            }}
                        ></Box>
                    </Box>

                    {/* section 2 ------ */}
                    <Box sx={{
                        position: 'relative',
                        height: '488px',
                        minHeight: '488px',
                        width: '100vw',
                        minWidth: '100vw',
                    }}>
                        <Box sx={{
                            minWidth: '978px',
                            width: '978px',
                            height: '488px',
                            minHeight: '488px',
                            margin: 'auto',
                            borderRadius: '24px',
                            
                        }}>
                            <video
                                ref={videoRef}
                                autoPlay
                                playsInline
                                loop
                                muted
                                style={{ height: "100%", width: "100%", maxWidth: "100%", borderRadius: '24px' }}>
                                <source src={homeVideo} />
                            </video>
                        </Box>
                    </Box>


                    {/* section3 --------------- */}
                    <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        paddingTop: '97px',
                        paddingBottom: '96px'
                    }}>

                        <Box sx={{
                            width: '978px',
                            minWidth: '978px',
                            maxWidth: '978px',
                            height: 'fit-content',
                            margin: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '34px',
                           
                        }}>

                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '34px' }}>
                                <Box sx={{
                                    minWidth: '471px',
                                    width: '471px',
                                    maxWidth: '471px',
                                    height: '573px',
                                    minHeight: '573px',
                                    maxHeight: '573px',
                                    border: `1px solid ${style.borderColor}`,
                                    borderRadius: '24px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: '#ffffff'
                                }}>

                                    <Box sx={{

                                        minHeight: '288px',
                                        maxHeight: '288px',
                                        height: '288px',
                                        background: '#F8FAFC',
                                        borderTopLeftRadius: '24px',
                                        borderTopRightRadius: '24px'
                                    }}>
                                        <img style={{
                                            width: '100%',
                                            height: '288px',
                                            maxHeight: '288px',
                                            objectFit: 'cover'
                                        }} src={boxOneImg} />
                                    </Box>

                                    <Box sx={{
                                        height: '233px',
                                        minHeight: '233px',
                                        maxHeight: '233px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '65px 32px 54px 32px',
                                        gap: '16px'
                                    }}>
                                        <Text className={style.H2}>Share magic with our stunning widgets</Text>
                                        <Text className={style.p2}>Customize all widgets according to your style and create an amazing biography.
                                        </Text>
                                    </Box>


                                </Box>
                                <Box sx={{
                                    minWidth: '471px',
                                    width: '471px',
                                    maxWidth: '471px',
                                    height: '573px',
                                    minHeight: '573px',
                                    maxHeight: '573px',
                                    border: `1px solid ${style.borderColor}`,
                                    borderRadius: '24px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: '#ffffff'
                                }}>

                                    <Box sx={{

                                        minHeight: '288px',
                                        maxHeight: '288px',
                                        height: '288px',
                                        background: '#F8FAFC',
                                        borderTopLeftRadius: '24px',
                                        borderTopRightRadius: '24px'
                                    }}>
                                        <img style={{
                                            width: '100%',
                                            height: '288px',
                                            maxHeight: '288px',
                                            objectFit: 'cover'
                                        }} src={boxTwoImg} />
                                    </Box>

                                    <Box sx={{
                                        height: '233px',
                                        minHeight: '233px',
                                        maxHeight: '233px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '65px 32px 54px 32px',
                                        gap: '16px'
                                    }}>
                                        <Text className={style.H2}>Unwrap your style with the perfect variant</Text>
                                        <Text className={style.p2}>Increase your identity by choosing the variant that truly captures your essence!.</Text>
                                    </Box>


                                </Box>



                            </Box>


                            <Box sx={{ minHeight: '30px', display: 'flex', gap: '34px' }}>
                                <Box sx={{
                                    minWidth: '302px',
                                    width: '302px',
                                    maxWidth: '302px',
                                    height: '610px',
                                    minHeight: '610px',
                                    maxHeight: '610px',
                                    border: `1px solid ${style.borderColor}`,
                                    borderRadius: '24px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: '#ffffff'
                                }}>

                                    <Box sx={{

                                        minHeight: '288px',
                                        maxHeight: '288px',
                                        height: '288px',
                                        background: '#F8FAFC',
                                        borderTopLeftRadius: '24px',
                                        borderTopRightRadius: '24px'
                                    }}>
                                        <img style={{
                                            width: '100%',
                                            height: '288px',
                                            maxHeight: '288px',
                                            objectFit: 'cover'
                                        }} src={boxThreeImg} />
                                    </Box>

                                    <Box sx={{
                                        height: '233px',
                                        minHeight: '233px',
                                        maxHeight: '233px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '65px 32px 54px 32px',
                                        gap: '16px'
                                    }}>
                                        <Text className={style.H2}>Drag and Drop elegantly
                                        </Text>
                                        <Text className={style.p2}>Effortlessly drag and drop elements for  a seamless design experience.
                                        </Text>
                                    </Box>


                                </Box>

                                <Box sx={{
                                    minWidth: '302px',
                                    width: '302px',
                                    maxWidth: '302px',
                                    height: '610px',
                                    minHeight: '610px',
                                    maxHeight: '610px',
                                    border: `1px solid ${style.borderColor}`,
                                    borderRadius: '24px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: '#ffffff'
                                }}>

                                    <Box sx={{

                                        minHeight: '288px',
                                        maxHeight: '288px',
                                        height: '288px',
                                        background: '#F8FAFC',
                                        borderTopLeftRadius: '24px',
                                        borderTopRightRadius: '24px'
                                    }}>
                                        <img style={{
                                            width: '100%',
                                            height: '288px',
                                            maxHeight: '288px',
                                            objectFit: 'cover'
                                        }} src={boxFourImg} />
                                    </Box>

                                    <Box sx={{
                                        height: '233px',
                                        minHeight: '233px',
                                        maxHeight: '233px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '65px 32px 54px 32px',
                                        gap: '16px'
                                    }}>
                                        <Text className={style.H2}>Light / Dark amazing </Text>
                                        <Text className={style.p2}>Elevate your journey: simply switch modes for tailored delight.


                                        </Text>
                                    </Box>


                                </Box>

                                <Box sx={{
                                    minWidth: '302px',
                                    width: '241px',
                                    maxWidth: '241px',
                                    height: '610px',
                                    minHeight: '610px',
                                    maxHeight: '610px',
                                    border: `1px solid ${style.borderColor}`,
                                    borderRadius: '24px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: '#ffffff'
                                }}>

                                    <Box sx={{

                                        minHeight: '288px',
                                        maxHeight: '288px',
                                        height: '288px',
                                        background: '#F8FAFC',
                                        borderTopLeftRadius: '24px',
                                        borderTopRightRadius: '24px'
                                    }}>
                                        <img style={{
                                            width: '100%',
                                            height: '288px',
                                            maxHeight: '288px',
                                            objectFit: 'cover'
                                        }} src={boxfiveImg} />
                                    </Box>

                                    <Box sx={{
                                        height: '233px',
                                        minHeight: '233px',
                                        maxHeight: '233px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '65px 32px 54px 32px',
                                        gap: '16px'
                                    }}>
                                        <Text className={style.H2}>Simplicity in One Dashboard
                                        </Text>
                                        <Text className={style.p2}>Unleash creativity! Manage seamlessly with our intuitive dashboard.




                                        </Text>
                                    </Box>


                                </Box>


                            </Box>



                        </Box>






                    </Box>


                    {/* section4 --------------- */}
                    <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        paddingBottom: '56px',

                    }}>

                        <Box sx={{
                            width: '850px',
                            minWidth: '850px',
                            maxWidth: '850px',
                            height: '366px',
                            margin: 'auto',
                            borderRadius: '24px',
                            background: '#ffffff',
                            border: `1px solid ${style.borderColor}`,
                            padding: '97px 33px 97px 33px'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '24px',
                                alignItems: 'center'
                            }}>
                                <Text className={style.H3}>Get Started - <span style={{ color: '#C026D3' }}>It's free</span>
                                </Text>
                                <Text className={style.p3}>Craft your own Superrlink bio a few minutes and kick off sharing today.

                                </Text>
                                <Button

                                    className={style.btnStyle}
                                    variant="invisible"
                                    sx={{
                                        minWidth: '180px',
                                        maxWidth: '180px',
                                        width: '180px',
                                        minHeight: '56px',
                                        maxHeight: '56px',
                                        height: '56px',
                                        background: style.btnbackgroundColor,
                                        color: style.whitecolor,
                                        borderRadius: '9999px',
                                        '&:hover': {
                                            background: style.blackColor,
                                            backgroundColor: style.blackColor,

                                        }
                                    }}
                                    onClick={() => {
                                        navigate('/signup')
                                    }}
                                >Claim now</Button>
                            </Box>
                        </Box>
                    </Box>

                    {/* Footer */}

                    <Box sx={{
                        position: 'relative',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        width: '100vw',
                        minWidth: '100vw',
                        paddingBottom: '62px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Footer />
                    </Box>

                    {/* ------------------------ */}


    


                </Box>











            </TabletLandscape>

        </>

    )
}