import { Box, Heading,Link,Text } from "@primer/react";
import { useNavigate } from 'react-router-dom';
import style from '../global.module.scss'
import googleIcon from '../assets/Google.svg'
import { useMediaQuery } from 'react-responsive'
import { doc, getDoc, setDoc} from "firebase/firestore";
import {db,auth,googleProvider, analytics} from '../firebase';
import { signInWithPopup,GoogleAuthProvider, setPersistence, browserSessionPersistence  } from 'firebase/auth';
import React from "react";
import {logEvent, setUserId, setUserProperties } from "firebase/analytics";
import mixpanel from 'mixpanel-browser';
import posthog from "posthog-js";
mixpanel.init('7a2c125cf3bf07b7fef81e91d0190b14');
posthog.init('phc_mGFMcRORH045UBsthDPi8bGDGMAPCvc7qtACPde72Hh', { api_host: 'https://app.posthog.com' })
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}
const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
}

const parentBox = {
    display:'flex',
    flexDirection:'column',
    height:'100vh',
    width:'100vw',
    background:style.pageBg
}

 

const childBox = {
    position: 'absolute',
    top: '135px',
    left: '0px',
    right: '0px',
    margin: 'auto auto auto auto',
    height: '326px',
    width: '388px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '39px'

}

const innerChildBox = {display:'flex',flexDirection:'column',gap:'29px',alignItems:'center'}

const childBoxMobile = {
    position: 'absolute',
    top: '135px',
    left: '0px',
    right: '0px',
    margin: 'auto auto auto auto',
    height: '326px',
    width: '348px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '39px',
  //  background:'red'
    
}

const innerChild = {
    display: 'flex',
    flexDirection: 'row',
    gap: '18px',
    alignItems:'center'
}

export default function SignIn(){

    const navigate = useNavigate()
   
      

    const handleFirebaseAuth = (props) => {

        signInWithPopup(auth, googleProvider)
            .then((result) => {

                console.log("result", result)
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                const user = result.user;
                console.log("user", user)
                handleLogin(user.uid, user.email)

            }).catch((error) => {

                const errorCode = error.code;
                const errorMessage = error.message;
                const email = error.customData.email;
                const credential = GoogleAuthProvider.credentialFromError(error);

            });

    }

    const handleSignUpWithFirebase = async (userId, userEmail) => {

        console.log(userId, userEmail)
        const userRef = doc(db, 'users', userId);
        const data = {
            userId: userId,
            userEmail: userEmail,
            timeStamp: new Date(),
            capital: true,
            merge: true,
            username: '',
            name: '',
            description: '',
            imageUrl: ''
        }

        await setDoc(userRef, data)
         
        setUserId(analytics, userId)
        setUserProperties(analytics, { userEmail: userEmail })
        logEvent(analytics, 'signup', {
            userId: userId,
            userEmail: userEmail
        });


        navigate('/accountsetup')
    }

    

    const handleLogin =async (props,email) => {

           
          const userRef = doc(db, 'users', props);
          const docSnap = await getDoc(userRef);
          if (docSnap.exists()) {

              setUserId(analytics, props)
              setUserProperties(analytics, { userEmail: email })
              logEvent(analytics, 'login', {
                  userId: props,
                  userEmail: email
              });

            

            const responseData = docSnap.data()
            const userName = responseData?.username
            console.log("userName", userName)
            if (userName === "") {
                  navigate('/accountsetup')
            } else {

                mixpanel.people.set_once({
                    user_id: props,
                    user_email: email,
                    $name: responseData?.name,
                    $email: email,
                    user_name: userName
                })

                mixpanel.identify(props)
                mixpanel.track('Login', {
                    $distinct_id: props,
                    time: new Date().getTime(),
                    user_id: props,
                    user_email: email
                })

                posthog.identify(props, { name: responseData?.name, email:email })

                posthog.capture(
                    'Login',
                    {
                        distinct_id: props,
                        $set_once: { username: userName },
                        user_id:props,
                        user_email:email,
                        timestamp: new Date().toISOString(),
                       
                    }
                )


                navigate(`/${userName}`)
            }
            


          }else{

                handleSignUpWithFirebase(props,email)   

          }

    }


    

   
    

    React.useState(()=>{


         

          setPersistence(auth, browserSessionPersistence)
          .then(() => {
             console.log("Firebase session persistence enabled successfully!");
          })
          .catch((error) => {
             console.error("Error enabling Firebase session persistence:", error);
          });
         

    })


  

    return(

        <>
            <Default >
                <Box sx={parentBox}>

                    <Box sx={childBox}>
                        <Box sx={innerChildBox}>
                        <Heading className={style.H1}>Welcome Back  👋</Heading>
                        <Text className={style.p2} sx={{ textAlign: 'center' }}>Today is a new day. It's your day. You shape it.
                            Sign in to start managing your  bio page .</Text>
                        </Box>
                        
                        <Box sx={innerChild}>
                            <Box sx={{ minWidth: '115px', height: '1px', background: style.borderColor }}></Box>
                            <Text className={style.p3} sx={{ textAlign: 'center' }}>Sign in with.</Text>
                            <Box sx={{ minWidth: '115px', height: '1px', background: style.borderColor }}></Box>
                        </Box>

                        <Box sx={{
                            minHeight: '52px',
                            width: '100%',
                            background: style.btnbackgroundColor,
                            borderRadius: '12px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '16px',
                            cursor: 'pointer',
                            ":hover": {
                                  background:'rgb(246, 248, 250)',
                                  border:`1px solid ${style.borderColor}`
                            }
                        }}
                        onClick={()=>{
                            handleFirebaseAuth("")
                        }}
                        >
                            <img src={googleIcon}></img>
                            <Text className={style.p3} sx={{}}>Sign in with Google</Text>
                        </Box>

                        <Text className={style.p2} sx={{ textAlign: 'center' }}>Don't you have an account? <Link sx={{":hover":{
                              textDecoration:'none',
                              cursor:'pointer'
                        }}}  onClick={()=> navigate('/signup')}>Sign up .</Link></Text>

                    </Box>
                   
                    
                </Box>
                 

            </Default >
            {/* Mobile responsive code */}
            <Mobile>
                <Box sx={parentBox}>

                    <Box sx={childBoxMobile}>
                    <Box sx={innerChildBox}>
                        <Heading className={style.H1}>Welcome Back  👋</Heading>
                        <Text className={style.p2} sx={{ textAlign: 'center' }}>Today is a new day. It's your day. You shape it.
                            Sign in to start managing your  bio page .</Text>
                        </Box>
                        
                        <Box sx={innerChild}>
                            <Box sx={{ minWidth: '115px', height: '1px', background: style.borderColor }}></Box>
                            <Text className={style.p3} sx={{ textAlign: 'center' }}>Sign in with.</Text>
                            <Box sx={{ minWidth: '115px', height: '1px', background: style.borderColor }}></Box>
                        </Box>

                        <Box sx={{
                            minHeight: '52px',
                            width: '100%',
                            background: style.btnbackgroundColor,
                            borderRadius: '12px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '16px',
                            cursor: 'pointer',
                            ":hover": {
                                // background:'rgb(246, 248, 250)'
                            }
                        }}
                        onClick={()=>{
                            handleFirebaseAuth("")
                        }}
                        >
                            <img src={googleIcon}></img>
                            <Text className={style.p3} sx={{}}>Sign in with Google</Text>
                        </Box>

                        <Text className={style.p2} sx={{ textAlign: 'center' }}>Don't you have an account? <Link sx={{":hover":{
                              textDecoration:'none',
                              cursor:'pointer'
                        }}} onClick={()=> navigate('/signup')} >Sign up .</Link></Text>

                    </Box>


                </Box>
            </Mobile>
            <Text className={style.p4} sx={{
                         
                        textAlign:'center',
                        width:'100vw',
                        position: 'absolute', 
                        bottom: '30px', 
                        left: '0px',
                        right: '0px',
                        margin: 'auto auto auto auto',
                    }}>© 2024 ALL RIGHTS RESERVED</Text>
        </>
        
    )
}