import twitter from '../../assets/socialIcons/twitter.svg'
import instagram from '../../assets/socialIcons/Instagram.svg'
import linkedIn from '../../assets/socialIcons/linkedIn.svg'
import youtube from '../../assets/socialIcons/youtube.svg'
import spotify from '../../assets/socialIcons/spotify.svg'
import marxiix from '../../assets/socialIcons/marxiix.svg'
import thread from '../../assets/socialIcons/thread.svg'
import discord from '../../assets/socialIcons/discord.svg'
import github from '../../assets/socialIcons/github.svg'
import gumroad from '../../assets/socialIcons/gumroad.svg'
import medium from '../../assets/socialIcons/medium.svg'
import figma from '../../assets/socialIcons/figma.svg'
import drible from '../../assets/socialIcons/drible.svg'
import sms from '../../assets/socialIcons/sms.svg'
import behance from '../../assets/socialIcons/behance.svg'
import whatsapp from '../../assets/socialIcons/whatsapp.svg'
import clubhouse from '../../assets/socialIcons/clubhouse.svg'
import mastodon from '../../assets/socialIcons/mastodon.svg'
import tiktok from '../../assets/socialIcons/tiktok.svg'
import producthunt from '../../assets/socialIcons/producthunt.svg'
import coffe from '../../assets/socialIcons/coffe.svg'

const platformUrl = [
    { id: 'twitter', icon: twitter, url:'https://twitter.com/' },
    { id: 'instagram', icon: instagram,url:'https://www.instagram.com/' },
    { id: 'linkedIn', icon: linkedIn, url:'https://www.linkedin.com/' },
    { id: 'youtube', icon: youtube, url:'https://youtube.com/'},
    { id: 'spotify', icon: spotify, url:'https://www.spotify.com/' },
    { id: 'marxiix', icon: marxiix, url:'https://www.twitch.tv/' },
    { id: 'thread', icon: thread, url:'https://www.threads.net/' },
    { id: 'discord', icon: discord, url:'https://discord.com/' },
    { id: 'github', icon: github, url:'https://github.com/' },
    { id: 'gumroad', icon: gumroad, url:'https://app.gumroad.com/' },
    { id: 'medium', icon: medium, url:'https://medium.com/' },
    { id: 'figma', icon: figma, url:'https://www.figma.com/' },
    { id: 'drible', icon: drible, url:'https://dribbble.com/' },
    { id: 'sms', icon: sms, url:'mailto:' },
    { id: 'behance',icon:behance, url:'https://www.behance.net/' },
    { id: 'whatsapp',icon:whatsapp, url:'https://wa.me/' },
    { id: 'clubhouse',icon:clubhouse ,url:'https://www.clubhouse.com/' },
    { id: 'mastodon',icon:mastodon ,url:'https://mastodon.social/' },
    { id: 'tiktok',icon:tiktok, url:'https://www.tiktok.com/' },
    { id: 'producthunt',icon:producthunt, url:'https://www.producthunt.com/' },
    { id: 'coffe',icon:coffe ,url:'https://www.buymeacoffee.com/' }
]

export default platformUrl