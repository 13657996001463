import React from "react";
import { Box,Text } from "@primer/react";
import style from './web.module.scss'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import MobileSideNav from "./MobileSideNav";
import posthog from "posthog-js";
posthog.init('phc_mGFMcRORH045UBsthDPi8bGDGMAPCvc7qtACPde72Hh', { api_host: 'https://app.posthog.com' })
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1224 })
    return isDesktop ? children : null
}

const TabletPortrait = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991, orientation: 'portrait' })
    return isTablet ? children : null
}
const TabletLandscape = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 992, maxWidth: 1223, orientation: 'landscape' })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}
const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
}


export default function Terms() {

    const navigate = useNavigate()

    const headerSyle =
    {
        width: '100vw',
        top: '0',
        left: '0',
        height: '76px',
        zIndex: '999', // Adjust z-index as needed
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: style.pageBg
    }


    const parenBox = {
        position: 'relative',
        width: '100vw',
        minWidth: '100vw',
        maxWidth: '100vw',
        height: '100vh',
        minHeight: '100vh',
        maxHeight: '100vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: style.pageBg
    }

    const termsPageEvent = (props) => {

        posthog.capture(
            'TermsPage',
            {
                distinct_id: 'TermsPage',
                timestamp: new Date().toISOString(),
            }
        )

    }

    React.useState(() => {

        termsPageEvent("")

    })


    return (<>
        <Desktop>
            <Box sx={parenBox}>
                {/* -------------header  */}
                <Box
                    position="fixed"
                    sx={headerSyle}>
                    <Header />
                </Box>

                {/* Section one  */}

                <Box sx={{
                    width: '1280px',
                    minWidth: '1280px',
                    maxWidth: '1280px',
                    minHeight: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '201px',
                    gap: '24px',
                    paddingBottom: '96px'
                }}>
                    <Text className={style.p10}>Terms of Service
                    </Text>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>

                        <Text className={style.p9}>1. Introduction</Text>
                        <Text className={style.p8}>By using Superrlink you confirm your acceptance of, and agree to be bound by, these terms and conditions.</Text>
                        <Text className={style.p9}>2. Agreement to Terms and Conditions</Text>
                        <Text className={style.p8}>This Agreement takes effect on the date on which you first use the Superrlink application.</Text>
                        <Text className={style.p8}>3. Unlimited Access Software License with Termination Rights The Superrlink Software License facilitates the acquisition of Superrlink software through a single purchase, granting users unrestricted and perpetual access to its comprehensive functionalities. Tailored for Hobbyists and Pros, Superrlink empowers users to create a bio website. This license entails a straightforward and flexible arrangement, exempting users from recurring fees or subscriptions. However, it is important to acknowledge that the licensor retains the right to terminate the license without conditions or prerequisites. This termination provision enables the licensor to exercise control over software distribution and utilization. Opting for the Superrlink Software License enables users to enjoy the benefits of the software while recognizing the licensor's unrestricted termination rights, which provide adaptability and address potential unforeseen circumstances.</Text>
                        <Text className={style.p9}>4. Disclaimer</Text>
                        <Text className={style.p8}>It is not warranted that Superrlink will meet your requirements or that its operation will be uninterrupted or error free. All express and implied warranties or conditions not stated in this Agreement (including without limitation, loss of profits, loss or corruption of data, business interruption or loss of contracts), so far as such exclusion or disclaimer is permitted under the applicable law are excluded and expressly disclaimed. This Agreement does not affect your statutory rights. </Text>
                        <Text className={style.p9}>4. Warranties and Limitation of Liability</Text>
                        <Text className={style.p8}>Superrlink does not give any warranty, guarantee or other term as to the quality, fitness for purpose or otherwise of the software. Superrlink shall not be liable to you by reason of any representation (unless fraudulent), or any implied warranty, condition or other term, or any duty at common law, for any loss of profit or any indirect, special or consequential loss, damage, costs, expenses or other claims (whether caused by Superrlink negligence or the negligence of its servants or agents or otherwise) which arise out of or in connection with the provision of any goods or services by Superrlink. Superrlink shall not be liable or deemed to be in breach of contract by reason of any delay in performing, or failure to perform, any of its obligations if the delay or failure was due to any cause beyond its reasonable control. Notwithstanding contrary clauses in this Agreement, in the event that Superrlink are deemed liable to you for breach of this Agreement, you agree that Superrlink liability is limited to the amount actually paid by you for your services or software, which amount calculated in reliance upon this clause. You hereby release Superrlink from any and all obligations, liabilities and claims in excess of this limitation </Text>
                        <Text className={style.p9}>4.Responsibilities</Text>
                        <Text className={style.p8}>Superrlink is not responsible for what the user does with the user-generated content.</Text>
                        <Text className={style.p8}>Last updated: 21 Dec 2023.</Text>


                    </Box>
                </Box>



                {/* ------------ */}

                {/* Footer */}

                <Box sx={{
                    position: 'relative',
                    height: 'fit-content',
                    minHeight: 'fit-content',
                    width: '100vw',
                    minWidth: '100vw',
                    paddingBottom: '62px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <Footer />

                </Box>

                {/* ------------------------ */}

            </Box>

        </Desktop>

        <Mobile>
            <Box sx={parenBox}>
                {/* -------------header  */}
                <Box position="fixed"
                    sx={{
                        width: '100vw',
                        top: '0',
                        left: '0',
                        height: '76px',
                        zIndex: '999', // Adjust z-index as needed
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: style.pageBg
                    }}>
                    <Header />
                </Box>


                {/* Section one  */}

                <Box sx={{
                    width: '92vw',
                    minWidth: '92vw',
                    maxWidth: '92vw',
                    minHeight: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '201px',
                    gap: '24px',
                    paddingBottom: '96px'
                }}>
                    <Text className={style.p10}>Terms of Service
                    </Text>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>

                        <Text className={style.p9}>1. Introduction</Text>
                        <Text className={style.p8}>By using Superrlink you confirm your acceptance of, and agree to be bound by, these terms and conditions.</Text>
                        <Text className={style.p9}>2. Agreement to Terms and Conditions</Text>
                        <Text className={style.p8}>This Agreement takes effect on the date on which you first use the Superrlink application.</Text>
                        <Text className={style.p8}>3. Unlimited Access Software License with Termination Rights The Superrlink Software License facilitates the acquisition of Superrlink software through a single purchase, granting users unrestricted and perpetual access to its comprehensive functionalities. Tailored for Hobbyists and Pros, Superrlink empowers users to create a bio website. This license entails a straightforward and flexible arrangement, exempting users from recurring fees or subscriptions. However, it is important to acknowledge that the licensor retains the right to terminate the license without conditions or prerequisites. This termination provision enables the licensor to exercise control over software distribution and utilization. Opting for the Superrlink Software License enables users to enjoy the benefits of the software while recognizing the licensor's unrestricted termination rights, which provide adaptability and address potential unforeseen circumstances.</Text>
                        <Text className={style.p9}>4. Disclaimer</Text>
                        <Text className={style.p8}>It is not warranted that Superrlink will meet your requirements or that its operation will be uninterrupted or error free. All express and implied warranties or conditions not stated in this Agreement (including without limitation, loss of profits, loss or corruption of data, business interruption or loss of contracts), so far as such exclusion or disclaimer is permitted under the applicable law are excluded and expressly disclaimed. This Agreement does not affect your statutory rights. </Text>
                        <Text className={style.p9}>4. Warranties and Limitation of Liability</Text>
                        <Text className={style.p8}>Superrlink does not give any warranty, guarantee or other term as to the quality, fitness for purpose or otherwise of the software. Superrlink shall not be liable to you by reason of any representation (unless fraudulent), or any implied warranty, condition or other term, or any duty at common law, for any loss of profit or any indirect, special or consequential loss, damage, costs, expenses or other claims (whether caused by Superrlink negligence or the negligence of its servants or agents or otherwise) which arise out of or in connection with the provision of any goods or services by Superrlink. Superrlink shall not be liable or deemed to be in breach of contract by reason of any delay in performing, or failure to perform, any of its obligations if the delay or failure was due to any cause beyond its reasonable control. Notwithstanding contrary clauses in this Agreement, in the event that Superrlink are deemed liable to you for breach of this Agreement, you agree that Superrlink liability is limited to the amount actually paid by you for your services or software, which amount calculated in reliance upon this clause. You hereby release Superrlink from any and all obligations, liabilities and claims in excess of this limitation </Text>
                        <Text className={style.p9}>4.Responsibilities</Text>
                        <Text className={style.p8}>Superrlink is not responsible for what the user does with the user-generated content.</Text>
                        <Text className={style.p8}>Last updated: 21 Dec 2023.</Text>

                    </Box>
                </Box>



                {/* ------------ */}
                {/* Footer */}
                <Box sx={{
                    position: 'relative',
                    height: 'fit-content',
                    minHeight: 'fit-content',
                    width: '100vw',
                    minWidth: '100vw',
                    paddingBottom: '72px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <Footer />
                </Box>

            </Box>
            <MobileSideNav />
        </Mobile>
        <TabletPortrait>
            <Box sx={parenBox}>

                {/* -------------header  */}
                <Box position="fixed"
                    sx={{
                        width: '100vw',
                        top: '0',
                        left: '0',
                        height: '76px',
                        zIndex: '999', // Adjust z-index as needed
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: style.pageBg
                    }}>
                    <Header />
                </Box>

                {/* Section one  */}

                <Box sx={{
                    width: '778px',
                    minWidth: '778px',
                    maxWidth: '778px',
                    minHeight: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '201px',
                    gap: '24px',
                    paddingBottom: '96px'
                }}>
                    <Text className={style.p10}>Terms of Service
                    </Text>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>

                        <Text className={style.p9}>1. Introduction</Text>
                        <Text className={style.p8}>By using Superrlink you confirm your acceptance of, and agree to be bound by, these terms and conditions.</Text>
                        <Text className={style.p9}>2. Agreement to Terms and Conditions</Text>
                        <Text className={style.p8}>This Agreement takes effect on the date on which you first use the Superrlink application.</Text>
                        <Text className={style.p8}>3. Unlimited Access Software License with Termination Rights The Superrlink Software License facilitates the acquisition of Superrlink software through a single purchase, granting users unrestricted and perpetual access to its comprehensive functionalities. Tailored for Hobbyists and Pros, Superrlink empowers users to create a bio website. This license entails a straightforward and flexible arrangement, exempting users from recurring fees or subscriptions. However, it is important to acknowledge that the licensor retains the right to terminate the license without conditions or prerequisites. This termination provision enables the licensor to exercise control over software distribution and utilization. Opting for the Superrlink Software License enables users to enjoy the benefits of the software while recognizing the licensor's unrestricted termination rights, which provide adaptability and address potential unforeseen circumstances.</Text>
                        <Text className={style.p9}>4. Disclaimer</Text>
                        <Text className={style.p8}>It is not warranted that Superrlink will meet your requirements or that its operation will be uninterrupted or error free. All express and implied warranties or conditions not stated in this Agreement (including without limitation, loss of profits, loss or corruption of data, business interruption or loss of contracts), so far as such exclusion or disclaimer is permitted under the applicable law are excluded and expressly disclaimed. This Agreement does not affect your statutory rights. </Text>
                        <Text className={style.p9}>4. Warranties and Limitation of Liability</Text>
                        <Text className={style.p8}>Superrlink does not give any warranty, guarantee or other term as to the quality, fitness for purpose or otherwise of the software. Superrlink shall not be liable to you by reason of any representation (unless fraudulent), or any implied warranty, condition or other term, or any duty at common law, for any loss of profit or any indirect, special or consequential loss, damage, costs, expenses or other claims (whether caused by Superrlink negligence or the negligence of its servants or agents or otherwise) which arise out of or in connection with the provision of any goods or services by Superrlink. Superrlink shall not be liable or deemed to be in breach of contract by reason of any delay in performing, or failure to perform, any of its obligations if the delay or failure was due to any cause beyond its reasonable control. Notwithstanding contrary clauses in this Agreement, in the event that Superrlink are deemed liable to you for breach of this Agreement, you agree that Superrlink liability is limited to the amount actually paid by you for your services or software, which amount calculated in reliance upon this clause. You hereby release Superrlink from any and all obligations, liabilities and claims in excess of this limitation </Text>
                        <Text className={style.p9}>4.Responsibilities</Text>
                        <Text className={style.p8}>Superrlink is not responsible for what the user does with the user-generated content.</Text>
                        <Text className={style.p8}>Last updated: 21 Dec 2023.</Text>

                    </Box>
                </Box>

                {/* Footer */}

                <Box sx={{
                    position: 'relative',
                    height: 'fit-content',
                    minHeight: 'fit-content',
                    width: '100vw',
                    minWidth: '100vw',
                    paddingBottom: '62px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <Footer />
                </Box>

                {/* ------------------------ */}

            </Box>
        </TabletPortrait>

        <TabletLandscape>
            <Box sx={parenBox}>

                {/* -------------header  */}
                <Box position="fixed"
                    sx={{
                        width: '100vw',
                        top: '0',
                        left: '0',
                        height: '76px',
                        zIndex: '999', // Adjust z-index as needed
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: style.pageBg
                    }}>
                    <Header />
                </Box>

                {/* Section one  */}

                <Box sx={{
                    width: '958px',
                    minWidth: '958px',
                    maxWidth: '958px',
                    minHeight: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '201px',
                    gap: '24px',
                    paddingBottom: '96px'
                }}>
                    <Text className={style.p10}>Terms of Service
                    </Text>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>

                        <Text className={style.p9}>1. Introduction</Text>
                        <Text className={style.p8}>By using Superrlink you confirm your acceptance of, and agree to be bound by, these terms and conditions.</Text>
                        <Text className={style.p9}>2. Agreement to Terms and Conditions</Text>
                        <Text className={style.p8}>This Agreement takes effect on the date on which you first use the Superrlink application.</Text>
                        <Text className={style.p8}>3. Unlimited Access Software License with Termination Rights The Superrlink Software License facilitates the acquisition of Superrlink software through a single purchase, granting users unrestricted and perpetual access to its comprehensive functionalities. Tailored for Hobbyists and Pros, Superrlink empowers users to create a bio website. This license entails a straightforward and flexible arrangement, exempting users from recurring fees or subscriptions. However, it is important to acknowledge that the licensor retains the right to terminate the license without conditions or prerequisites. This termination provision enables the licensor to exercise control over software distribution and utilization. Opting for the Superrlink Software License enables users to enjoy the benefits of the software while recognizing the licensor's unrestricted termination rights, which provide adaptability and address potential unforeseen circumstances.</Text>
                        <Text className={style.p9}>4. Disclaimer</Text>
                        <Text className={style.p8}>It is not warranted that Superrlink will meet your requirements or that its operation will be uninterrupted or error free. All express and implied warranties or conditions not stated in this Agreement (including without limitation, loss of profits, loss or corruption of data, business interruption or loss of contracts), so far as such exclusion or disclaimer is permitted under the applicable law are excluded and expressly disclaimed. This Agreement does not affect your statutory rights. </Text>
                        <Text className={style.p9}>4. Warranties and Limitation of Liability</Text>
                        <Text className={style.p8}>Superrlink does not give any warranty, guarantee or other term as to the quality, fitness for purpose or otherwise of the software. Superrlink shall not be liable to you by reason of any representation (unless fraudulent), or any implied warranty, condition or other term, or any duty at common law, for any loss of profit or any indirect, special or consequential loss, damage, costs, expenses or other claims (whether caused by Superrlink negligence or the negligence of its servants or agents or otherwise) which arise out of or in connection with the provision of any goods or services by Superrlink. Superrlink shall not be liable or deemed to be in breach of contract by reason of any delay in performing, or failure to perform, any of its obligations if the delay or failure was due to any cause beyond its reasonable control. Notwithstanding contrary clauses in this Agreement, in the event that Superrlink are deemed liable to you for breach of this Agreement, you agree that Superrlink liability is limited to the amount actually paid by you for your services or software, which amount calculated in reliance upon this clause. You hereby release Superrlink from any and all obligations, liabilities and claims in excess of this limitation </Text>
                        <Text className={style.p9}>4.Responsibilities</Text>
                        <Text className={style.p8}>Superrlink is not responsible for what the user does with the user-generated content.</Text>
                        <Text className={style.p8}>Last updated: 21 Dec 2023.</Text>

                    </Box>
                </Box>


                {/* Footer */}

                <Box sx={{
                    position: 'relative',
                    height: 'fit-content',
                    minHeight: 'fit-content',
                    width: '100vw',
                    minWidth: '100vw',
                    paddingBottom: '72px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <Footer />
                </Box>

                {/* ------------------------ */}



            </Box>




        </TabletLandscape>


    </>)
}