 
import { configureStore } from "@reduxjs/toolkit"

import dashboardReducer from "./features/dashboardSlice"
import firebaseReducer from "./features/firebaseSlice"
import webReducer from "./features/webSlice"
export default configureStore({
     reducer:{
        webStore: webReducer,
        dasboardStore:dashboardReducer,
        firebaseStore:firebaseReducer
     }
})