 
import React from 'react';
import {ThemeProvider, BaseStyles, Box, Button} from '@primer/react'
import { BrowserRouter, Routes, Route, Router, useLocation } from "react-router-dom";
import SignIn from './components/SignIn'  
import SignUp from './components/SignUp';
import AccountSetup from './components/AccountSetup';
import AccountProfileSetup from './components/AccountProfileSetup';
import AddPlatForms from './components/AddPlatForms';
//import Dashboard from './components/Dashboard';
import { auth, db } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { getFirebaseUser } from './components/redux/features/firebaseSlice';
import { doc, getDoc } from 'firebase/firestore';
import OpenDashboard from './components/OpenDashboard';
import Home from './components/Home';
import LandingPage from './components/Website/LandingPage';
import Pricing from './components/Website/Pricing';
import Privacy from './components/Website/Privacy';
import Terms from './components/Website/Terms';

function App() {

  const dispatch = useDispatch()
   
  const [username,setusername] = React.useState("") 

  React.useEffect(() => {

    onAuthStateChanged(auth, (user) => {

      if (user) {
        const uid = user.uid;
        dispatch(getFirebaseUser({
          firebaseUser: user
        }))
        getUsedatafromFirestore(uid)

      } else {
        console.log("else with user signout")
        dispatch(getFirebaseUser({
          firebaseUser: null
        }))
      }
    })
        
  },[]);

  const getUsedatafromFirestore = async (props) => {

    
    const userRef = doc(db, 'users', props);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {

      const responseData = docSnap.data()
      const userName = responseData?.username
      console.log("userName", userName)
      if (userName === "") {
        
      } else {
            setusername(userName)
      }


    }

  }

  

    

  return (
    <ThemeProvider
      
    >
      <BaseStyles>

         

        <BrowserRouter>
          <Routes>
           
            <Route path='/pricing' element={<Pricing/>} />
            <Route path='/privacy' element={<Privacy/>} />
            <Route path='/terms' element={<Terms/>} />
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/accountsetup" element={<AccountSetup />} />
            <Route path="/profilesetup" element={<AccountProfileSetup />} />
            <Route path="/addplatforms" element={<AddPlatForms />} />
            <Route path="/dashboard" element={<Home />} />
            <Route path={`/${username}`} element={<Home />} />
            <Route path='/:username' element={<OpenDashboard/>} />

          </Routes>
        </BrowserRouter>



      </BaseStyles>
    </ThemeProvider>
   
  );
}

export default App;
