import { Box, Text } from "@primer/react";
import logo from './assets/weblogo.svg'
import style from './web.module.scss'
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1224 })
    return isDesktop ? children : null
}

const TabletPortrait = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991, orientation: 'portrait' })
    return isTablet ? children : null
}
const TabletLandscape = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 992, maxWidth: 1223, orientation: 'landscape' })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}
const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
}

export default function Footer() {

    const navigate = useNavigate()


    return (<>

        <Desktop>
            <Box sx={{
                width: '1280px',
                minWidth: '1280px',
                maxWidth: '1280px',
                height: '35px',
                minHeight: '35px',
                maxHeight: '35px',
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            }}>
                <img src={logo} style={{ width: '180px', maxHeight: '35px' }} />
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '24px', position: 'absolute', right: '0' }}>
                    <Text className={style.p4} sx={{ cursor: 'pointer' }} onClick={() => { navigate('/privacy') }}>Privacy Policy</Text>
                    <Text className={style.p4} sx={{ cursor: 'pointer' }} onClick={() => { navigate('/terms') }}>Terms of Service</Text>
                </Box>
            </Box>
        </Desktop>
        <Mobile>
            <Box sx={{
                width: '98%',
                minWidth: '98%',
                maxWidth: '98%',
                height: 'auto',
                minHeight: 'auto',
                maxHeight: 'auto',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px'
            }}>
                <img src={logo} style={{ width: '140px', maxHeight: '30px' }} />
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
                    <Text className={style.p4} sx={{ cursor: 'pointer' }} onClick={() => { navigate('/privacy') }}>Privacy Policy</Text>
                    <Text className={style.p4} sx={{ cursor: 'pointer' }} onClick={() => { navigate('/terms') }}>Terms of Service</Text>
                </Box>
            </Box>
        </Mobile>

        <TabletPortrait>

            <Box sx={{
                width: '778px',
                minWidth: '778px',
                maxWidth: '778px',
                height: '35px',
                minHeight: '35px',
                maxHeight: '35px',
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            }}>
                <img src={logo} style={{ width: '180px', maxHeight: '35px' }} />
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '24px', position: 'absolute', right: '16px' }}>
                    <Text className={style.p4} sx={{ cursor: 'pointer' }} onClick={() => { navigate('/privacy') }}>Privacy Policy</Text>
                    <Text className={style.p4} sx={{ cursor: 'pointer' }} onClick={() => { navigate('/terms') }}>Terms of Service</Text>
                </Box>
            </Box>

        </TabletPortrait>

        <TabletLandscape>
            <Box sx={{
                width: '978px',
                minWidth: '978px',
                maxWidth: '978px',
                height: '35px',
                minHeight: '35px',
                maxHeight: '35px',
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            }}>
                <img src={logo} style={{ width: '180px', maxHeight: '35px' }} />
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '24px', position: 'absolute', right: '16px' }}>
                    <Text className={style.p4} sx={{ cursor: 'pointer' }} onClick={() => { navigate('/privacy') }}>Privacy Policy</Text>
                    <Text className={style.p4} sx={{ cursor: 'pointer' }} onClick={() => { navigate('/terms') }}>Terms of Service</Text>
                </Box>
            </Box>

        </TabletLandscape>

    </>)
}