import { Box, Dialog, Text, TextInput, Button,Textarea, Avatar, Select, Spinner } from "@primer/react"
import React from "react"
import style from '../../global.module.scss'
import { useMediaQuery } from 'react-responsive'
import { useDispatch, useSelector } from "react-redux"
import { addProjectWidgetOpenClose, profilereloadTrigger } from "../redux/features/dashboardSlice"
import uploadIcon from '../../assets/uploadicon.svg'
import { doc, getDoc, updateDoc } from "firebase/firestore"
import { db } from "../../firebase"
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage"
import { v4 as uuidv4 } from 'uuid';
import posthog from "posthog-js"
posthog.init('phc_mGFMcRORH045UBsthDPi8bGDGMAPCvc7qtACPde72Hh', { api_host: 'https://app.posthog.com' })

const storage = getStorage()
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}
const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
}
 
const inputStyle = {
    width: '234px',
    maxWidth: '234px',
    minHeight: '48px',
    maxHeight: '48px',
    borderRadius: '8px',
    minWidth: '234px',

    // border: '2px solid #E4E4E7'
}

const contentBoxChildBox2InnerBox = {
    height: '120px',
    width: '120px',
    background: '#F4F4F5',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

const NextBtnStyle = {

    minWidth: '120px',
    minHeight: '48px',
    maxHeight: '48px',
    background: '#000000',
    color: '#FFFFFF',
    borderRadius: '12px',
    '&:hover': {
        background: '#000000',
        backgroundColor: '#000000'

    }
}

//---------- Mobile responsive --------

const inputStyleMobile = {
    width: '85vw',
    maxWidth: '85vw',
    minWidth: '85vw',
    minHeight: '48px',
    maxHeight: '48px',
    borderRadius: '8px',


    // border: '2px solid #E4E4E7'
}



export default function AddProjectWidgetDialog() {

    const dispatch = useDispatch()
    const firebaseUser = useSelector((state) => state.firebaseStore.firebaseUser)
    const isaddprojectWidgetOpenClose = useSelector((state) => state.dasboardStore.isaddprojectWidgetOpenClose)
    const [platforms, setplatforms] = React.useState([])
    const [selectedFile, setselectedFile] = React.useState(null)
    const [showLoading, setshowLoading] = React.useState(false)
    const imageRef = React.useRef()
    const [newplatform, setnewplatform] = React.useState(
        {
            id: '',
            icon: '',
            symbol: '@',
            placeholder: 'username',
            value: '',
            valueValidation: '',
            position: platforms.length + 1,
            label: '',
            status: '',
            link: '',
            linkValidation: '',
            mrr: '',
            description: ''
        }
    )

    const closeDialog = (props) => {
        dispatch(addProjectWidgetOpenClose({
            isaddprojectWidgetOpenClose: false
        }))
        setnewplatform((prevState) => {
            return ({
                ...prevState,

                id: '',
                icon: '',
                symbol: '@',
                placeholder: 'username',
                value: '',
                valueValidation: '',
                position: platforms.length + 1,
                label: '',
                status: '',
                link: '',
                linkValidation: '',
                mrr: '',
                description: ''
            })
        })
        setselectedFile(null)
    }

    const handlePickComputerFile = (props) => {

        setselectedFile(props)

    }


    const handleChangeInput = (props, value) => {

        if (props === "name") {

            setnewplatform((prevState) => {
                return ({ ...prevState, value: value })
            })

        } else if (props === "status") {

            setnewplatform((prevState) => {
                return ({ ...prevState, status: value })
            })

        } else if (props === "link") {
            setnewplatform((prevState) => {
                return ({ ...prevState, link: value })
            })

        } else if (props === "mrr") {

            setnewplatform((prevState) => {
                return ({ ...prevState, mrr: value })
            })
        } else if (props === "description") {

            setnewplatform((prevState) => {
                return ({ ...prevState, description: value })
            })
        }

    }





    const getallPlatforms = async (props) => {

        const userRef = doc(db, 'users', props);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {

            const responseData = docSnap.data()
            const platformArray = [...responseData.platforms]
            setplatforms(platformArray)

        } else {


        }

    }



    const updateImageInfirestore = (props, filename) => {
        return new Promise((resolve, reject) => {
            const file = props;
            setshowLoading(true)

            const fileName = filename //"" + firebaseUser.uid + "" //props.name
            const metadata = {
                contentType: 'image/jpeg',
            };

            const storageRef = ref(storage, `platforms/${firebaseUser?.uid}/` + fileName)
            const uploadTask = uploadBytesResumable(storageRef, file, metadata);
            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        // ...

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                    }
                    reject(error); // Reject the promise if there's an error
                },
                () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref)
                        .then(downloadURL => {
                            console.log('File available at', downloadURL);
                            setshowLoading(false);
                            resolve(downloadURL); // Resolve the promise with the download URL
                        })
                        .catch(error => {
                            reject(error); // Reject the promise if there's an error in getting the download URL
                        });
                }
            );
        });
    }

    const addWidgetInFirestore = async (props) => {

        if (newplatform.value === "") {

            setnewplatform((prevState) => {
                return ({ ...prevState, valueValidation: 'error' })
            })

        } else if (newplatform.link === "") {

            setnewplatform((prevState) => {
                return ({ ...prevState, linkValidation: 'error' })
            })

        } else if (selectedFile == null) {

            const updatedPlatform = [...platforms, {
                id: newplatform.value.toLocaleLowerCase().trim(),
                icon: '',
                symbol: '@',
                placeholder: 'username',
                value: newplatform.value,
                position: platforms.length,
                label: newplatform.value,
                status: newplatform.status,
                link: newplatform.link,
                mrr: newplatform.mrr,
                description: newplatform.description,
                platformType: 'custom',
                selectedFileName: ''
            }]

            const userRef = doc(db, 'users', firebaseUser?.uid);
            const updateResponse = await updateDoc(userRef, { platforms: updatedPlatform })
            
            posthog.capture(
                'AddProject',
                {
                    distinct_id: firebaseUser?.uid,
                    $set_once: {
                        user_id: firebaseUser?.uid,
                        user_email: firebaseUser?.email,
                        updatedPlatform:updatedPlatform
                    },
                    timestamp: new Date().toISOString(),
                    user_id: firebaseUser?.uid,
                    user_email: firebaseUser?.email,
                }
            )
            
            
            closeDialog("")
            dispatch(profilereloadTrigger({
                isprofRedload: true
            }))
            console.log("addWidgetInFirestore", updatedPlatform)




        } else {

            const selectedFileName = "" + uuidv4() + ""

            updateImageInfirestore(selectedFile, selectedFileName)
                .then(async downloadURL => {
                    // Handle the download URL
                    console.log("uploadResponse", downloadURL);

                    const updatedPlatform = [...platforms, {
                        id: newplatform.value.toLocaleLowerCase().trim(),
                        icon: downloadURL,
                        symbol: '@',
                        placeholder: 'username',
                        value: newplatform.value,
                        position: platforms.length,
                        label: newplatform.value,
                        status: newplatform.status,
                        link: newplatform.link,
                        mrr: newplatform.mrr,
                        description: newplatform.description,
                        platformType: 'custom',
                        selectedFileName: selectedFileName
                    }]

                    const userRef = doc(db, 'users', firebaseUser?.uid);
                    const updateResponse = await updateDoc(userRef, { platforms: updatedPlatform })

                    posthog.capture(
                        'AddProject',
                        {
                            distinct_id: firebaseUser?.uid,
                            $set_once: {
                                user_id: firebaseUser?.uid,
                                user_email: firebaseUser?.email,
                                updatedPlatform: updatedPlatform
                            },
                            timestamp: new Date().toISOString(),
                            user_id: firebaseUser?.uid,
                            user_email: firebaseUser?.email,
                        }
                    )


                    closeDialog("")
                    dispatch(profilereloadTrigger({
                        isprofRedload: true
                    }))
                    console.log("addWidgetInFirestore", updatedPlatform)

                })
                .catch(error => {
                      // Handle errors
                      console.error("uploadResponse Error:", error);
                });



        }

    }


    React.useEffect((row, index) => {

        if (isaddprojectWidgetOpenClose) {

            getallPlatforms(firebaseUser?.uid)
        }


    }, [isaddprojectWidgetOpenClose])

    return (<>
        <Default>
            <Dialog
                isOpen={isaddprojectWidgetOpenClose}
                onDismiss={() => {
                    closeDialog("")
                }}
                sx={{
                    position: 'absolute',
                    top: '-46px',
                    width: '595px',
                    minWidth:'595px',
                    maxWidth:'595px',
                    height: '607px',
                    minHeight: '607px',
                    background: '#ffffff',
                    borderRadius: '14px',
                    border: '1px solid #D1D5DB'
                }}
            >

                <Box sx={{
                    position: 'absolute',
                    top: '40px',
                    left: '0px',
                    right: '0px',
                    margin: 'auto',
                    minHeight: 'fit-content',
                    width: '481px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '30px',
                    
                }}>
                    <Box sx={{
                        minHeight: 'fit-content',
                        width: 'fit-content',
                        display: 'flex',
                    }}>
                        <Text className={style.H7}>Add Project Widget</Text>

                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        minHeight: 'fit-content',
                        width: 'fit-content',
                    }}>

                        <Box sx={contentBoxChildBox2InnerBox}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}

                                onClick={() => {
                                    imageRef.current.click()

                                }}
                            >

                                {showLoading ? <Spinner sx={{ position: 'absolute' }}></Spinner> : ''}

                                {selectedFile == null ?
                                    <>
                                        <img src={uploadIcon} style={{ width: '24px', height: '24px' }} />
                                        <Text className={style.p6} sx={{ textAlign: 'center' }}>Add Avatar</Text>
                                        <Text className={style.p6} sx={{ textAlign: 'center' }}>(max. 3MB)</Text>
                                    </>
                                    :
                                    <><Avatar size={120} sx={{objectFit:'cover'}} src={URL.createObjectURL(selectedFile)} /></>
                                }



                            </Box>
                            <input ref={imageRef} type={"file"} accept=".png, .jpg, .jpeg" onChange={(e) => handlePickComputerFile(e.target.files[0])} hidden />

                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '13px' }}>
                            <TextInput
                                contrast
                                className={style.p4}
                                sx={inputStyle}
                                placeholder="Name"
                                value={newplatform.value}
                                onChange={(e) => {
                                    handleChangeInput("name", e.target.value)
                                }}

                            >
                            </TextInput>
                            <Box    >

                                <Select contrast className={style.p4} sx={inputStyle}
                                    onChange={(e) => { handleChangeInput("status", e.target.value) }}
                                    value={newplatform.status}
                                >
                                    <Select.Option value="Active">Active</Select.Option>
                                    <Select.Option value="Building">Building</Select.Option>
                                    <Select.Option value="Discontinued">Discontinued</Select.Option>
                                    <Select.Option value="Acquired">Acquired</Select.Option>
                                    <Select.Option value="Inactive">Inactive</Select.Option>
                                    <Select.Option value="Sale">Sale</Select.Option>
                                </Select>

                            </Box>


                            
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '13px' }}>
                            <TextInput
                                contrast
                                className={style.p4}
                                sx={inputStyle}
                                placeholder="Link"
                                onChange={(e) => {
                                    handleChangeInput("link", e.target.value)
                                }}
                                value={newplatform.link}
                            >
                            </TextInput>
                            <TextInput
                                contrast
                                className={style.p4}
                                sx={inputStyle}
                                placeholder="MRR(USD) Optional"
                                onChange={(e) => {
                                    handleChangeInput("mrr", e.target.value)
                                }}
                                value={newplatform.mrr}
                            >
                            </TextInput>
                        </Box>

                        <Box>

                            <Textarea
                                placeholder="Description "
                                resize="none" cols={68} rows={7} contrast className={style.p4}
                                onChange={(e) => {
                                    handleChangeInput("description", e.target.value)
                                }}
                                value={newplatform.description}
                            />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '20px',
                            justifyContent: 'center'
                        }}>
                            <Button
                                disabled={showLoading}
                                variant="invisible"
                                className={style.p5}
                                sx={NextBtnStyle}
                                onClick={() => {
                                    addWidgetInFirestore("")
                                }}
                            >
                                Add widget
                            </Button>

                        </Box>





                    </Box>


                </Box>

            </Dialog>
        </Default>

        {/* Mobile ---------  */}

        <Mobile>
            <Dialog
                isOpen={isaddprojectWidgetOpenClose}
                onDismiss={() => {
                    closeDialog("")
                }}
                sx={{
                    width: '90vw',
                    minWidth: '90vw',
                    maxWidth: '90vw',
                    top: '20px',
                    minHeight: '720px',
                    background: '#ffffff',
                    overflowY: 'auto',
                    overflowX: 'hidden',

                }}
                style={{ borderRadius: '14px' }}
            >

                <Box sx={{
                    position: 'relative',
                    //  position: 'absolute',
                    //  top: '40px',
                    //  left: '8px',
                    //  right: '8px',
                    margin: '40px 8px 0px 8px',
                    minHeight: 'fit-content',
                    width: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '30px'
                }}>
                    <Box sx={{
                        minHeight: 'fit-content',
                        width: 'fit-content',
                        display: 'flex',
                    }}>
                        <Text className={style.H7}>Add Project Widget</Text>

                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        minHeight: 'fit-content',
                        width: 'fit-content',
                    }}>

                        <Box sx={contentBoxChildBox2InnerBox}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}

                                onClick={() => {
                                    imageRef.current.click()

                                }}
                            >

                                {showLoading ? <Spinner sx={{ position: 'absolute' }}></Spinner> : ''}

                                {selectedFile == null ?
                                    <>
                                        <img src={uploadIcon} style={{ width: '24px', height: '24px' }} />
                                        <Text className={style.p6} sx={{ textAlign: 'center' }}>Add Avatar</Text>
                                        <Text className={style.p6} sx={{ textAlign: 'center' }}>(max. 3MB)</Text>
                                    </>
                                    :
                                    <><Avatar size={120} sx={{objectFit:'cover'}} src={URL.createObjectURL(selectedFile)} /></>
                                }



                            </Box>
                            <input ref={imageRef} type={"file"} accept=".png, .jpg, .jpeg" onChange={(e) => handlePickComputerFile(e.target.files[0])} hidden />

                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '13px' }}>
                            <TextInput
                                contrast
                                className={style.p4}
                                sx={inputStyleMobile}
                                placeholder="Name"
                                value={newplatform.value}
                                onChange={(e) => {
                                    handleChangeInput("name", e.target.value)
                                }}

                            >
                            </TextInput>
                            <Box    >

                                <Select contrast className={style.p4} sx={inputStyleMobile}
                                    onChange={(e) => { handleChangeInput("status", e.target.value) }}
                                    value={newplatform.status}
                                >
                                    <Select.Option value="Active">Active</Select.Option>
                                    <Select.Option value="Building">Building</Select.Option>
                                    <Select.Option value="Discontinued">Discontinued</Select.Option>
                                    <Select.Option value="Acquired">Acquired</Select.Option>
                                    <Select.Option value="Inactive">Inactive</Select.Option>
                                    <Select.Option value="Sale">Sale</Select.Option>
                                </Select>

                            </Box>



                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '13px' }}>
                            <TextInput
                                contrast
                                className={style.p4}
                                sx={inputStyleMobile}
                                placeholder="Link"
                                onChange={(e) => {
                                    handleChangeInput("link", e.target.value)
                                }}
                                value={newplatform.link}
                            >
                            </TextInput>
                            <TextInput
                                contrast
                                className={style.p4}
                                sx={inputStyleMobile}
                                placeholder="MRR(USD) Optional"
                                onChange={(e) => {
                                    handleChangeInput("mrr", e.target.value)
                                }}
                                value={newplatform.mrr}
                            >
                            </TextInput>
                        </Box>

                        <Box>

                            <Textarea
                                placeholder="Description "
                                resize="none" cols={68} rows={7} contrast className={style.p4}
                                onChange={(e) => {
                                    handleChangeInput("description", e.target.value)
                                }}
                                value={newplatform.description}
                            />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '20px',
                            justifyContent: 'center'
                        }}>
                            <Button
                                disabled={showLoading}
                                variant="invisible"
                                className={style.p5}
                                sx={NextBtnStyle}
                                onClick={() => {
                                    addWidgetInFirestore("")
                                }}
                            >
                                Add widget
                            </Button>

                        </Box>





                    </Box>


                </Box>

            </Dialog>
        </Mobile>
       
        

    </>)
}